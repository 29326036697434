import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams, BrowserRouter } from "react-router-dom";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  message,
  Breadcrumb,
  Radio,
} from "antd";
import moment from "moment";
import APICall from "../../../services/apiservices";

const { Item } = Form;

const ManagePurchase = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("sameState");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      fetchPurchaseDetails(id);
    }
  }, [id]);

  const fetchPurchaseDetails = async (id) => {
    try {
      const response = await APICall(`/purchase/${id}`, {}, 1);
      const purchaseData = response.data;
      form.setFieldsValue({
        ...purchaseData,
        date: moment(purchaseData.date),
      });

      setSelectedOption(purchaseData.igst ? "oms" : "sameState");
    } catch (error) {
      console.error("Failed to fetch purchase details: ", error);
    }
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const requestData = { ...values, selectedOption };
      let response;
      if (id) {
        await APICall(`/purchase/${id}`, requestData, 3);
        message.success("Purchase updated successfully!");
      } else {
        await APICall("/purchase", requestData, 2);
        message.success("Purchase created successfully!");
      }
      form.resetFields();
      navigate("/purchases");
    } catch (error) {
      setIsLoading(false);
      message.error("Operation failed. Please try again later.");
      console.error(error);
    }
  };

  const calculateValues = (basicAmount) => {
    const cgst = basicAmount * 0.09;
    const sgst = basicAmount * 0.09;
    const totalGst = cgst + sgst;
    const totalAmount = parseFloat(basicAmount) + parseFloat(totalGst);
    return { cgst, sgst, totalGst, totalAmount };
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    form.resetFields(["igst"]);
  };

  const breadcrumbItems = id ? (
    <>
      <Breadcrumb.Item key="purchases">
        <Link to="/purchases">Purchases</Link>
      </Breadcrumb.Item>

      <Breadcrumb.Item key="Form">
        <Link to="#">Edit</Link>
      </Breadcrumb.Item>
    </>
  ) : (
    <>
      <Breadcrumb.Item key="purchases">
        <Link to="/purchases">Purchases</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item key="Form">
        <Link to="#">Create</Link>
      </Breadcrumb.Item>
    </>
  );

  return (
    <>
      <div type="inner" style={{ margin: 0, height: "50px" }}>
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Row gutter={10}>
        <Col span={24}>
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Card
              title={id ? "Edit Purchase" : "Create Purchase"}
              bordered={false}
            >
              <Item>
                <Row gutter={10}>
                  <Col span={24}>
                    <Radio.Group
                      onChange={handleOptionChange}
                      value={selectedOption}
                    >
                      <Radio value="sameState">Same State</Radio>
                      <Radio value="oms">OMS</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </Item>
              {selectedOption === "sameState" && (
                <Row gutter={10}>
                  <Col span={8}>
                    <Item
                      label="Date"
                      name="date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter date",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="Select date"
                        format="DD-MM-YYYY"
                        style={{ width: "100%" }}
                        disabledDate={(current) =>
                          current && current < moment().startOf("day")
                        }
                      />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      label="Party Detail"
                      name="customerId"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the party name",
                        },
                        {
                          pattern: /^[A-Za-z\s]+$/,
                          message:
                            "Party name should only contain alphabetic characters",
                        },
                      ]}
                    >
                      <Input placeholder="Company Name" />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      label="PO No"
                      name="poNo"
                      rules={[
                        {
                          required: true,
                          message: "Please enter PO No",
                        },
                      ]}
                    >
                      <Input placeholder="PO123" />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      label="Basic Amount (₹)"
                      name="basicAmount"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Basic Amount",
                        },
                        {
                          pattern: /^\d+$/,
                          message:
                            "Basic Amount should only contain numeric values",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter basic amount"
                        onChange={(e) => {
                          const { cgst, sgst, totalGst, totalAmount } =
                            calculateValues(e.target.value);
                          form.setFieldsValue({
                            cgst: cgst.toFixed(2),
                            sgst: sgst.toFixed(2),
                            totalGst: totalGst.toFixed(2),
                            totalAmount: totalAmount.toFixed(2),
                          });
                        }}
                      />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item label="CGST (9%)" name="cgst">
                      <Input placeholder="Enter CGST" />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item label="SGST (9%)" name="sgst">
                      <Input placeholder="Enter SGST" />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      label="Total GST (18%)"
                      name="totalGst"
                      rules={[
                        {
                          required: true,
                          message: "Please enter total gst",
                        },
                      ]}
                    >
                      <Input placeholder="Enter total GST" />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      label="Total Amount (₹)"
                      name="totalAmount"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Total Amount",
                        },
                      ]}
                    >
                      <Input placeholder="Enter total amount" />
                    </Item>
                  </Col>
                </Row>
              )}
              {selectedOption === "oms" && (
                <Row gutter={10}>
                  <Col span={8}>
                    <Item
                      label="Date"
                      name="date"
                      rules={[
                        {
                          required: true,
                          message: "Please enter date",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="Select date"
                        format="DD-MM-YYYY"
                        style={{ width: "100%" }}
                        disabledDate={(current) =>
                          current && current < moment().startOf("day")
                        }
                      />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      label="Party Detail"
                      name="customerId"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the party name",
                        },
                        {
                          pattern: /^[A-Za-z\s]+$/,
                          message:
                            "Party name should only contain alphabetic characters",
                        },
                      ]}
                    >
                      <Input placeholder="Company Name" />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      label="PO No"
                      name="poNo"
                      rules={[
                        {
                          required: true,
                          message: "Please enter PO No",
                        },
                      ]}
                    >
                      <Input placeholder="PO123" />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      label="Basic Amount (₹)"
                      name="basicAmount"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Basic Amount",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter basic amount"
                        onChange={(e) => {
                          const { cgst, sgst, totalGst, totalAmount } =
                            calculateValues(e.target.value);
                          form.setFieldsValue({
                            cgst: cgst.toFixed(2),
                            sgst: sgst.toFixed(2),
                            totalGst: totalGst.toFixed(2),
                            totalAmount: totalAmount.toFixed(2),
                          });
                        }}
                      />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      label="IGST"
                      name="igst"
                      rules={[
                        {
                          required: true,
                          message: "Please enter IGST amount",
                        },
                        {
                          validator: (_, value) => {
                            if (value === undefined || value === "") {
                              return Promise.resolve();
                            }
                            if (isNaN(value)) {
                              return Promise.reject(
                                "IGST amount must be a number"
                              );
                            }
                            if (value < 0) {
                              return Promise.reject(
                                "IGST amount cannot be negative"
                              );
                            }
                            if (value <= 0) {
                              return Promise.reject(
                                "IGST amount must be greater than 0"
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input placeholder="Enter IGST" />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      label="Total GST (18%)"
                      name="totalGst"
                      rules={[
                        {
                          required: true,
                          message: "Please enter total gst",
                        },
                      ]}
                    >
                      <Input placeholder="Enter total GST" />
                    </Item>
                  </Col>
                  <Col span={8}>
                    <Item
                      label="Total Amount (₹)"
                      name="totalAmount"
                      rules={[
                        {
                          required: true,
                          message: "Please enter Total Amount",
                        },
                      ]}
                    >
                      <Input placeholder="Enter total amount" />
                    </Item>
                  </Col>
                </Row>
              )}
              <Row gutter={16} style={{ marginTop: "10px" }}>
                <Col span={2}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={isLoading}
                    style={{ backgroundColor: "#26b689" }}
                  >
                    {id ? "Update" : "Save"}
                  </Button>
                </Col>
              </Row>
            </Card>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ManagePurchase;
