import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import { LiaInfoCircleSolid } from "react-icons/lia";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  message,
  Modal,
  Dropdown,
  Spin,
  Form,
  Input,
  Checkbox,
  Space,
  Tooltip,
  Tag,
  Descriptions,
  Breadcrumb,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import APICall from "../../services/apiservices";
import { async } from "q";
import { Duplex, values } from "pdf-lib";
import { useAuth } from "../../auth/AuthProvider";

const { Search } = Input;
const { confirm } = Modal;

const Employees = ({}) => {
  const { userData } = useAuth();
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getEmployees = async (searchTerm = "") => {
    try {
      const url = `/client/getClientEmployees?query=${searchTerm}`;
      const response = await APICall(url, {}, 1);
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error("No data found or invalid response format");
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
      message.error("failed to fetch employee data");
      return null;
    }
  };

  const fetchList = async () => {
    setIsLoading(true);
    // const page = tableParams.pagination.current;
    // const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";

    try {
      const response = await getEmployees(searchTerm);
      if (response && response.documents) {
        let clientData = `/client?query=${searchTerm}`;

        const clientResponse = await APICall(clientData, {}, 1);

        const filteredClientData = clientResponse.data.documents.filter(
          (client) => client.userId === userData._id
        );

        if (filteredClientData.length > 0) {
          const filteredEmployees = response.documents.filter(
            (employee) => employee.clientId === filteredClientData[0]._id
          );

          setEmployees(filteredEmployees);
          setTotalEmployeeCount(filteredEmployees.length);
        } else {
          setEmployees([]);
          setTotalEmployeeCount(0);
        }

        setTableParams((prevParams) => ({
          ...prevParams,
          pagination: {
            ...prevParams.pagination,
            total: response?.total || 0,
          },
        }));
      } else {
        setEmployees([]);
        setTotalEmployeeCount(0);
      }
    } catch (error) {
      console.error("Error in fetchList:", error.message || error);
      message.error("Failed to fetch data");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination,
      filters: {
        ...prevParams.filters,
        ...filters,
      },
      sorter,
    }));
  };

  const onSearch = (value) => {
    setSearchInput(value);
    setTableParams((prevParams) => ({
      ...prevParams,
      filters: {
        ...prevParams.filters,
        searchTerm: value,
      },
      pagination: {
        ...prevParams.pagination,
        current: 1, // Reset pagination to first page on new search
      },
    }));
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
      responsive: ["md"],
      width: 120,
    },

    {
      title: "Name",
      key: "name",
      render: (_, record) => {
        return <span>{record.name}</span>;
      },
      responsive: ["md"],
      width: 200,
    },
    {
      title: "Designation",
      key: "designation",
      render: (_, record) => {
        return <span>{record.designation}</span>;
      },
      responsive: ["md"],
      width: 200,
    },

    {
      title: "Email",
      key: "email",
      render: (_, record) => {
        return <a href={`mailto:${record.email}`}>{record.email}</a>;
      },

      responsive: ["lg"],
      width: 200,
    },
    {
      title: "Mobile",
      key: "mobile",
      render: (_, record) => {
        return <a href={`tel:+91${record.mobile}`}>{record.mobile}</a>;
      },
      width: 200,
    },
  ];

  const breadcrumbItems = [
    <Breadcrumb.Item key="employees">
      <Link to="#">Employees</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <>
      <div style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}>
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      {isLoading ? (
        <Row
          justify="center"
          align="middle"
          style={{ position: "absolute", top: "50%", left: "50%" }}
        >
          <Spin tip={"Loading"} />
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Card
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  {"Employees"}
                  <span
                    style={{
                      margin: "3px 0px 0px 4px",
                      fontSize: "14px",
                    }}
                  >
                    ({totalEmployeeCount})
                  </span>
                </div>
              }
              extra={
                <Row
                  justify={"end"}
                  align="top"
                  style={{ paddingBottom: "10px" }}
                >
                  <Space>
                    <Search
                      // className="search-Secondary"
                      style={{
                        width: 220,
                      }}
                      placeholder="Search an employee"
                      allowClear
                      enterButton
                      // loading={loading}
                      size="middle"
                      value={searchInput}
                      onChange={handleSearchChange}
                      onSearch={onSearch}
                    />
                  </Space>
                </Row>
              }
            >
              <Table
                isLoading={isLoading}
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={employees}
                size="small"
                pagination={tableParams.pagination}
                onChange={handleTableChange}
                scroll={{ x: "1000px" }}
              />
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Employees;
