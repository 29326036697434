import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  message,
  Breadcrumb,
  Space,
  Select,
  Table,
} from "antd";
import moment from "moment";
import { ClockCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

const { Item } = Form;
const { Option } = Select;
const { Search } = Input;

const GSTReport = ({ isEdit }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("sameState");
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {},
    sorter: {},
  });

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      // Your API call logic here
      setIsLoading(false);
      message.success("Operation successful!");
      navigate("/Quotations");
    } catch (error) {
      setIsLoading(false);
      message.error("Operation failed. Please try again later.");
      console.error(error);
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSearch = (value) => {
    setSearchText(value);
    // Perform search logic here if needed
  };

  const handleExport = () => {
    // Export logic
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Party Detail",
      dataIndex: "partyDetail",
      key: "partyDetail",
    },
    {
      title: "PO No",
      dataIndex: "poNo",
      key: "poNo",
    },
    {
      title: "Basic Amount (₹)",
      dataIndex: "basicAmount",
      key: "basicAmount",
    },
    {
      title: "CGST (9%)",
      dataIndex: "cgst",
      key: "cgst",
    },
    {
      title: "SGST (9%)",
      dataIndex: "sgst",
      key: "sgst",
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
    },
    {
      title: "Total GST (18%)",
      dataIndex: "totalGst",
      key: "totalGst",
    },
    {
      title: "Total Amount (₹)",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
  ];

  const salesColumns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Date",
      key: "date",
      render: (_, data) => {
        return moment(data.date).format("YYYY-MM-DD");
      },
    },
    {
      title: "Customer Name",
      key: "name",
      render: (_, data) => {
        return data.user.name;
      },
    },
    {
      title: "Amount (₹)",
      key: "amount",
      render: (_, data) => {
        return data.amount;
      },
    },
    {
      title: "Discount (₹)",
      key: "discount",
      render: (_, data) => {
        return `${data.discountAmount} (${data.discount}%)`;
      },
    },
    {
      title: "GST (₹)",
      key: "gst",
      render: (_, data) => {
        const totalGST = data.sgstAmount + data.cgstAmount;
        return `${totalGST}`;
      },
    },
    {
      title: "TDS (₹)",
      key: "tds",
      render: (_, data) => {
        return `${data.tdsAmount} `;
      },
    },
    {
      title: "Total Amount (₹)",
      key: "totalAmount",
      render: (_, data) => {
        return data.totalAmount;
      },
    },
  ];

  const breadcrumbItems = [
    <Breadcrumb.Item key="report">
      <Link to="#">Report</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="table">
      <Link to="#">TDS Report</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <>
      <div
        type="inner"
        style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}
      >
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Card
        title="TDS Report"
        extra={[
          <Space>
            <Select
              key="selectMonth"
              defaultValue="January"
              style={{ width: 120, marginRight: 10 }}
            >
              <Option value="January">January</Option>
              <Option value="February">February</Option>
              <Option value="March">March</Option>
              <Option value="April">April</Option>
              <Option value="May">May</Option>
              <Option value="June">June</Option>
              <Option value="July">July</Option>
              <Option value="August">August</Option>
              <Option value="September">September</Option>
              <Option value="October">October</Option>
              <Option value="November">November</Option>
              <Option value="December">December</Option>
            </Select>
            <Search
              style={{
                width: 220,
              }}
              placeholder="Search an Customer"
              allowClear
              enterButton
              // loading={loading}
              size="middle"
              onSearch={onSearch}
            />
            <Button key="export" type="primary" onClick={handleExport}>
              Export
            </Button>
          </Space>,
        ]}
      >
        <Row gutter={20}>
          <Col span={6}>
            <Card
              title={
                <span style={{ color: "white" }}>
                  {" "}
                  <CheckCircleOutlined
                    style={{ fontSize: "24px", marginRight: "8px" }}
                  />{" "}
                  ₹ 20000 TDS Paid
                </span>
              }
              style={{
                backgroundColor: "#32a834",
                height: "57px",
                textAlign: "center",
                cursor: "pointer",
              }}
            ></Card>
          </Col>
          <Col span={6}>
            <Card
              title={
                <span style={{ color: "white" }}>
                  {" "}
                  <ClockCircleOutlined
                    style={{ fontSize: "24px", marginRight: "8px" }}
                  />{" "}
                  ₹ 30000 Pending
                </span>
              }
              style={{
                backgroundColor: "#f0b954",
                height: "57px",
                textAlign: "center",
                cursor: "pointer",
              }}
            ></Card>
          </Col>
          {/* <Col span={8}>
                        <Card title={<span style={{ color: "white" }}>Outstanding</span>}
                            style={{
                                backgroundColor: '#f54747',
                                height: "57px",
                                textAlign: "center",
                                cursor: "pointer",
                            }}>
                        </Card>
                    </Col> */}
          <Col span={24}>
            <Card
              title="Sales"
              bordered={true}
              style={{ marginTop: "20px" }}
              // extra={[
              //   <Space>
              //     <Search
              //       style={{
              //         width: 220,
              //       }}
              //       placeholder="Search an Customer"
              //       allowClear
              //       enterButton
              //       // loading={loading}
              //       size="middle"
              //       onSearch={onSearch}
              //     />
              //     <Button key="export" type="primary" onClick={handleExport}>
              //       Export
              //     </Button>
              //   </Space>,
              // ]}
            >
              <Table
                columns={salesColumns}
                // dataSource={data}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Card
              title="Purchases"
              bordered={true}
              style={{ marginTop: "20px" }}
              // extra={[
              //   <Space>
              //     <Search
              //       style={{
              //         width: 220,
              //       }}
              //       placeholder="Search an Party"
              //       allowClear
              //       enterButton
              //       // loading={loading}
              //       size="middle"
              //       onSearch={onSearch}
              //     />
              //     <Button key="export" type="primary" onClick={handleExport}>
              //       Export
              //     </Button>
              //   </Space>,
              // ]}
            >
              <Table
                columns={columns}
                // dataSource={data}
              />
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default GSTReport;
