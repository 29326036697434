import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Settings from "../pages/Settings";
import MainLayout from "../../components/Layout/MainLayout";
import NotFound from "../../pages/NotFound";
import Login from "../../pages/Login";
import Employees from "../pages/Employees";
import ManageEmployee from "../features/employee/ManageEmployees";
import EmployeeDetails from "../features/employee/EmployeeDetails";
import EmployeeAttendance from "../features/employee/EmployeeAttendance";
import Customers from "../pages/Customers";
import ManageCustomer from "../features/customers/ManageCustomers";
import CustomerDetails from "../features/customers/CustomerDetails";
import ClientDetails from "../features/customers/ClientDetails";
import Items from "../pages/Items";
import ManageItems from "../features/items/ManageItems";
import ItemDetails from "../features/items/ItemDetails";
import Quotations from "../pages/Quotations";
import AMCs from "../pages/AMCs";
import Visits from "../pages/Visits";
import Surveys from "../pages/Surveys";

import Enquiry from "../pages/Enquiry";
import ManageEnquiries from "../features/enquiry/manageEnquiry";
import GuestEntry from "../pages/GuestEntry";
import ManageEntries from "../features/GuestEntry/ManageEntries";

import ManageVisits from "../features/visits/ManageVisits";
import VisitDetails from "../features/visits/VisitDetails";
import ManageAMCs from "../features/amc/ManageAMCs";
import AmcExport from "../features/amc/AmcExport";
import AMCDetails from "../features/amc/AmcDetails";

import ManageQuoatation from "../features/quotations/ManageQuoatation";
import QuoatationDetails from "../features/quotations/QuoatationDetails";
import MonthQuoatation from "../features/quotations/MonthQuoatation";

import GSTReport from "../features/Reports/gstReport";
import TDSReport from "../features/Reports/tdsReport";
import SalePurchaseReport from "../features/Reports/salesPurchaseReort";

import ManageSurvey from "../features/survey/ManageSurvey";
import SurveyDetails from "../features/survey/SurveyDetails";
import OfferLetterDetails from "../features/employee/OfferLetter";
import AcceptAttendance from "../features/employee/AcceptAttendance";

import Salary from "../features/employee/Salary";

import Purchase from "../pages/Purchase";
import ManagePurchase from "../features/purchases/ManagePurchase";
import PurchaseDetails from "../features/purchases/PurchaseDetails";

const SuperAdminRoutes = () => {
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <MainLayout active="dashboard">
            <Dashboard />
          </MainLayout>
        }
      />
      <Route
        path="/customers"
        element={
          <MainLayout active="customers">
            <Customers isActive={true} />
          </MainLayout>
        }
      />
      <Route
        path="/customers/inactive"
        element={
          <MainLayout active="customers">
            <Customers isActive={false} />
          </MainLayout>
        }
      />

      <Route
        path="/clients/manage"
        element={
          <MainLayout active="clients">
            <ManageCustomer />
          </MainLayout>
        }
      />
      <Route
        path="/clients/clientDetails/:clientId"
        element={
          <MainLayout active="clients">
            <ClientDetails />
          </MainLayout>
        }
      />
      <Route
        path="/clients/manage/:customerId"
        element={
          <MainLayout active="clients">
            <ManageCustomer />
          </MainLayout>
        }
      />
      <Route
        path="/customers/manage/:customerId"
        element={
          <MainLayout active="customers">
            <ManageCustomer isEdit />
          </MainLayout>
        }
      />
      <Route
        path="/customers/:customerId"
        element={
          <MainLayout active="customers">
            <CustomerDetails />
          </MainLayout>
        }
      />
      <Route
        path="/employees"
        element={
          <MainLayout active="employees">
            <Employees isActive={true} />
          </MainLayout>
        }
      />
      <Route
        path="/employees/inactive"
        element={
          <MainLayout active="employees">
            <Employees isActive={false} />
          </MainLayout>
        }
      />
      <Route
        path="/employees/attendance"
        element={
          <MainLayout active="employees">
            <EmployeeAttendance />
          </MainLayout>
        }
      />
      <Route
        path="/employees/attendance/accept"
        element={
          <MainLayout active="employees">
            <AcceptAttendance />
          </MainLayout>
        }
      />
      <Route
        path="/employees/manage"
        element={
          <MainLayout active="employees">
            <ManageEmployee />
          </MainLayout>
        }
      />
      <Route
        path="/employees/manage/:employeeId"
        element={
          <MainLayout active="employees">
            <ManageEmployee isEdit />
          </MainLayout>
        }
      />
      <Route
        path="/employees/:employeeId"
        element={
          <MainLayout active="employees">
            <EmployeeDetails />
          </MainLayout>
        }
      />
      <Route
        path="/employees/salary"
        element={
          <MainLayout active="employees">
            <Salary />
          </MainLayout>
        }
      />
      <Route
        path="/employees/:employeeId/offerLetter"
        element={
          <MainLayout active="employees">
            <OfferLetterDetails />
          </MainLayout>
        }
      />

      <Route
        path="/enquiry"
        element={
          <MainLayout active="enquiry">
            <Enquiry />
          </MainLayout>
        }
      />

      <Route
        path="/enquiry/manage"
        element={
          <MainLayout active="enquiry">
            <ManageEnquiries />
          </MainLayout>
        }
      />

      <Route
        path="/enquiry/manage/:id"
        element={
          <MainLayout active="enquiry">
            <ManageEnquiries />
          </MainLayout>
        }
      />

      <Route
        path="/guestEntry"
        element={
          <MainLayout active="enquiry">
            <GuestEntry />
          </MainLayout>
        }
      />

      <Route
        path="/guestEntry/manage"
        element={
          <MainLayout active="enquiry">
            <ManageEntries />
          </MainLayout>
        }
      />

      <Route
        path="/guestEntry/manage/:id"
        element={
          <MainLayout active="guestEntry">
            <ManageEntries isEdit />
          </MainLayout>
        }
      />

      <Route
        path="/items"
        element={
          <MainLayout active="items">
            <Items />
          </MainLayout>
        }
      />
      <Route
        path="/items/:id"
        element={
          <MainLayout active="items">
            <ItemDetails />
          </MainLayout>
        }
      />
      <Route
        path="/items/manage"
        element={
          <MainLayout active="items">
            <ManageItems />
          </MainLayout>
        }
      />
      <Route
        path="/items/manage/:id"
        element={
          <MainLayout active="items">
            <ManageItems isEdit />
          </MainLayout>
        }
      />
      <Route
        path="/purchases"
        element={
          <MainLayout active="purchases">
            <Purchase />
          </MainLayout>
        }
      />
      <Route
        path="/purchases/:id"
        element={
          <MainLayout active="purchases">
            <PurchaseDetails />
          </MainLayout>
        }
      />
      <Route
        path="/purchases/manage"
        element={
          <MainLayout active="purchases">
            <ManagePurchase />
          </MainLayout>
        }
      />
      <Route
        path="/purchases/manage/:id"
        element={
          <MainLayout active="purchases">
            <ManagePurchase isEdit />
          </MainLayout>
        }
      />
      <Route
        path="/quotations"
        element={
          <MainLayout active="quotations">
            <Quotations />
          </MainLayout>
        }
      />
      <Route
        path="/quotations/:quotationId"
        element={
          <MainLayout active="quotations">
            <QuoatationDetails />
          </MainLayout>
        }
      />
      <Route
        path="/quotations/manage"
        element={
          <MainLayout active="items">
            <ManageQuoatation />
          </MainLayout>
        }
      />
      <Route
        path="/quotations/month"
        element={
          <MainLayout active="quotations">
            <MonthQuoatation />
          </MainLayout>
        }
      />
      <Route
        path="/quotations/manage/:id"
        element={
          <MainLayout active="items">
            <ManageQuoatation isEdit />
          </MainLayout>
        }
      />
      <Route
        path="/amcs"
        element={
          <MainLayout active="amcs">
            <AMCs />
          </MainLayout>
        }
      />
      <Route
        path="/amcs/details/:amcId"
        element={
          <MainLayout active="amcs">
            <AMCDetails />
          </MainLayout>
        }
      />
      <Route
        path="/amcs/:amcId"
        element={
          <MainLayout active="amcs">
            <AmcExport />
          </MainLayout>
        }
      />
      <Route
        path="/amcs/manage"
        element={
          <MainLayout active="amcs">
            <ManageAMCs />
          </MainLayout>
        }
      />
      <Route
        path="/amcs/manage/:id"
        element={
          <MainLayout active="amcs">
            <ManageAMCs isEdit />
          </MainLayout>
        }
      />
      <Route
        path="/visits"
        element={
          <MainLayout active="visits">
            <Visits />
          </MainLayout>
        }
      />
      <Route
        path="/visits/:id"
        element={
          <MainLayout active="visits">
            <VisitDetails />
          </MainLayout>
        }
      />
      <Route
        path="/visits/manage"
        element={
          <MainLayout active="visits">
            <ManageVisits />
          </MainLayout>
        }
      />
      <Route
        path="/visits/manage/:id"
        element={
          <MainLayout active="visits">
            <ManageVisits isEdit />
          </MainLayout>
        }
      />
      <Route
        path="/surveys"
        element={
          <MainLayout active="surveys">
            <Surveys />
          </MainLayout>
        }
      />
      <Route
        path="/surveys/completed"
        element={
          <MainLayout active="surveys">
            <Surveys conducted />
          </MainLayout>
        }
      />
      <Route
        path="/surveys/:surveyId"
        element={
          <MainLayout active="surveys">
            <SurveyDetails />
          </MainLayout>
        }
      />
      <Route
        path="/surveys/manage"
        element={
          <MainLayout active="surveys">
            <ManageSurvey />
          </MainLayout>
        }
      />
      <Route
        path="/surveys/manage/:surveyId"
        element={
          <MainLayout active="surveys">
            <ManageSurvey isEdit />
          </MainLayout>
        }
      />

      <Route
        path="/tdsReport"
        element={
          <MainLayout>
            <TDSReport />
          </MainLayout>
        }
      />

      <Route
        path="/gstReport"
        element={
          <MainLayout>
            <GSTReport />
          </MainLayout>
        }
      />

      <Route
        path="/salePurchaseReport"
        element={
          <MainLayout>
            <SalePurchaseReport />
          </MainLayout>
        }
      />
      <Route
        path="/settings"
        element={
          <MainLayout>
            <Settings />
          </MainLayout>
        }
      />
      <Route
        path="/new"
        element={
          <MainLayout>
            <Settings />
          </MainLayout>
        }
      />

      <Route path="/:notfound" element={<NotFound />} />
    </Routes>
  );
};

export default SuperAdminRoutes;
