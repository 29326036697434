import {
  Dropdown,
  Button,
  Row,
  Col,
  Card,
  Radio,
  Table,
  Space,
  Form,
  Modal,
  Input,
  message,
  Breadcrumb,
} from "antd";

import APICall from "../../../services/apiservices";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import React, { useEffect } from "react";
import queryString from "query-string";

const { Search } = Input;
const { confirm } = Modal;

const BuildingsSurveys = () => {
  const navigate = useNavigate();
  const [loading, setIsLoading] = useState(true);
  const location = useLocation();
  const params = useParams();
  const { customerId, customerName, pagename, surveyId } = queryString.parse(
    location.search
  );
  const [buildingForm] = Form.useForm();
  const [buildings, setBuildings] = useState([]);
  const [filteredBuilding, setFilteredbuilding] = useState([
    {
      name: "Loading...",

    }
  ]);
  const [openModal, setOpenModal] = useState(false);
  const [surveType, setSurveyType] = useState("Normal");

  useEffect(() => {
    getBuidlings();
  }, []);

  const getBuidlings = async () => {
    try {
      const response = await APICall(`/customer/${customerId}/building`, {}, 1);
      setBuildings(response.data.data);
      setFilteredbuilding(response.data.data);
      setIsLoading(false);
    } catch (error) {
      message.error("Failed to fetch buildings");
    }
  };

  const breadCrumbItems = [
    {
      label: pagename,
      to: `/${pagename.toLowerCase()}`,
    },
    {
      label: customerName,
      // to: `/surveys`,
    },
    {
      label: "Buildings",
    },
  ];

  const coloums = [
    {
      title: "Sr.No.",
      key: "srNo",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Name",
      key: "name",
      render: (_, record) => record.name,
    },
    // {
    //   title: "Areas",
    //   key: "areas",
    //   render: (_, record) => record.areas.length,
    // },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: (
        //           <Link
        //             to={`/${pagename === "Visits" ? "visit" : "survey"}/${
        //               pagename === "Visits" ? params.visitId : params.surveyId
        //             }/building/${
        //               record._id
        //             }/areas?customerId=${customerId}&customerName=${customerName}&buildingName=${
        //               record.name
        //             }&pagename=${pagename}`}
        //           >
        //             Manage Areas
        //           </Link>
        //         ),
        //       },
        //       {
        //         key: 2,
        //         label: "Remove",
        //         onClick: () => {
        //           onRemove(record._id);
        //         },
        //         danger: true,
        //       },
        //     ],
        //   }}
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Space>
          <Link
            to={`/${pagename === "Visits" ? "visit" : "survey"}/${pagename === "Visits" ? params.visitId : params.surveyId
              }/building/${record._id
              }/areas?customerId=${customerId}&customerName=${customerName}&buildingName=${record.name
              }&pagename=${pagename}&surveyType=${surveType}`}
          >
            <Button type="primary" size="small" ghost>
              Manage Areas
            </Button>
          </Link>
          <Button
            type="primary"
            onClick={() => onRemove(record._id)}
            size="small"
            ghost
          >
            Remove
          </Button>
        </Space>
      ),
      align: "center",
    },
  ];

  const onbuildingFormFinish = async (formData) => {
    if (
      buildings?.find(
        (item) => item?.name.toLowerCase() === formData?.name.toLowerCase()
      )
    ) {
      message.error("Already exist");
    } else {
      try {
        const response = await APICall(
          `/customer/${customerId}/building`,
          formData,
          2
        );
        if (response?.status) {
          message.success("New building added.");
        }
        getBuidlings();
        setOpenModal(false);
        buildingForm.resetFields();
      } catch (error) {
        message.error(error.response.data.message);
      }
    }
  };
  const onSearch = (value) => {
    //filtering buildings
    const filteredBuilding = buildings.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredbuilding(filteredBuilding);
  };

  const onRemove = (data) => {
    confirm({
      title: "Are you sure you want to remove building?",
      onOk: async () => {
        try {
          const response = await APICall(
            `/customer/${customerId}/building/${data}`,
            {},
            4
          );
          if (response?.status) {
            Modal.success({
              title: " Buildings Removed",
              content: "",
            });
            getBuidlings();
          }
        } catch (error) {
          console.log(error);
          Modal.error({
            title: "Cannot Remove Building",
          });
        }
      },
      okButtonProps: {
        style: {
          backgroundColor: "red",
        },
      },
    });
  };

  const handleMarkConducted = () => {
    confirm({
      title: `Are you sure you want to mark ${pagename === "Visits" ? "visit" : "survey"
        } completed.`,
      onOk: async () => {
        try {
          const res = await APICall(
            `/${pagename === "Visits" ? "visits" : "survey"}/${pagename === "Visits" ? params.visitId : params.surveyId
            }/conducted`,
            {},
            2
          );
          if (res.status) {
            message.success(
              `${pagename === "Visits" ? "Visit" : "Survey"
              } marked as conducted.`
            );
            navigate(-1);
          }
        } catch (error) {
          message.error(error);
        }
      },
    });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Card
            title={
              <Breadcrumb separator=">" style={{ margin: "16px 0" }}>
                {breadCrumbItems.map((item, index) => (
                  <Breadcrumb.Item key={index}>
                    {item.to ? (
                      <Link to={item.to}>{item.label}</Link>
                    ) : (
                      item.label
                    )}
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            }
            extra={
              // !loading ? (
              <Row
                justify="end"
                align={"top"}
                style={{ paddingBottom: "10px" }}
                gutter={[16, 8]}
              >
                <Col lg={10} md={10} sm={10} xs={20} 
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    float: "right",
                  }}
                >
                  {/* <Space> */}
                    <Search
                      style={{ width: '100%' }}
                      placeholder="Search by name"
                      allowClear
                      size="middle"
                      onSearch={onSearch}
                      enterButton
                    />
                     </Col>
                     <Col lg={8} md={8} sm={8} xs={20}
                       style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        float: "right",
                      }}
                     >
                    <Button type="primary" onClick={handleMarkConducted}>
                      Mark As Conducted
                    </Button>
                    </Col>
                    <Col lg={4} md={4} sm={4} xs={20}
                       style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        float: "right",
                      }}
                    >
                    <Button type="primary" onClick={() => setOpenModal(true)} ghost>
                      Add
                    </Button>
                    </Col>
                  {/* </Space> */}
               
              </Row>
              // ) : null
            }
          >
            <Row>
              <Col span={24}
              >
                <Radio.Group
                  options={[
                    {
                      label: "Hydarant System",
                      value: "Normal",
                    },
                    {
                      label: "Fire Extinguisher",
                      value: "FireExtinguisher",
                    },
                  ]}
                  onChange={(target) => {
                    setSurveyType(target.target.value);
                  }}
                  value={surveType}
                  optionType="button"
                  buttonStyle="solid"
                  style={{ marginBottom: "10px" }}
                />
              </Col>
            </Row>
            <Table
              columns={coloums}
              dataSource={filteredBuilding}
              // loading={loading}
              size="small"
              scroll={{ x: "1000px" }}
            />
          </Card>
          <Modal
            title="Add Building"
            okText={"Add Building"}
            centered
            open={openModal}
            onCancel={() => {
              setOpenModal(false);
              buildingForm.resetFields();
            }}
            onOk={buildingForm.submit}
          >
            <Form
              form={buildingForm}
              layout="vertical"
              onFinish={onbuildingFormFinish}
            >
              <Form.Item
                label="Building Name"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="text" />
              </Form.Item>
            </Form>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default BuildingsSurveys;
