import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  Table,
  message,
  Modal,
  Space,
  Image,
  Dropdown,
  Tag,
} from "antd";
import { Link, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
// import APICall from "../../services/apiservices";
import { useForm } from "antd/es/form/Form";
import moment from "moment";
import { Upload } from "antd";
import { ArrowsAltOutlined, UploadOutlined } from "@ant-design/icons";
import APICall from "../../services/apiservices";
import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
import { MdCancel } from "react-icons/md";

const { confirm } = Modal;

const Settings = () => {
  const { Item } = Form;
  const [form] = useForm();
  const [formLetterheads] = useForm();
  const [systemForm] = useForm();
  const [header, setHeader] = useState([]);
  const [footer, setFooter] = useState([]);
  const [companyDetails, setCompanyDetails] = useState({});
  const [imageUrl, setImageUrl] = useState(null);
  const [photoFileId, setPhotoFileId] = useState();
  const [visible, setVisible] = useState(false);
  const [showLetterheadModal, setShowLetterheadModal] = useState(false);
  const [showSystemModal, setShowSystemModal] = useState(false);
  const [showSystemEditModal, setShowSystemEditModal] = useState(false);
  const [systems, setSystems] = useState([]);
  const [editable, setEditable] = useState(false);
  const [img, setImg] = useState();
  const [showBankModal, setShowBankModal] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [formBankDetails] = Form.useForm();
  const [bankDetails, setBankDetails] = useState([]);
  const [logoFileId, setLogoFileId] = useState(null);

  // const fetchBankData = async () => {
  //   try {
  //     const response = await APICall("/setting", {}, 1);
  //     console.log(response.data.documents, 'bankdata');
  //     setBankData(response.data.documents);
  //   } catch (error) {
  //     console.error("Error fetching bank data:", error);
  //   }
  // };
  // console.log(bankData, 'bankData1');
  // useEffect(() => {
  //   fetchBankData();
  // }, []);

  const handleSystemOk = async (isEdit) => {
    setShowSystemModal(false);
    setShowSystemEditModal(false);
    if (await systemForm.validateFields()) {
      const formData = systemForm.getFieldsValue();
      if (isEdit) {
        await APICall(
          `/company/649bff0f87a35d7bececa3d8/systemType/${formData.data._id}`,
          formData,
          3
        );
        message.success("System edited");
      } else {
        await APICall(
          `/company/649bff0f87a35d7bececa3d8/systemType`,
          formData,
          2
        );
        message.success("System added");
      }
      systemForm.resetFields();
      getSystems();
    }
    // const formData = itemForm.getFieldsValue()
    // console.log(formData)
    // itemForm.resetFields();
    // setEmployeeValue(undefined)
    // if(selectedVisits.find(v => moment((new Date(v.scheduledDate))).format('DD/MM/YYYY') === moment((new Date(formData.scheduledDate))).format('DD/MM/YYYY'))) {
    //     message.warning('Visit already added for selected date')
    //     return;
    // }
    // const visitsSelected = [...selectedVisits]
    // setSelectedVisits([...visitsSelected, formData])
  };

  const handleSystemCancel = () => {
    systemForm.resetFields();
    setShowSystemModal(false);
    setShowSystemEditModal(false);
  };

  const onFinishBank = async (values) => {
    const bankDetail = {
      bankName: values.bankName?.toString(),
      holderName: values.holderName?.toString(),
      branchName: values.branchName?.toString(),
      accountNumber: values.accountNumber,
      ifscNumber: values.ifscNumber?.toString(),
    };
    try {
      const response = await APICall(
        `/company/649bff0f87a35d7bececa3d8`,
        { bankDetails: [bankDetail] },
        3
      );
      if (response.data) {
        message.success("Bank detailed added successfully!");
        formBankDetails.resetFields();
        setShowBankModal(false);
        getCompanydetails();
      }
    } catch (error) {
      alert(error);
    }
  };

  const onFinish = async (formData) => {
    console.log("Logo File ID:", logoFileId);
    delete formData.logo;
    console.log({ new: formData });
    // const bankDetails = {
    //   bankName: formData.bankName?.toString(),
    //   holderName: formData.holderName?.toString(),
    //   branchName: formData.branchName?.toString(),
    //   accountNumber: formData.accountNumber,
    //   ifscNumber: formData.ifscNumber?.toString(),
    // };
    // delete formData.bankName;
    // delete formData.holderName;
    // delete formData.branchName;
    // delete formData.accountNumber;
    // delete formData.ifscNumber;
    try {
      const response = await APICall(
        `/company/649bff0f87a35d7bececa3d8`,
        { ...formData, logoFileId: logoFileId },
        3
      );
      if (response.data) {
        message.success("Company information has been updated!");
        getCompanydetails();
        formBankDetails.resetFields();
        setShowBankModal(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  const showImage = (img) => {
    setImg(img);
    setVisible(true);
  };

  const handleFileUpload = async (file) => {
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Setting",
    };

    const fileResponse = await APICall("/file", fileData, 2);
    await axios.put(fileResponse.data.data[0].url, file);
    setPhotoFileId(fileResponse.data.data[0].id);
    return false;
  };

  const handleLetterheadSubmit = (formData) => {
    try {
      const res = APICall(
        `/company/649bff0f87a35d7bececa3d8/letterhead`,
        {
          name: formData.fileName,
          type: formData.type,
          fileId: photoFileId,
        },
        2
      );
      message.success("Letterhead added successfully.");
      setShowLetterheadModal(false);
      formLetterheads.resetFields();
      getLetterhead();
    } catch (error) {
      setShowLetterheadModal(false);
      message.error(error.response.data.message);
    }
  };

  const getLetterhead = async () => {
    try {
      const response = await APICall(
        `/company/649bff0f87a35d7bececa3d8/letterhead`,
        {},
        1
      );
      setHeader(response.data.letterheadHeaders);
      setFooter(response.data.letterheadFooters);
    } catch (error) {
      console.error(error);
    }
  };

  const getSystems = async () => {
    try {
      const response = await APICall(
        `/company/649bff0f87a35d7bececa3d8/systemType`,
        {},
        1
      );
      setSystems(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCompanydetails = async () => {
    try {
      const response = await APICall(
        `/company/649bff0f87a35d7bececa3d8`,
        {},
        1
      );
      console.log("response.data", response.data);
      setBankDetails(response.data.bankDetails);
      setCompanyDetails(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleLetterheads = (record) => {
    if (record.isDefault) {
      message.warning("Can not delete default letterheads.");
    } else {
      confirm({
        title: "Confirm Delete",
        content: `Are you sure you want to delete letterhead?`,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: async () => {
          const res = await APICall(
            `/company/649bff0f87a35d7bececa3d8/letterhead/${record._id}`,
            {},
            4
          );
          // setReload(true);
          message.success("Letterhead deleted successfully!");
          getLetterhead();
        },
      });
    }
  };

  const handleDeleteBank = (record) => {
    console.log(record, "record");

    if (record.isDefault) {
      message.warning("Can not delete default bank detailed.");
    } else {
      confirm({
        title: "Confirm Delete",
        content: `Are you sure you want to delete bank detailed?`,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: async () => {
          const response = await APICall(
            `/company/deleteBankAccount`,
            {
              companyId: "649bff0f87a35d7bececa3d8",
              bankAccountId: record._id,
            },
            2
          );
          // setReload(true);
          message.success("Bank detailed deleted successfully!");
          setBankDetails((prevBankDetails) =>
            prevBankDetails.filter(
              (bankDetail) => bankDetail._id !== record._id
            )
          );
          // setBankDetails();
          // setCompanydetails();
        },
      });
    }
  };

  const handleMakeDefaultBank = (record) => {
    confirm({
      title: "Confirm Default",
      content: `Are you sure you want to make default bank detailed?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const res = await APICall(
          `/company/updateDefaultBankAccount`,
          {
            isDefault: true,
            bankAccountId: record._id,
            companyId: "649bff0f87a35d7bececa3d8",
          },
          2
        );
        message.success("Default bank detailed set successfully!");
        const updatedCompany = await getCompanydetails();
        setBankDetails(updatedCompany.bankDetails);
        // setBankDetails();
      },
    });
  };

  const handleMakeDefault = (record) => {
    confirm({
      title: "Confirm Default",
      content: `Are you sure you want to make default letterhead?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const res = await APICall(
          `/company/649bff0f87a35d7bececa3d8/letterhead/${record._id}`,
          { isDefault: true },
          3
        );
        message.success("Default letterhead set successfully!");
        getLetterhead();
      },
    });
  };

  const handleLogoUpload = async (file) => {
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Setting",
    };
    try {
      const fileResponse = await APICall("/file", fileData, 2);
      await axios.put(fileResponse.data.data[0].url, file);
      setLogoFileId(fileResponse.data.data[0].id);
      message.success("Logo uploaded successfully!");
      setImageUrl(fileResponse.data.data[0].url);
    } catch (error) {
      message.error("Failed to upload profile image");
    }

    return false;
  };

  useEffect(() => {
    if (companyDetails) {
      const initialValues = {
        name: companyDetails?.name,
        gstn: companyDetails?.gstn,
        licenceNumber: companyDetails?.licenceNumber,
        email: companyDetails?.email,
        financialYear: companyDetails?.financialYear,
        mobile: companyDetails?.mobile,
        quotationNumberFormat: companyDetails?.quotationNumberFormat,
        address: companyDetails?.address,
        state: companyDetails?.state,
        pincode: companyDetails?.pincode,
        gstNumber: companyDetails?.gstNumber,
        website: companyDetails?.website,
      };
      form.setFieldsValue(initialValues);
    }
  }, [companyDetails]);

  console.log("footer", footer);

  // const handleMakeDefault = (bankId) => {
  //   // Handle setting default bank logic here
  //   console.log('Set bank with ID as default:', bankId);
  //   // Assume your logic to set bank as default
  // };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: " Actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 2,
        //         label: "Edit",
        //         onClick: () => {
        //           systemForm.setFieldsValue({
        //             data: record,
        //             name: record.name,
        //             description: record.description,
        //           });
        //           setShowSystemEditModal(true);
        //         },
        //       },
        //       {
        //         key: 3,
        //         label: "Delete",
        //         onClick: async () => {
        //           confirm({
        //             title: "Delete system?",
        //             content: `Are you sure you want to delete this system?`,
        //             okText: "Yes",
        //             okType: "danger",
        //             cancelText: "No",
        //             onOk: async () => {
        //               try {
        //                 await APICall(
        //                   `/company/649bff0f87a35d7bececa3d8/systemType/${record._id}`,
        //                   {},
        //                   4
        //                 );
        //                 message.success("System deleted");
        //                 getSystems();
        //               } catch (error) {}
        //             },
        //           });
        //         },
        //         danger: true,
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Space>
          <Button
            onClick={() => {
              systemForm.setFieldsValue({
                data: record,
                name: record.name,
                description: record.description,
              });
              setShowSystemEditModal(true);
            }}
            type="primary"
            ghost
            size="small"
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              confirm({
                title: "Delete system?",
                content: `Are you sure you want to delete this system?`,
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                onOk: async () => {
                  try {
                    await APICall(
                      `/company/649bff0f87a35d7bececa3d8/systemType/${record._id}`,
                      {},
                      4
                    );
                    message.success("System deleted");
                    getSystems();
                  } catch (error) {}
                },
              });
            }}
            type="primary"
            size="small"
          >
            Delete
          </Button>
        </Space>
      ),
      align: "center",
    },
  ];

  const headerCols = [
    {
      title: "Header Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Default",
      render: (_, record) => (
        <Tag color={record.isDefault ? "green" : "red"}>
          {record.isDefault ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: " Actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: "View",
        //         onClick: () => {
        //           showImage(record.url);
        //         },
        //       },

        //       {
        //         key: 2,
        //         label: "Make Default",
        //         onClick: () => {
        //           handleMakeDefault(record);
        //         },
        //       },
        //       {
        //         key: 3,
        //         label: "Delete",
        //         onClick: () => {
        //           handleDeleLetterheads(record);
        //         },
        //         danger: true,
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Space>
          <Button
            onClick={() => {
              showImage(record.url);
            }}
            type="primary"
            ghost
            size="small"
            disabled={!editable}
          >
            View
          </Button>
          <Button
            onClick={() => {
              handleMakeDefault(record);
            }}
            type="primary"
            size="small"
            disabled={!editable}
          >
            Make Default
          </Button>
          <Button
            onClick={() => {
              handleDeleLetterheads(record);
            }}
            type="primary"
            size="small"
            disabled={!editable}
          >
            Delete
          </Button>
        </Space>
      ),
      align: "center",
    },
  ];
  const footerCols = [
    {
      title: "Footer Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Default",
      render: (_, record) => (
        <Tag color={record.isDefault ? "green" : "red"}>
          {record.isDefault ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: " Actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: "View",
        //         onClick: () => {
        //           showImage(record.url);
        //         },
        //       },

        //       {
        //         key: 2,
        //         label: "Make Default",
        //         onClick: () => {
        //           handleMakeDefault(record);
        //         },
        //       },
        //       {
        //         key: 3,
        //         label: "Delete",
        //         onClick: () => {
        //           handleDeleLetterheads(record);
        //         },
        //         danger: true,
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Space>
          <Button
            onClick={() => {
              showImage(record.url);
            }}
            type="primary"
            ghost
            size="small"
            disabled={!editable}
          >
            View
          </Button>
          <Button
            onClick={() => {
              handleMakeDefault(record);
            }}
            type="primary"
            size="small"
            disabled={!editable}
          >
            Make Default
          </Button>
          <Button
            onClick={() => {
              handleDeleLetterheads(record);
            }}
            type="primary"
            size="small"
            disabled={!editable}
          >
            Delete
          </Button>
        </Space>
      ),
      align: "center",
    },
  ];

  const bankCols = [
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
    },
    {
      title: "Default",
      render: (_, record) => (
        <Tag color={record.isDefault ? "green" : "red"}>
          {record.isDefault ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: " Actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: "View",
        //         onClick: () => {
        //           showImage(record.url);
        //         },
        //       },

        //       {
        //         key: 2,
        //         label: "Make Default",
        //         onClick: () => {
        //           handleMakeDefault(record);
        //         },
        //       },
        //       {
        //         key: 3,
        //         label: "Delete",
        //         onClick: () => {
        //           handleDeleLetterheads(record);
        //         },
        //         danger: true,
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Space>
          {/* <Button
            onClick={() => {
              showImage(record.url);
            }}
            type="primary"
            ghost
            size="small"
          >
            View
          </Button> */}
          <Button
            onClick={() => {
              handleMakeDefaultBank(record);
            }}
            type="primary"
            size="small"
            disabled={!editable}
          >
            Make Default
          </Button>
          <Button
            onClick={() => {
              handleDeleteBank(record);
            }}
            type="primary"
            size="small"
            disabled={!editable}
          >
            Delete
          </Button>
        </Space>
      ),
      align: "center",
    },
  ];

  const handleAdd = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    getLetterhead();
    getSystems();
  }, []);

  useEffect(() => {
    getCompanydetails();
  }, []);

  console.log(header);

  return (
    <>
      <Row>
        <Col span={24}>
          <Card
            title={"Settings & Preferences"}
            extra={
              <Space>
                <Button
                  type="primary"
                  tabIndex={14}
                  ghost
                  onClick={() => {
                    setEditable(!editable);
                  }}
                  style={editable ? { borderColor: "red", color: "red" } : {}}
                >
                  {editable ? "Cancel" : "Edit"}
                </Button>
              </Space>
            }
            style={{ marginBottom: "20px" }}
          >
            <Form
              form={form}
              layout="vertical"
              wrapperCol={{
                span: 14,
              }}
              onFinish={onFinish}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Card title="" bordered={false}>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Item
                          label="Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the name",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g ABC Company"
                            tabIndex={1}
                          />
                        </Item>

                        <Item
                          label="GSTIN"
                          name="gstn"
                          rules={[
                            {
                              required: true,
                              message: "Enter GSTIN of the company",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g GSTN12345678"
                            tabIndex={3}
                          />
                        </Item>

                        <Item
                          label="Logo"
                          name="file"
                          rules={[
                            {
                              required: false,
                              message: "Please enter the Logo",
                            },
                          ]}
                        >
                          {/* <Image
                            src="Company_Logo_2.jpeg"
                            style={{ width: "130px" }}
                          /> */}
                          {!editable ? (
                            <Image
                              src={companyDetails?.logoURL}
                              style={{ width: "130px" }}
                            />
                          ) : (
                            <Upload
                              beforeUpload={handleLogoUpload}
                              showUploadList={false}
                              multiple={false}
                            >
                              <Button icon={<UploadOutlined />}>
                                Click to Upload
                              </Button>
                            </Upload>
                          )}
                        </Item>
                      </Col>
                      <Col span={12}>
                        <Item
                          label="License Number"
                          name="licenceNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the licenc number",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g MFS - LA / RF-38"
                            tabIndex={2}
                          />
                        </Item>
                        <Item
                          label="Financial Year"
                          name="financialYear"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Financial Year",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g 2023-2024"
                            tabIndex={4}
                          />
                        </Item>
                        <Item
                          label="Quotation Number Format"
                          name="quotationNumberFormat"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Quotation Number Format",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g QUOTE/23-24/0000 "
                            tabIndex={6}
                          />
                        </Item>
                      </Col>
                    </Row>
                  </Card>
                  <Card title="Contact Information" bordered={false}>
                    <Row gutter={10}>
                      <Col span={12}>
                        <Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Email",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g abc@gmail.com"
                            tabIndex={7}
                          />
                        </Item>
                        <Item
                          label="Address"
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: "Please enter Address",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g Shiwaji Nagar, Pune-123456"
                            tabIndex={9}
                          />
                        </Item>
                      </Col>

                      <Col span={12}>
                        <Item
                          label="Mobile"
                          name="mobile"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the mobile",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g 0123456789"
                            tabIndex={8}
                          />
                        </Item>

                        <Item
                          label="Website"
                          name="website"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the website",
                            },
                          ]}
                        >
                          <Input
                            disabled={!editable}
                            placeholder="e.g company.com"
                            tabIndex={10}
                          />
                        </Item>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    title="Bank Details"
                    bordered={false}
                    extra={
                      <Button
                        type="primary"
                        ghost
                        disabled={!editable}
                        style={{ marginBottom: 16 }}
                        onClick={() => setShowBankModal(true)}
                      >
                        Add Bank
                      </Button>
                    }
                  >
                    <Table
                      columns={bankCols}
                      dataSource={bankDetails}
                      rowKey="_id"
                    />

                    <Modal
                      title="Add Bank Details"
                      open={showBankModal}
                      onCancel={() => setShowBankModal(false)}
                      footer={null}
                    >
                      <Form
                        form={formBankDetails}
                        onFinish={onFinishBank}
                        layout="vertical"
                      >
                        <Row>
                          <Col span={10} style={{ marginRight: "5%" }}>
                            <Form.Item
                              label="Bank Name"
                              name="bankName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter bank name",
                                },
                              ]}
                            >
                              <Input placeholder="Enter bank name" />
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              label="Holder Name"
                              name="holderName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter holder name",
                                },
                              ]}
                            >
                              <Input placeholder="Enter holder name" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={10} style={{ marginRight: "5%" }}>
                            <Form.Item
                              label="Branch Name"
                              name="branchName"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter branch name",
                                },
                              ]}
                            >
                              <Input placeholder="Enter branch name" />
                            </Form.Item>
                          </Col>
                          <Col span={10}>
                            <Form.Item
                              label="Account No."
                              name="accountNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter account Number",
                                },
                              ]}
                            >
                              <Input placeholder="Enter account number" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={10} style={{ marginRight: "5%" }}>
                            <Form.Item
                              label="IFSC No."
                              name="ifscNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter IFSC no.",
                                },
                              ]}
                            >
                              <Input placeholder="Enter IFSC number" />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <Form.Item>
                              <Button
                                htmlType="submit"
                                type="primary"
                                style={{ backgroundColor: "#26b689" }}
                              >
                                OK
                              </Button>
                              <span style={{ marginRight: "8px" }}></span>
                              <Button
                                onClick={() => setShowBankModal(false)}
                                style={{ borderColor: "red", color: "red" }}
                              >
                                Cancel
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Modal>
                  </Card>
                </Col>
              </Row>
              {editable && (
                <Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginTop: "20px", backgroundColor: "#26b689" }}
                  >
                    Save
                  </Button>
                </Item>
              )}
            </Form>
          </Card>

          <Card title="Letterhead" style={{ marginBottom: "20px" }}>
            <Card
              title="Header"
              bordered={false}
              style={{ marginBottom: "20px" }}
            >
              <Button
                onClick={() => {
                  formLetterheads.setFieldsValue({
                    type: "Header",
                  });
                  setShowLetterheadModal(!showLetterheadModal);
                }}
                type="primary"
                style={{
                  marginBottom: 16,
                  marginLeft: "85%",
                }}
                ghost
                disabled={!editable}
              >
                Add Header
              </Button>
              <Table columns={headerCols} dataSource={header} size="large" />
            </Card>
            <Card title="Footer" bordered={false}>
              <Button
                onClick={() => {
                  formLetterheads.setFieldsValue({
                    type: "Footer",
                  });
                  setShowLetterheadModal(!showLetterheadModal);
                }}
                type="primary"
                style={{
                  marginBottom: 16,
                  marginLeft: "85%",
                }}
                ghost
                disabled={!editable}
              >
                Add Footer
              </Button>

              <Table columns={footerCols} dataSource={footer} size="large" />
            </Card>
            <Modal
              title="Add Letterheads"
              open={showLetterheadModal}
              onOk={formLetterheads.submit}
              onCancel={() => {
                setShowLetterheadModal(!showLetterheadModal);
              }}
              centered
              okButtonProps={{ style: { backgroundColor: "#26b689" } }}
              cancelButtonProps={{
                style: { borderColor: "red", color: "red" },
              }}
            >
              <Form
                name="uploadLetterheads"
                form={formLetterheads}
                onFinish={handleLetterheadSubmit}
              >
                <Item
                  label="File Type"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the file name",
                    },
                  ]}
                >
                  <Input disabled />
                </Item>

                <Item
                  label="File Name"
                  name="fileName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the file name",
                    },
                  ]}
                >
                  <Input />
                </Item>
                <Item
                  label="File"
                  name="file"
                  rules={[
                    {
                      required: true,
                      message: "Please upload a file",
                    },
                  ]}
                >
                  <Upload
                    Upload
                    name="productPhoto"
                    accept=".jpg, .png"
                    beforeUpload={handleFileUpload}
                    multiple={false}
                  >
                    <Button>Click to Upload</Button>
                  </Upload>
                </Item>
              </Form>
            </Modal>
            <Image
              preview={{
                visible,
                src: img,
                onVisibleChange: (value) => setVisible(value),
              }}
            />
          </Card>

          {/* <Card
            title="Systems"
            extra={
              <Button
                type="primary"
                onClick={() => setShowSystemModal(true)}
                ghost
              >
                Add Systems
              </Button>
            }
            style={{ marginBottom: "20px" }}
          >
            <Table columns={columns} dataSource={systems} size="middle" />
            <Modal
              title="Add System"
              open={showSystemModal}
              onOk={() => handleSystemOk(false)}
              onCancel={handleSystemCancel}
              centered
              okText="Add"
            >
              <Form
                form={systemForm}
                layout="vertical"
                onFinish={(value) => console.log(value)}
              >
                <Form.Item
                  name="name"
                  label="Enter Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input placeholder="e.g. Hydrant" tabIndex={51} />
                </Form.Item>
                <Item label="Data" name="data" hidden></Item>

                <Form.Item
                  name="description"
                  label="Enter Description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="e.g. Highly durable hydrant"
                    tabIndex={52}
                  />
                </Form.Item>
              </Form>
            </Modal>

            <Modal
              title="Edit System"
              open={showSystemEditModal}
              onOk={() => handleSystemOk(true)}
              onCancel={handleSystemCancel}
              centered
              okText="Save"
            >
              <Form
                form={systemForm}
                layout="vertical"
                onFinish={(value) => console.log(value)}
              >
                <Form.Item
                  name="name"
                  label="Enter Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input placeholder="e.g. Hydrant" tabIndex={51} />
                </Form.Item>
                <Item label="Data" name="data" hidden></Item>

                <Form.Item
                  name="description"
                  label="Enter Description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter description",
                    },
                  ]}
                >
                  <TextArea
                    placeholder="e.g. Highly durable hydrant"
                    tabIndex={52}
                  />
                </Form.Item>
              </Form>
            </Modal>
          </Card> */}
        </Col>
      </Row>
    </>
  );
};

export default Settings;
