import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Image,
  Row,
  Col,
  Card,
  message,
  Select,
  Space,
  Breadcrumb,
} from "antd";
import { Link, useParams, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import APICall from "../../../services/apiservices";
import { useForm } from "antd/es/form/Form";
import { Upload } from "antd";
import {
  UploadOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import moment from "moment";
const { Item } = Form;

const ManageEmployee = ({ isEdit }) => {
  const [form] = useForm();
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const [profileFileId, setProfileFileId] = useState();
  const [aadhaarFileId, setadhaarFileId] = useState();
  const [panFileId, setPanFileId] = useState();
  const [previewImage, setPreviewImage] = useState(null);
  const [aadhaarPreviewImage, setAadhaarPreviewImage] = useState(null);
  const [panPreviewImage, setPanPreviewImage] = useState(null);
  // const [employee, setEmployee] = useState({});
  const params = useParams();
  const getEmployeeData = async (employeeId) => {
    const response = await APICall(`/employee/${employeeId}`, {}, 1);
    setPreviewImage(response.data.user.photoURL);
    setAadhaarPreviewImage(response.data.aadhaarURL);
    setPanPreviewImage(response.data.panURL);
    console.log(response.data, "5555");
    form.setFieldsValue({
      name: response.data.user.name,
      email: response.data.user.email,
      birthdate: dayjs(new Date(response?.data?.user?.birthdate)),
      aadhaarId: response.data.user.aadhaarId,
      address: response.data.user.address,
      mobile: response.data.user.mobile,
      qualification: response.data.qualification,
      accountNumber: response?.data?.bankAccounts[0]?.accountNumber,
      bankName: response?.data?.bankAccounts[0]?.bankName,
      accountName: response?.data?.bankAccounts[0]?.accountName,
      ifscCode: response?.data?.bankAccounts[0]?.ifscCode,
      employeeCode: response.data.employeeCode,
      joiningDate: dayjs(new Date(response?.data?.joiningDate)),
      salaryPerDay: response.data.salaryPerDay,
      designation: response.data.designation,
      salary: response.data.salary,
      otRatePerHr: response.data.otRatePerHr,
      pan: response.data.pan,
      accessType: response.data.user.employee.accessType,
    });
  };

  const onFinish = async (values) => {
    console.log("Submitting form with values:", values);
    console.log("Profile File ID:", profileFileId);
    console.log(aadhaarFileId);
    console.log(panFileId);
    try {
      if (isEdit) {
        const res = await APICall(
          `/employee/${params.employeeId}`,
          {
            ...values,
            photoFileId: profileFileId,
            aadhaarFileId: aadhaarFileId,
            panFileId: panFileId,
            salaryPerDay: 0,
          },
          3
        );
        navigate("/employees");
        message.success("Employee has been updated!");
        console.log(res);
      } else {
        const res = await APICall(
          "/employee",
          {
            ...values,
            photoFileId: profileFileId,
            aadhaarFileId: aadhaarFileId,
            panFileId: panFileId,
            isActive: true,
            salaryPerDay: 0,
          },
          2
        );
        message.success("Employee has been created!");
        navigate("/employees");
      }
    } catch (e) {
      message.error(e?.response?.data?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    if (isEdit) getEmployeeData(employeeId);
  }, [isEdit, employeeId]);

  const handleProfileUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);

    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Employee",
    };
    try {
      const fileResponse = await APICall("/file", fileData, 2);
      await axios.put(fileResponse.data.data[0].url, file);
      setProfileFileId(fileResponse.data.data[0].id);
      message.success("Profile image uploaded successfully!");
    } catch (error) {
      message.error("Failed to upload profile image");
    }

    return false;
  };

  const handleAadhaarUpload = async (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setAadhaarPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);

    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Employee",
    };

    const fileResponse = await APICall("/file", fileData, 2);
    await axios.put(fileResponse.data.data[0].url, file);

    setadhaarFileId(fileResponse.data.data[0].id);

    return false;
  };

  const handlePanUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setPanPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Employee",
    };

    const fileResponse = await APICall("/file", fileData, 2);
    await axios.put(fileResponse.data.data[0].url, file);

    setPanFileId(fileResponse.data.data[0].id);

    return false;
  };

  const validateAccountNumber = (_, value) => {
    if (value.length < 9 || value.length > 15) {
      return Promise.reject("Input length must be between 9 and 14 characters");
    }
    return Promise.resolve();
  };

  function disabledDate(current) {
    const now = moment();
    return current && current < now.startOf("day");
  }

  function disabledBirthDates(current) {
    const now = moment();
    // Disable future years except today's year
    const futureYearsDisabled = current.year() > now.year();
    // Disable dates in the next month
    const nextMonthDisabled =
      current.year() === now.year() && current.month() > now.month();
    // Disable dates after today's date
    const pastToday = current.isAfter(now, "day");

    return futureYearsDisabled || nextMonthDisabled || pastToday;
  }

  function disabledJoiningDate(current) {
    const now = moment().startOf("day");
    const previousYearDisabled = current.isBefore(moment().startOf("year"));
    const previousMonthDisabled = current.isBefore(moment().startOf("month"));
    const beforeCurrentDateDisabled =
      current.isBefore(now) && !current.isSame(now, "day");
    const currentAndNextYearEnabled = current.year() <= now.year() + 1;

    return (
      previousYearDisabled ||
      previousMonthDisabled ||
      beforeCurrentDateDisabled ||
      !currentAndNextYearEnabled
    );
  }

  const validateConfirmAccountNumber = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!value || getFieldValue("accountNumber") === value) {
        return Promise.resolve();
      }
      return Promise.reject("The account number do not match!");
    },
  });

  const handlePhotoDelete = () => {
    setPreviewImage(null);
    setProfileFileId(null);
    message.info("File deleted.");
  };

  const handleAadhaarDelete = () => {
    setAadhaarPreviewImage(null);
    message.info("File deleted.");
  };

  const handlePanDelete = () => {
    setPanPreviewImage(null);
    message.info("File deleted.");
  };

  const breadcrumbItems = isEdit ? (
    <>
      <Breadcrumb.Item key="employees">
        <Link to="/employees">Employees</Link>
      </Breadcrumb.Item>

      <Breadcrumb.Item key="Form">
        <Link to="#">Edit</Link>
      </Breadcrumb.Item>
    </>
  ) : (
    <>
      <Breadcrumb.Item key="employees">
        <Link to="/employees">Employees</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item key="Form">
        <Link to="#">Create</Link>
      </Breadcrumb.Item>
    </>
  );

  return (
    <>
      <div style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}>
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Row className="scroll-container">
        <Col span={24}>
          <Card title={isEdit ? "Edit Employee" : "Create Employee"}>
            <Form form={form} layout="vertical" onFinish={onFinish}>
              <Row gutter={10}>
                <Col span={24}>
                  <Card
                    title="Personal Information"
                    bordered={false}
                    style={{ marginBottom: "20px" }}
                  >
                    <Row gutter={20}>
                      <Col span={8}>
                        <Item
                          label="Name"
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the employee name",
                            },
                            {
                              pattern: /^[a-zA-Z\s'-]+$/,
                              message:
                                "Invalid name. Please enter a alphabetic letters.",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. Rahul Kumar" tabIndex={1} />
                        </Item>

                        <Item
                          label="Date of Birth"
                          name="birthdate"
                          rules={[
                            {
                              required: true,
                              message: "Please select the date of birth",
                            },
                          ]}
                        >
                          <DatePicker
                            disabledDate={disabledBirthDates}
                            placeholder="e.g. 15/08/1998"
                            style={{ width: "100%" }}
                            tabIndex={4}
                          />
                        </Item>
                      </Col>

                      <Col span={8}>
                        <Item
                          label="Mobile"
                          name="mobile"
                          rules={[
                            {
                              required: true,
                              message: "Mobile number is required.",
                            },
                            {
                              pattern: /^[0-9]{10}$/,
                              message:
                                "Invalid mobile number. Please enter a valid 10-digit mobile number.",
                            },
                          ]}
                        >
                          <Input
                            placeholder="e.g. 7744953400"
                            tabIndex={2}
                            maxLength={10}
                          />
                        </Item>

                        <Item
                          label="Employee Qualification"
                          name="qualification"
                          rules={[
                            {
                              required: true,
                              message:
                                "Please enter the employee qualification",
                            },
                          ]}
                        >
                          <Input
                            placeholder="e.g. BA, 12th, etc."
                            tabIndex={5}
                          />
                        </Item>
                      </Col>

                      <Col span={8}>
                        <Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Email is required.",
                            },
                            // {
                            //   type: "email",
                            //   message:
                            //     "Invalid email address. Please enter a valid email address.",
                            // },
                            {
                              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                              message:
                                "Invalid email address. Please enter a valid email address.",
                            },
                          ]}
                        >
                          <Input
                            placeholder="e.g. rahul.kumar@gmail.com"
                            tabIndex={3}
                          />
                        </Item>

                        <Item
                          label="Aadhaar Number"
                          name="aadhaarId"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the Aadhaar number",
                            },
                            {
                              pattern: /^\d{12}$/,
                              message:
                                "Invalid aadhar number. Please enter a valid 12-digit aadhar number.",
                            },
                          ]}
                        >
                          <Input
                            placeholder="e.g. 1234 5678 9012"
                            maxLength={12}
                          />
                        </Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col span={16}>
                        <Item
                          label="Address"
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the employee address",
                            },
                          ]}
                        >
                          <TextArea
                            rows={3}
                            placeholder="e.g. 12th Road, Kumar Nagar, Shah Wasti, Pune, Maharashtra 411001"
                          />
                        </Item>
                      </Col>
                      <Col spam={8}>
                        <Item label="Profile Image" name="file">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Upload
                              beforeUpload={handleProfileUpload}
                              multiple={false}
                              showUploadList={false}
                            >
                              <Button
                                icon={
                                  isEdit ? (
                                    <ReloadOutlined />
                                  ) : (
                                    <UploadOutlined />
                                  )
                                }
                              >
                                {isEdit ? "Re-upload" : "Click to Upload"}
                              </Button>
                            </Upload>
                            {previewImage && previewImage !== "" && (
                              <Space
                                style={{
                                  marginLeft: "5px",
                                }}
                              >
                                <Image
                                  src={previewImage}
                                  width={30}
                                  height={30}
                                  style={{ borderRadius: "15px" }}
                                />
                                <Button
                                  type="text"
                                  icon={
                                    <DeleteOutlined style={{ color: "red" }} />
                                  }
                                  onClick={handlePhotoDelete}
                                />
                              </Space>
                            )}
                          </div>
                        </Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col span={6}>
                        <Item label="Upload Aadhaar" name="aadhaarFile">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Upload
                              beforeUpload={handleAadhaarUpload}
                              multiple={false}
                              showUploadList={false}
                            >
                              <Button
                                icon={
                                  isEdit ? (
                                    <ReloadOutlined />
                                  ) : (
                                    <UploadOutlined />
                                  )
                                }
                              >
                                {isEdit ? "Re-upload" : "Click to Upload"}
                              </Button>
                            </Upload>
                            {aadhaarPreviewImage && ( // Ensure you have a variable for Aadhaar preview image
                              <Space
                                style={{
                                  marginLeft: "5px",
                                }}
                              >
                                <Image
                                  src={aadhaarPreviewImage}
                                  alt="Aadhaar Preview"
                                  width={30}
                                  height={30}
                                />
                                <Button
                                  type="text"
                                  icon={
                                    <DeleteOutlined style={{ color: "red" }} />
                                  }
                                  onClick={handleAadhaarDelete} // Make sure you have a delete handler for Aadhaar
                                />
                              </Space>
                            )}
                          </div>
                        </Item>
                      </Col>
                      <Col spam={6}>
                        <Item label="Upload Pan" name="panFile">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Upload
                              beforeUpload={handlePanUpload}
                              multiple={false}
                              showUploadList={false}
                            >
                              <Button
                                icon={
                                  isEdit ? (
                                    <ReloadOutlined />
                                  ) : (
                                    <UploadOutlined />
                                  )
                                }
                              >
                                {isEdit ? "Re-upload" : "Click to Upload"}
                              </Button>
                            </Upload>
                            {panPreviewImage && ( // Ensure you have a variable for Aadhaar preview image
                              <Space
                                style={{
                                  marginLeft: "5px",
                                }}
                              >
                                <Image
                                  src={panPreviewImage}
                                  alt="Aadhaar Preview"
                                  width={30}
                                  height={30}
                                />
                                <Button
                                  type="text"
                                  icon={
                                    <DeleteOutlined style={{ color: "red" }} />
                                  }
                                  onClick={handlePanDelete} // Make sure you have a delete handler for Aadhaar
                                />
                              </Space>
                            )}
                          </div>
                        </Item>
                      </Col>
                    </Row>
                  </Card>
                  <Card
                    title="Employee Information"
                    bordered={false}
                    style={{ marginBottom: "20px" }}
                  >
                    <Row gutter={20}>
                      <Col span={8}>
                        <Item
                          label="Employee Designation"
                          name="designation"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the employee designation",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. Service Coordinator" />
                        </Item>

                        {/* <Item
                        label="Salary per day"
                        name="salaryPerDay"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the salary per day",
                          },
                        ]}
                      >
                        <Input placeholder="e.g. 1200" type="number" />
                      </Item> */}
                        <Item
                          label="Salary"
                          name="salary"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the employee salary",
                            },
                            {
                              pattern: /^(?!0$)[0-9]+(?:\.[0-9]{1,2})?$/,
                              message: "Invalid salary number.",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. 22000" type="text" />
                        </Item>
                      </Col>

                      <Col span={8}>
                        <Item
                          label="Date of Joining"
                          name="joiningDate"
                          rules={[
                            {
                              required: true,
                              message: "Please select the date of joining",
                            },
                          ]}
                        >
                          <DatePicker
                            disabledDate={disabledJoiningDate}
                            placeholder="e.g. 15/08/2020"
                            style={{ width: "100%" }}
                          />
                        </Item>
                        <Item
                          label="Ot Rate Per Hour"
                          name="otRatePerHr"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the ot rate per hour",
                            },
                            {
                              pattern: /^\d+$/,
                              message: "Only numbers are allowed",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. 1200" type="text" min={1} />
                        </Item>
                      </Col>
                      <Col span={8}>
                        <Item
                          label="Access Type"
                          name="accessType"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the employee salary",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Selecte access type"
                            options={[
                              {
                                label: "Admin",
                                value: "Admin",
                              },
                              {
                                label: "Sales",
                                value: "Sales",
                              },
                            ]}
                          />
                        </Item>
                      </Col>
                    </Row>
                  </Card>
                  <Card title="Bank Account Information" bordered={false}>
                    <Row gutter={20}>
                      <Col span={8}>
                        <Item
                          label="Bank Name"
                          name="bankName"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the bank name",
                            },
                            {
                              pattern: /^[A-Za-z\s]+$/,
                              message:
                                "Bank name should only contain alphabetic characters",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. HDFC Bank" />
                        </Item>
                        <Item
                          label="Account Holder Name"
                          name="accountName"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the account holder name",
                            },
                            {
                              pattern: /^[A-Za-z\s]+$/,
                              message:
                                "Please enter only alphabetic characters",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. Rahul Vivek Mishra" />
                        </Item>
                      </Col>

                      <Col span={8}>
                        <Item
                          label="Bank IFSC Code"
                          name="ifscCode"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the bank IFSC code",
                            },
                            {
                              pattern: /^[A-Z]{4}0[A-Z0-9]{6}$/,
                              message:
                                "Invalid IFSC number. Please enter a valid IFSC code.",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. HDFC00123" maxLength={11} />
                        </Item>
                        <Item
                          label="PAN Number"
                          name="pan"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the pan number",
                            },
                            {
                              pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                              message:
                                "Invalid Pan number. Please enter a valid pan number.",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. ABCTY1234D" maxLength={10} />
                        </Item>
                      </Col>
                      <Col span={8}>
                        <Item
                          label="Bank Account Number"
                          name="accountNumber"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the bank account number",
                            },
                            {
                              pattern: /^(?!0$)[0-9]+(?:\.[0-9]{1,2})?$/,
                              message: "Invalid Account number.",
                            },
                            // {
                            //   validator: validateAccountNumber,
                            // },
                          ]}
                        >
                          <Input
                            placeholder="e.g. 987412320004001"
                            type="text"
                          />
                        </Item>
                        <Item
                          label="Confirm Account Number"
                          name="confirmaccountNumber"
                          dependencies={["accountNumber"]}
                          rules={[
                            {
                              required: true,
                              message: "Please enter the bank account number",
                            },
                            validateConfirmAccountNumber,
                          ]}
                        >
                          <Input
                            placeholder="e.g. 987412320004001"
                            type="text"
                          />
                        </Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Item style={{ marginTop: "20px", marginLeft: "20px" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#26b689",
                  }}
                >
                  {isEdit ? "Save" : "Create"}
                </Button>
              </Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ManageEmployee;
