import { Card, Col, Row, Table, Button } from "antd";
import React, { useState, useEffect } from "react";
import APICall from "../../services/apiservices";
import moment from "moment";
import { Link } from "react-router-dom";
const Reports = () => {
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const getReports = async () => {
    const res = await APICall(
      `/report?customerId=${userData.customer._id}`,
      {},
      1
    );
    setReportData(res.data.documents);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getReports();
  }, []);

  const getTypeLink = (type) => {
    switch (type) {
      case "Training Certificate":
        return "trainingCertificate";
      case "Test Certificate":
        return "testingCertificate";
      case "Form A":
        return "formA";
      case "Form B":
        return "formB";
      case "Installation Certificate":
        return "installationCertificate";
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (currentPage - 1) * pageSize + index + 1;
      },
    },
    {
      title: "Report Code",
      key: "reportCode",
      render: (_, record) => record?.reportCode,
    },
    {
      title: "Report Type",
      key: "reportType",
      render: (_, record) => record?.type,
    },
    {
      title: "Issuer",
      key: "issuer",
      render: (_, record) => record?.issuer?.user?.name,
    },
    {
      title: "Issued Date",
      key: "date",
      render: (_, record) => moment(record?.date).format("DD/MM/YYYY"),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Link to={`/view/${getTypeLink(record.type)}/${record._id}`}>
          <Button type="primary">View Report</Button>
        </Link>
      ),
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card title="Reports" loading={isLoading}>
          <Table
            columns={columns}
            dataSource={reportData}
            pagination={{
              defaultPageSize: 10,
              current: currentPage,
              onChange: (page) => {
                setCurrentPage(page);
              },
            }}
            rowKey={(record) => record.id}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default Reports;
