import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import { LiaInfoCircleSolid } from "react-icons/lia";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  message,
  Modal,
  Dropdown,
  Spin,
  Form,
  Input,
  Checkbox,
  Space,
  Tooltip,
  Tag,
  Descriptions,
  Breadcrumb,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import APICall from "../../services/apiservices";
import { async } from "q";
import { Duplex, values } from "pdf-lib";

const { Search } = Input;
const { confirm } = Modal;

const Employees = ({ isActive }) => {
  const params = useParams();
  const [empData, setEmpData] = useState({});
  const [employees, setEmployees] = useState([]);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [totalEmployeeCount, setTotalEmployeeCount] = useState(0);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getEmployees = async (searchTerm = "", page = 1, limit = 10) => {
    try {
      const url = isActive
        ? `/employee?page=${page}&limit=${limit}&isActive=${isActive}&query=${searchTerm}`
        : `/employee?page=${page}&limit=${limit}&isInactive=${true}&query=${searchTerm}`;
      const response = await APICall(url, {}, 1);
      return response.data;
      // setEmployees(response.data.documents);
      // setIsLoading(false);
    } catch (error) {
      console.error("Error fetching employee data:", error);
      message.error("failed to get employee data");
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   getEmployees(1, 10);
  // }, [reload]);

  const fetchList = async () => {
    setIsLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";

    try {
      const response = await getEmployees(searchTerm, page, limit);
      if (response?.documents) {
        setEmployees(response.documents);
        setTotalEmployeeCount(response.total);
      } else {
        setEmployees([]); // Clear employees if no data
      }

      setTableParams((prevParams) => ({
        ...prevParams,
        pagination: {
          ...prevParams.pagination,
          total: response?.total || 0,
        },
      }));
    } catch (error) {
      message.error("Failed to fetch data");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams), isActive]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination,
      filters: {
        ...prevParams.filters,
        ...filters,
      },
      sorter,
    }));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSearch = (value) => {
    setSearchInput(value);
    setTableParams((prevParams) => ({
      ...prevParams,
      filters: {
        ...prevParams.filters,
        searchTerm: value,
      },
      pagination: {
        ...prevParams.pagination,
        current: 1, // Reset pagination to first page on new search
      },
    }));
  };
  const getEmployee = async () => {
    const res = await APICall(`/employee/${params.employeeId}`, {}, 1);
    setEmpData(res.data);
  };
  const changeStatus = async (record) => {
    confirm({
      title: `${
        record?.user?.isActive ? "Inactive" : "Active"
      } employee account`,
      content: `Are you sure you want to ${
        record?.user?.isActive ? "disable" : "enable"
      } employee ${record?.user?.name}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          let res;
          if (record?.user?.isActive) {
            res = await APICall(`/user/${record?.user?._id}/disable`, {}, 3);
          } else {
            res = await APICall(`/user/${record?.user?._id}/enable`, {}, 3);
          }
          // setReload(true);
          if (res.data) {
            message.success(
              `Employee  ${
                record?.user?.isActive ? "disabled" : "enabled"
              } successfully!`
            );
            fetchList();
          }
        } catch (error) {
          // console.log(error);
          message.error(error.response.data.message);
        }
      },
    });
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
      responsive: ["md"],
    },
    {
      title: "Emp. Code",
      key: "empCode",
      render: (_, record) => {
        return (
          <Link to={`/employees/${record._id}`}>
            FFSS/EMP/{record.employeeCode.padStart(4, 0)}
          </Link>
        );
      },
      responsive: ["md"],
      width: 120,
    },
    {
      title: "Name",
      key: "empName",
      render: (_, record) => {
        return <Link to={`/employees/${record._id}`}>{record.user.name}</Link>;
      },
      responsive: ["md"],
      width: 120,
    },
    {
      title: "Access Type",
      dataIndex: "accessType",
      key: "accessType",
      render: (_, record) => (
        <Tag
          color={
            // (record.accessType === "Super Admin" && "green") ||
            (record.accessType === "Admin" && "blue") ||
            // (record.accessType === "General Employee" && "blue") ||
            (record.accessType === "Sales" && "pink")
          }
        >
          {record.accessType}
        </Tag>
      ),
      width: 120,
    },

    {
      title: "Email",
      key: "email",
      render: (_, record) => {
        return <a href={`mailto:${record.user.email}`}>{record.user.email}</a>;
      },

      responsive: ["lg"],
      width: 150,
    },
    {
      title: "Mobile",
      key: "mobile",
      render: (_, record) => {
        return (
          <a href={`tel:+91${record.user.mobile}`}>{record.user.mobile}</a>
        );
      },
    },
    {
      title: () => {
        return (
          // <Row align="middle">
          //   Attendence
          //   <Tooltip title="Attendence of the current month">
          //     <LiaInfoCircleSolid
          //       style={{
          //         margin: "0px 0px 0px 10px",
          //         color: "red",
          //         fontSize: "18px",
          //       }}
          //     />
          //   </Tooltip>
          // </Row>
          <Tooltip title="Attendence of the current month">Attendance</Tooltip>
        );
      },
      // title: "Attendance (Last Month)",
      dataIndex: "attendanceCount",
      key: "attendance",
    },
    {
      title: "Access Status",
      key: "accessStatus",
      render: (_, record) => (
        <Tag
          style={{ cursor: "pointer" }}
          color={record.user.isActive ? "green" : "red"}
          onClick={() =>
            changeStatus(record, record.user.isActive ? "enable" : "disable")
          }
        >
          {record.user.isActive ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: <Link to={`/employees/${record._id}`}>View</Link>,
        //       },
        //       {
        //         key: 2,
        //         label: <Link to={`/employees/manage/${record._id}`}>Edit</Link>,
        //       },
        //       {
        //         key: 3,
        //         label: "Create Offer Letter",
        //         onClick: () => {
        //           navigate(`/employees/${record._id}/offerLetter`);
        //         },
        //       },

        //       {
        //         key: 4,
        //         label: "Reset Password",
        //         onClick: () => {
        //           handleResetpassword(record);
        //         },
        //       },
        //       {
        //         key: 5,
        //         label: "Send Password via Email",
        //         onClick: () => {
        //           handleSendPassword(record);
        //         },
        //       },
        //       {
        //         key: 6,
        //         label: isActive ? "Disable" : "Enable",
        //         onClick: () => {
        //           handleDeactivate(record);
        //         },
        //         danger: isActive ? true : false,
        //       },
        //     ],
        //   }}
        //   placement="bottom"
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Button
          type="primary"
          onClick={() => navigate(`/employees/${record._id}`)}
          ghost
        >
          View
        </Button>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const breadcrumbItems = [
    <Breadcrumb.Item key="employees">
      <Link to="#">Employees</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <>
      <div style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}>
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      {isLoading ? (
        <Row
          justify="center"
          align="middle"
          style={{ position: "absolute", top: "50%", left: "50%" }}
        >
          <Spin tip={"Loading"} />
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Card
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  {isActive ? "Employees" : "Inactive Employees"}
                  <span
                    style={{
                      margin: "3px 0px 0px 4px",
                      fontSize: "14px",
                    }}
                  >
                    ({totalEmployeeCount})
                  </span>
                </div>
              }
              extra={
                <Row
                  justify={"end"}
                  align="top"
                  style={{ paddingBottom: "10px" }}
                >
                  <Space>
                    <Search
                      // className="search-Secondary"
                      style={{
                        width: 220,
                      }}
                      placeholder="Search an employee"
                      allowClear
                      enterButton
                      // loading={loading}
                      size="middle"
                      value={searchInput}
                      onChange={handleSearchChange}
                      onSearch={onSearch}
                    />
                    {isActive && (
                      <Link to="/employees/manage">
                        <Button
                          type="primary"
                          icon={<LuPlus style={{ paddingTop: "2px" }} />}
                        >
                          Create Employee
                        </Button>
                      </Link>
                    )}
                  </Space>
                </Row>
              }
            >
              <Table
                isLoading={isLoading}
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={employees}
                size="small"
                pagination={tableParams.pagination}
                onChange={handleTableChange}
                scroll={{ x: "1000px" }}
              />
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Employees;
