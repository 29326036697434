import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  Space,
  Image,
  Upload,
  message,
  Dropdown,
  Menu,
  Modal,
  Table,
  Breadcrumb,
  Popconfirm,
} from "antd";
import APICall from "../../../services/apiservices";
import { DownOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { LiaExternalLinkAltSolid } from "react-icons/lia";

const { Search } = Input;
const ManageSeats = () => {
  const form = Form.useForm()[0];
  const topRef = useRef(null);
  const [seatData, setItemData] = useState(null);
  // const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [img, setImg] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [photoFileId, setPhotoFileId] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [photoURL, setPhotoURL] = useState();
  const [seats, setSeats] = useState([]);
  const [reload, setReload] = useState(false);
  const [originalSeats, setOriginalSeats] = useState([]);
  const { confirm } = Modal;
  const [previewImage, setPreviewImage] = useState({
    url: seatData?.photoURL || null,
    name: seatData?.photoName || "",
  });
  const [imageUrl, setImageUrl] = useState(null);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {},
    sorter: {},
  });
  const [isEdit, setIsEdit] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);

  const showImage = (img) => {
    setImg(img);
    setVisible(true);
  };

  const navigate = useNavigate();
  const params = useParams();

  const getItemDetails = async () => {
    try {
      const response = await APICall(`/seat/${params.id}`, {}, 1);
      setItemData(response.data);
      setImg(response.data.photoURL);
      setIsEdit(true);
      setIsLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  const getSeats = async (searchTerm, page, limit) => {
    try {
      const response = await APICall("/seat", {}, 1);
      setSeats(response.data.documents);
      setOriginalSeats(response.data.documents);
      setIsLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (params.id) {
      setIsLoading(true);
      getItemDetails();
    } else {
      getSeats();
    }
  }, [params.id]);

  useEffect(() => {
    if (seatData) {
      const initialValues = {
        name: seatData?.name,
        hsn: seatData?.hsn,
        price: seatData?.price,
        sgst: seatData?.sgst,
        cgst: seatData?.cgst,
        description: seatData?.description,
        photo: seatData?.photo,
      };
      form.setFieldsValue(initialValues);
    }
  }, [seatData]);

  const handleView = async (seatId) => {
    try {
      const response = await APICall(`/seat/${seatId}`, {}, 1);
      setSelectedItem(response.data); // Set the selected seat's details
      setVisible(true); // Show the modal
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handleCancel = () => {
    setVisible(false); // Hide the modal
  };

  useEffect(() => {
    const getSeats = async () => {
      try {
        const response = await APICall("/seat", {}, 1);
        setSeats(response.data.documents);
        setIsLoading(false);
      } catch (error) {
        message.error(error.response.data.message);
        setIsLoading(false);
      }
    };

    getSeats();
  }, []);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      let response;
      if (params.id) {
        response = await APICall(
          `/seat/${params.id}`,
          { ...values, photoFileId: photoFileId },
          3
        );
      } else {
        response = await APICall(
          `/seat`,
          { ...values, photoFileId: photoFileId },
          2
        );
      }
      if (response.data) {
        setIsLoading(false);
        if (params.id) {
          message.success("Seat has been updated successfully.");
        } else {
          message.success("Seat has been created successfully.");
        }
        getSeats();
        navigate("/items/manage");
        setPreviewImage({ name: "" });
        setPhotoFileId(null);
      }
    } catch (error) {
      setIsLoading(false);
      message.error(error.response.data.message);
    }
    form.resetFields();
    setIsEdit(false);
  };

  useEffect(() => {
    if (seatData) {
      setPreviewImage({
        url: seatData.photoURL || null,
        name: seatData.photoName || "",
      });
    }
  }, [seatData]);

  const handleEdit = (record) => {
    setIsEdit(true);
    form.setFieldsValue(record); // Assuming you are using antd Form to set initial values
    setPreviewImage({ name: record.photoName, url: record.photoURL });
  };

  const handleFileUpload = async (file) => {
    const reader = new FileReader();
    console.log(reader.result);
    console.log(file.name);
    reader.onload = () => {
      setPreviewImage({ name: file.name, url: reader.result });
    };
    reader.readAsDataURL(file);

    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Items",
    };

    const fileResponse = await APICall("/file", fileData, 2);
    const uploadUrl = fileResponse.data.data[0].url;
    await axios.put(fileResponse.data.data[0].url, file);

    setUploading(false);

    message.success(`${file.name} file uploaded successfully`);

    setPhotoFileId(fileResponse.data.data[0].id);

    return false;
    // try {
    //   const base64Image = await getBase64(file);
    //   setPreviewImage({ name: file.name });
    //   setImageUrl(base64Image); // Store base64 image for preview
    //   // You can also upload the file to your server and store the URL in the database
    //   // Then setPhotoURL with the uploaded image URL
    // } catch (error) {
    //   console.error("Error uploading file:", error);
    //   message.error("Error uploading file. Please try again.");
    // }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onSearch = (value) => {
    if (value === "") {
      setSeats(originalSeats);
    } else {
      const filteredSeats = originalSeats.filter((seat) =>
        seat.name.toLowerCase().includes(value.toLowerCase())
      );
      setSeats(filteredSeats);
    }
  };

  const handleReset = () => {
    form.resetFields();
  };
  const handleDelete = async (recordId) => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete this seat?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          const response = await APICall(`/seat/${recordId}`, {}, 4);
          console.log("delete", response);
          message.success("Item deleted successfully!");
          const updatedSeats = seats.filter((seat) => seat.id !== recordId);
          getSeats(updatedSeats); // Assuming 'seats' is your state containing the list of seats
        } catch (error) {
          message.error(
            error.response?.data?.message || "An error occurred while deleting."
          );
        }
      },
    });
  };

  const handlePhotoDelete = () => {
    setPreviewImage({ name: "" });
    setPhotoFileId(null);
    message.info("File deleted.");
  };

  const handleEditClick = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        console.log(record, "record");
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (_, record) => {
        return <Image src={record.photoURL} width={60} />;
      },
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => {
        return record.name;
      },
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (_, record) => {
        return record.description;
      },
      width: "40%",
    },
    {
      title: "Price (₹)",
      dataIndex: "price",
      render: (_, record) => {
        return <>₹{record.price}</>;
      },
      width: "10%",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="view" onClick={() => handleView(record._id)}>
                View
              </Menu.Item>
              <Menu.Item key="edit">
                <Link to={`/items/manage/${record._id}`}>Edit</Link>
              </Menu.Item>
              <Menu.Item
                key="delete"
                danger
                onClick={() => handleDelete(record._id)}
              >
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="primary" ghost>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const breadcrumbSeats = [
    <Breadcrumb.Item key="clients">
      <Link to="#">Seats</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <>
      <div
        type="inner"
        style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}
      >
        <Breadcrumb separator=">">{breadcrumbSeats}</Breadcrumb>
      </div>
      {isEdit ? (
        <Card title="Edit Seat">
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the name of the seat",
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Name should only contain alphabetic characters",
                    },
                  ]}
                >
                  <Input placeholder="e.g.Cabin" tabIndex={1} />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the description of the seat",
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Enter description of the seat"
                    tabIndex={6}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="price"
                  label="Price (₹)"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the price of the seat",
                    },
                    {
                      pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                      message: "Please enter a valid price",
                    },
                  ]}
                >
                  <Input placeholder="e.g. 30000" tabIndex={3} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="image" label="Photo">
                  <Space>
                    <Upload
                      name="productPhoto"
                      accept=".jpg, .png, .jpeg "
                      beforeUpload={(file) => handleFileUpload(file, "image")}
                      showUploadList={false}
                    >
                      <Button style={{ marginTop: 0 }}>Re-Upload</Button>
                    </Upload>
                    {previewImage.url && (
                      <Space>
                        <Image
                          src={previewImage.url}
                          alt={previewImage.name}
                          width={30}
                          height={30}
                        />
                        <Button
                          type="text"
                          icon={<DeleteOutlined style={{ color: "red" }} />}
                          onClick={handlePhotoDelete}
                        />
                      </Space>
                    )}
                    {/* {seatData?.photoURL && (
                      <div>
                        <div>
                          <Image
                            src={seatData.photoURL}
                            alt={seatData.photoName}
                            width={30}
                            height={30}
                          />
                        </div>
                      </div>
                    )} */}
                  </Space>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item>
                  <Button
                    style={{ backgroundColor: "#26b689", color: "white" }}
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <Button
                    type="default"
                    style={{
                      borderColor: "red",
                      color: "red",
                      margin: "0px 0px 0px -420px",
                    }}
                    htmlType="button"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      ) : (
        <Card title="Create Seat">
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the name of the seat",
                    },
                    {
                      pattern: /^[A-Za-z\s]+$/,
                      message: "Name should only contain alphabetic characters",
                    },
                  ]}
                >
                  <Input placeholder="e.g. Cabin" tabIndex={1} />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the description of the seat",
                    },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Enter description of the seat"
                    tabIndex={6}
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="price"
                  label="Price (₹)"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the price of the seat",
                    },
                    {
                      pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                      message: "Please enter a valid price",
                    },
                  ]}
                >
                  <Input placeholder="e.g. 5000" tabIndex={3} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="image" label="Photo">
                  <Row gutter={16} align="middle">
                    <Col>
                      <Upload
                        name="productPhoto"
                        accept=".jpg, .png"
                        beforeUpload={handleFileUpload}
                        showUploadList={false}
                      >
                        <Button>Upload</Button>
                      </Upload>
                    </Col>
                    {previewImage.name && (
                      <Col>
                        <Space align="center">
                          <p style={{ margin: 0 }}>{previewImage.name}</p>
                          <Button
                            type="text"
                            icon={<DeleteOutlined style={{ color: "red" }} />}
                            onClick={handlePhotoDelete}
                            style={{ marginLeft: 8 }}
                          />
                        </Space>
                      </Col>
                    )}
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col>
                <Form.Item>
                  <Button
                    style={{ backgroundColor: "#26b689", color: "white" }}
                    htmlType="submit"
                    loading={isLoading}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                  {isEdit && (
                    <Button
                      type="danger"
                      style={{ marginLeft: "10px" }}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  )}
                </Form.Item>
              </Col>
              {/* <Col span={2}>
                <Form.Item>
                  <Button
                    type="default"
                    style={{ borderColor: "red", color: "red" }}
                    htmlType="button"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Col> */}
            </Row>
          </Form>
        </Card>
      )}

      {/* {!isEdit && ( */}
      <Card
        title="Seats"
        style={{ marginTop: "20px" }}
        extra={
          <Row justify={"end"} align="top" style={{ paddingBottom: "10px" }}>
            <Space>
              <Search
                style={{
                  width: 220,
                }}
                placeholder="Search an seat"
                allowClear
                enterButton
                size="middle"
                onSearch={onSearch}
              />
            </Space>
          </Row>
        }
      >
        <Table
          columns={columns}
          dataSource={seats}
          rowKey={(record) => record?._id}
          loading={isLoading}
          pagination={{ pageSize: 10 }}
        />
      </Card>
      {/* )} */}

      <Modal
        title="Photo"
        open={visible}
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Image src={img} />
      </Modal>

      <Modal open={visible} footer={null} onCancel={handleCancel}>
        {selectedItem && (
          <Card title="Seat Details">
            <Row gutter={20}>
              <Col span={6}>
                <Image
                  src={selectedItem?.photoURL}
                  style={{
                    width: "100%",
                    height: "80px",
                    objectFit: "cover",
                  }} // Adjust as needed
                />
              </Col>
              <Col span={18}>
                <Form
                  layout="vertical"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Form.Item
                    label={`Name: ${selectedItem?.name}`}
                    style={{ marginTop: 0, marginBottom: "-30px" }}
                  ></Form.Item>
                  <Form.Item
                    label={`Price: ₹${selectedItem?.price}`}
                    style={{ marginTop: 0, marginBottom: "-30px" }}
                  ></Form.Item>
                  <Form.Item
                    label={`Description: ${selectedItem?.description}`}
                    style={{ marginTop: 0, marginBottom: "-30px" }}
                  ></Form.Item>
                </Form>
              </Col>
            </Row>
          </Card>
        )}
      </Modal>
    </>
  );
};

export default ManageSeats;
