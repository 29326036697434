import { Navigate } from "react-router-dom";
import { useAuth } from "../auth/AuthProvider";
import Login from "../pages/Login";
import SuperAdminRoutes from "../superadmin/routes/SuperAdminRoutes";
import EmployeeRoutes from "../employee/routes/EmployeeRoutes";
import CutomerRoutes from "../customer/routes/CutomerRoutes";
// import AdminRoutes from "../admin/routes/AdminRoutes";

export default function AppRoutes() {
  const { authenticated } = useAuth();
  const userData = JSON.parse(localStorage.getItem("userData"));
  if (!userData) {
    localStorage.removeItem("accessToken");
    return <Navigate to="/" replace />;
  }
  if (!authenticated) {
    return <Navigate to="/" replace />;
  }

  if (userData?.employee?.accessType === "Super Admin")
    return <SuperAdminRoutes />;
  // if (userData?.employee?.accessType === "Admin") return <AdminRoutes />;
  if (userData?.employee?.accessType === "General Employee") {
    return <EmployeeRoutes />;
  }
  return <CutomerRoutes />;
}
