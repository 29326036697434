import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
  },
  letterheader: {
    // height: "150px",
    // paddingHorizontal: 16,
    // position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  title: {
    alignItems: "center",
    fontFamily: "Times-Bold",
    justifyContent: "center",
    paddingHorizontal: 32,
  },
  body: {
    fontFamily: "Times-Bold",
    textAlign: "left",
    fontSize: 12,
    paddingHorizontal: 32,
  },
  header: {
    flexDirection: "row",
    fontSize: 8,
    paddingBottom: 10,
    fontFamily: "Times-Roman",
    paddingHorizontal: 32,
  },
  headerSectionLeft: {
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
  },
  headerSectionRight: {
    flex: 1,
    alignItems: "flex-end",
    justifyContent: "center",
  },
  letterfooter: {
    position: "absolute",
    height: "100px",
    bottom: 0,
    left: 0,
    right: 0,
  },
});
function getYearString() {
  const currentYear = new Date().getFullYear();
  const yearString = `${currentYear.toString().slice(-2)}-${(currentYear + 1)
    .toString()
    .slice(-2)}`;
  return yearString;
}

// Create Document Component
const FormBPDF = ({
  letterheader,
  letterfooter,
  referenceNumber,
  headingDate,
  customer,
  company,
  startDate,
  endDate,
  place,
  footerDate,
}) => (
  <Page size="A4" style={styles.page}>
    ?
    {letterheader && (
      <View style={styles.letterheader}>{<Image src={letterheader} />}</View>
    )}
    <View
      style={{
        ...styles.header,
        paddingTop: letterheader ? 5 : 64,
      }}
    >
      <View style={styles.headerSectionLeft}>
        <Text
          style={{ fontFamily: "Times-Bold", paddingBottom: 0, fontSize: 10 }}
        >
          Reference Number:{" "}
          <Text style={{ fontFamily: "Times-Roman" }}>
            FFSS/{getYearString()}/{referenceNumber}
          </Text>
        </Text>
      </View>
      <View style={styles.headerSectionRight}>
        <Text
          style={{ fontFamily: "Times-Bold", paddingBottom: 0, fontSize: 10 }}
        >
          Date: <Text style={{ fontFamily: "Times-Roman" }}>{headingDate}</Text>
        </Text>
      </View>
    </View>
    <View style={styles.title}>
      <Text style={{ ...styles.title, fontSize: 18, paddingBottom: 5 }}>
        FORM B
      </Text>
      <Text style={{ ...styles.title, fontSize: 10, paddingBottom: 5 }}>
        [See section 3(3) and rules 4 (2)]
      </Text>
      <Text
        style={{
          ...styles.title,
          fontSize: 12,
          paddingBottom: 12,
          paddingHorizontal: 20,
          textAlign: "center",
        }}
      >
        Six monthly certificate to be given in every January and July by the
        owner or the occupier for compliance of the Fire Prevention and Life
        Safety Measures
      </Text>
      <Text
        style={{
          ...styles.title,
          fontSize: 20,
          paddingBottom: 12,
          textDecoration: "underline",
        }}
      >
        CERTIFICATE
      </Text>
    </View>
    <View style={styles.body}>
      <Text
        style={{
          paddingBottom: 12,
          fontFamily: "Times-Roman",
          textAlign: "justify",
        }}
      >
        Certified that I/We have carried out inspection of the Fire Prevention
        and Life Safety Measures installed in the following building or
        premises, Namely :-
      </Text>
      <Text style={{ textAlign: "center", fontSize: 13, paddingBottom: 4 }}>
        {customer?.name}
      </Text>
      <Text style={{ textAlign: "center", fontSize: 11, paddingBottom: 12 }}>
        {customer?.address}
      </Text>
      <Text
        style={{
          paddingBottom: 12,
          fontFamily: "Times-Roman",
          textAlign: "justify",
        }}
      >
        I/We further certify that these installations in the above mentioned
        buildings are maintained in good repair and efficient conditions during
        the period from
      </Text>
      <Text
        style={{ textAlign: "center", fontSize: 13, paddingBottom: 12 }}
      >{`${startDate} to ${endDate}`}</Text>
      <Text
        style={{
          paddingBottom: 18,
          fontFamily: "Times-Roman",
          textAlign: "justify",
        }}
      >
        as required under the provisions of the Maharashtra Fire Prevention and
        Life Safety Measures Act, 2006 (Mah. III of 2007) The details of the
        work and related activities which I or we have executed or performed are
        mentioned in the list
      </Text>
      <Text style={{ paddingBottom: 8 }}>
        Place: <Text style={{ fontFamily: "Times-Roman" }}>{place}</Text>
      </Text>
      <Text style={{ paddingBottom: 18 }}>
        Date: <Text style={{ fontFamily: "Times-Roman" }}>{footerDate}</Text>
      </Text>
      <Text style={{ fontSize: 10, paddingBottom: 8 }}>
        Address of the Licensed Agency:-
      </Text>
      <Text style={{ fontSize: 10, paddingBottom: 4 }}>{company?.name}</Text>
      <Text style={{ fontSize: 10, paddingBottom: 8, marginRight: 250 }}>
        {company?.address}
      </Text>
      <Text style={{ fontSize: 10, paddingBottom: 8 }}>License No.:-</Text>
      {company?.licenseNumbers.map((n, index) => (
        <Text style={{ fontSize: 10, paddingBottom: 8 }}>{`${
          index + 1
        }) ${n}`}</Text>
      ))}
      <Text style={{ textAlign: "right", fontSize: 10, paddingBottom: 16 }}>
        Authorised Signature
      </Text>
    </View>
    {letterfooter && (
      <View style={styles.letterfooter}>{<Image src={letterfooter} />}</View>
    )}
  </Page>
);

export default FormBPDF;
