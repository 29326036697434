import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Space,
  Input,
  Breadcrumb,
  Select,
  message,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import APICall from "../../services/apiservices";

const { Search } = Input;
const { confirm } = Modal;

const GuestEntry = () => {
  const [guestEntries, setGuestEntries] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {},
    sorter: {},
  });

  useEffect(() => {
    fetchGuestEntries();
  }, []);

  const fetchGuestEntries = async () => {
    try {
      const response = await APICall("/guest", {}, 1);
      console.log("Fetched Guest Entries:", response.data.documents);
      setGuestEntries(response.data.documents);
    } catch (error) {
      console.error("Error fetching guest entries:", error);
      message.error("Failed to fetch guest entries. Please try again later.");
    }
  };

  const handleDelete = async (recordId) => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete this entry?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await APICall(`/guest/${recordId}`, {}, 4);
          message.success("Entry deleted successfully!");
          const updatedGuestEntries = guestEntries.filter(
            (guest) => guest._id !== recordId
          );
          setGuestEntries(updatedGuestEntries);
        } catch (error) {
          message.error(
            error.response?.data?.message || "An error occurred while deleting."
          );
        }
      },
    });
  };

  const onSearch = (value) => {
    setSearchTerm(value);
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  const filteredGuestEntry = guestEntries.filter((enquiry) =>
    enquiry.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srNo",
      key: "srNo",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Select Client",
      dataIndex: ["clientDetails", "name"],
      key: "user",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Purpose Of Visit",
      dataIndex: "purposeOfVisit",
      key: "purposeOfVisit",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Button>
            <Link to={`/guestEntry/manage/${record._id}`}>Edit</Link>
          </Button>
          <Button key="delete" danger onClick={() => handleDelete(record._id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const breadcrumbItems = [
    <Breadcrumb.Item key="enquiry">
      <Link to="#">Guest Entry</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <>
      <div
        type="inner"
        style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}
      >
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Card
        title="Guest List"
        style={{ marginBottom: 20 }}
        extra={
          <>
            <Space>
              <Search
                style={{
                  width: 220,
                }}
                placeholder="Search an Party"
                allowClear
                enterButton
                // loading={loading}
                size="middle"
                onSearch={onSearch}
              />
              <Button type="primary" style={{ marginRight: 10 }}>
                <Link to="/guestEntry/manage">
                  <PlusOutlined /> Create Entries
                </Link>
              </Button>
            </Space>
          </>
        }
      >
        <Table
          columns={columns}
          dataSource={filteredGuestEntry}
          rowKey={(record) => record.id}
        />
      </Card>
    </>
  );
};

export default GuestEntry;
