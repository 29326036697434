import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LuPlus } from "react-icons/lu";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  message,
  Input,
  Space,
  Dropdown,
  Menu,
  Breadcrumb,
  Modal,
} from "antd";
import moment from "moment";
import APICall from "../../services/apiservices";

const { Search } = Input;
const { confirm } = Modal;

const Purchases = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const [purchases, setPurchases] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(null);

  const getPurchases = async (searchTerm, page, limit) => {
    try {
      if (searchTerm === undefined) searchTerm = "";

      const response = await APICall(
        `/purchase?page=${page}&limit=${limit}&query=${searchTerm}`,
        {},
        1
      );
      return response.data;
    } catch (error) {
      message.error("Failed to fetch Purchase details");
      setIsLoading(false);
      throw error;
    }
  };

  const fetchList = async () => {
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";

    setIsLoading(true);

    try {
      const response = await getPurchases(searchTerm, page, limit);
      setPurchases(response?.documents);
      setIsLoading(false);

      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: response?.total,
        },
      }));
    } catch (error) {
      setIsLoading(false);
      message.error("Failed to fetch purchases");
    }
  };

  useEffect(() => {
    fetchList();
  }, [
    tableParams.filters.searchTerm,
    tableParams.pagination.current,
    tableParams.pagination.pageSize,
  ]);

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        console.log(record, "daata");
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Party Details",
      dataIndex: "partyDetails",
      key: "partyDetails",
      render: (customerId, record) => (
        <>
          <span>{record.customerId}</span>
        </>
      ),
    },
    {
      title: "PO No",
      dataIndex: "poNo",
      key: "poNo",
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
    },
    {
      title: "CGST",
      dataIndex: "cgst",
      key: "cgst",
      render: (cgst) => (cgst ? cgst : "NA"),
    },
    {
      title: "SGST",
      dataIndex: "sgst",
      key: "sgst",
      render: (sgst) => (sgst ? sgst : "NA"),
    },
    {
      title: "IGST",
      dataIndex: "igst",
      key: "igst",
      render: (igst) => (igst ? igst : "NA"),
    },
    {
      title: "Total GST",
      dataIndex: "totalGst",
      key: "totalGst",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1" onClick={() => handleView(record)}>
                View
              </Menu.Item>
              <Menu.Item key="2" onClick={() => handleEdit(record)}>
                Edit
              </Menu.Item>
              <Menu.Item
                key="3"
                danger
                onClick={() => {
                  confirm({
                    title: "Are you sure you want to delete this purchase?",
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    onOk() {
                      handleDelete(record);
                    },
                  });
                }}
              >
                Delete
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link">Actions</Button>
        </Dropdown>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setPurchases([]);
    }
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
      pagination: {
        ...tableParams.pagination,
        current: 1, // Reset to the first page on search
      },
    });
  };

  const handleView = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleEdit = (record) => {
    navigate(`/purchases/manage/${record._id}`);
  };

  const handleDelete = async (record) => {
    try {
      const response = await APICall(`/purchase/${record._id}`, {}, 4);
      message.success("Purchase deleted successfully");
      fetchList();
    } catch (error) {
      message.error("Failed to delete purchase");
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedRecord(null); // Clear selected record after modal close
  };

  const breadcrumbItems = [
    <Breadcrumb.Item key="clients">
      <Link to="#">Purchases</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <>
      <div
        type="inner"
        style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}
      >
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Row>
        <Col span={24}>
          <Card
            title="Purchases"
            extra={
              <Row justify="end" align="middle" gutter={[10, 0]}>
                <Col>
                  <Space>
                    <Search
                      style={{ width: 220 }}
                      placeholder="Search by party details"
                      allowClear
                      enterButton
                      size="middle"
                      onSearch={onSearch}
                    />
                    <Link to="/purchases/manage">
                      <Button
                        type="primary"
                        icon={<LuPlus style={{ paddingTop: "2px" }} />}
                      >
                        Create Purchase
                      </Button>
                    </Link>
                  </Space>
                </Col>
              </Row>
            }
          >
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={purchases}
              onChange={handleTableChange}
              size="small"
              pagination={tableParams.pagination}
              scroll={{ x: "1000px" }}
            />
          </Card>
        </Col>
      </Row>
      {/* View Modal */}
      <Modal
        title="View Purchase Details"
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Close
          </Button>,
        ]}
      >
        {selectedRecord && (
          <div>
            <p>Date: {moment(selectedRecord.date).format("DD-MM-YYYY")}</p>
            <p>Party Details: {selectedRecord.customerId}</p>
            <p>PO No: {selectedRecord.poNo}</p>
            <p>Basic Amount: {selectedRecord.basicAmount}</p>
            <p>CGST: {selectedRecord.cgst || "NA"}</p>
            <p>SGST: {selectedRecord.sgst || "NA"}</p>
            <p>IGST: {selectedRecord.igst || "NA"}</p>
            <p>Total GST: {selectedRecord.totalGst}</p>
            <p>Total Amount: {selectedRecord.totalAmount}</p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Purchases;
