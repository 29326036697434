import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  message,
  Table,
  Modal,
  Space,
  Upload,
  Breadcrumb,
} from "antd";
import APICall from "../../../services/apiservices";
import { LuPlus } from "react-icons/lu";
import moment from "moment";
import axios from "axios";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import Quotation from "../../../pdfs/quotation";
import { handleDownloadPDF } from "../../../pdfs/pdfService";

const { confirm } = Modal;

const MonthQuoatation = ({}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [fetchedData, setFetchedData] = useState(null);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const breadcrumbItems = (
    <>
      <Breadcrumb.Item key="quotations">
        <Link to="/quotations">Invoice</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item key="Form">
        <Link to="#">Month</Link>
      </Breadcrumb.Item>
    </>
  );

  const fetchInvoices = async (startDate, endDate) => {
    console.log(startDate);
    console.log(endDate);
    try {
      const response = await APICall(
        `/quotation/monthInvoice?startDate=${startDate}&endDate=${endDate}`,
        {},
        1
      );
      console.log(response.data, "res");
      return response.data;
    } catch (error) {
      message.error("Failed to fetch invoices");
      return [];
    }
  };

  const handleDownload = (data) => {
    if (!data || data.length === 0) {
      message.error("No data available for download");
      return;
    }

    // Loop through each invoice in the data array
    data.forEach((invoice) => {
      if (!invoice.user || !invoice.user.name) {
        message.error("User data is missing in one of the invoices.");
        return;
      }

      // Alert to check the user name

      const quotationData = {
        ...invoice,
        letterheader: "/quotation_header.png",
        allData: invoice,
        customer: {
          name: invoice.user.name,
          address: invoice.user.address,
        },
        totalAmountWords: invoice.totalAmount.toFixed(2),
        date: moment(invoice.date).format("DD/MM/YYYY"),
        quoatationNo: `QUOTE/${
          new Date().getFullYear().toString().slice(-2) +
          "-" +
          (new Date().getFullYear() + 1).toString().slice(-2)
        }/${(invoice?.quotationNo).toString().padStart(4, "0")}`,
      };

      const itemsData = invoice?.items?.map((item) => {
        const i = item.itemId;
        console.log(item, "555");
        return {
          image: item.seatData.photoURL
            ? item.seatData.photoURL
            : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png",
          name: item?.seatData?.name || "Item Name Not Available",
          hsnCode: item?.seatData?.hsn || "HSN Code Not Available",
          description:
            item?.seatData?.description || "Item Description Not Available",
          amount: item.price || 0,
          quantity: item.quantity || 0,
          totalAmount: (item.price || 0) * (item.quantity || 0),
        };
      });

      quotationData.data = itemsData;

      handleDownloadPDF({
        pages: [<Quotation {...quotationData} />],
        fileName: `${invoice?.user?.name}-Quotation-${invoice?.quotationNo}`,
      });
    });
  };

  const handleFetchAndDownload = async () => {
    const values = await form.validateFields();
    const startDate = values.date ? values.date.format("YYYY-MM-DD") : null;
    const endDate = values.dueDate ? values.dueDate.format("YYYY-MM-DD") : null;

    if (startDate && endDate) {
      const fetchedData = await fetchInvoices(startDate, endDate);
      if (fetchedData && fetchedData.length > 0) {
        handleDownload(fetchedData); // Pass fetched data directly to handleDownload
      } else {
        message.error("No invoices found for the selected date range.");
      }
    } else {
      message.error("Please select both Date and Due Date");
    }
  };

  return (
    <>
      <div
        type="inner"
        style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}
      >
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Row gutter={10}>
        <Col span={24}>
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col span={24}>
                <Card title={"Month Invoice"} bordered={false}>
                  <Row gutter={10}>
                    <Col span={8}>
                      <Form.Item
                        label="Date"
                        name="date"
                        rules={[
                          { required: true, message: "Please select date" },
                        ]}
                      >
                        <DatePicker
                          placeholder="16/07/2023"
                          format="DD-MM-YYYY"
                          style={{ width: "100%" }}
                          tabIndex={3}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Due Date"
                        name="dueDate"
                        rules={[
                          { required: true, message: "Please select Due date" },
                        ]}
                      >
                        <DatePicker
                          placeholder="16/07/2023"
                          format="DD-MM-YYYY"
                          style={{ width: "100%" }}
                          tabIndex={3}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      span={8}
                      style={{ display: "flex", marginTop: "29px" }}
                    >
                      <Button
                        type="primary"
                        onClick={handleFetchAndDownload}
                        style={{ width: "40%" }}
                      >
                        Generate Invoice
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default MonthQuoatation;
