import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  Dropdown,
  Button,
  Modal,
  Typography,
  Input,
  Form,
  Space,
  message,
} from "antd";
import APICall from "../../services/apiservices";
import { Link, useRevalidator } from "react-router-dom";
import moment from "moment";
import downloadXLSX from "./survey/export";
import downloadFireExtinguisherXLSX from "./survey/fireExtExport";

const { TextArea, Search } = Input;
const userData = JSON.parse(localStorage.getItem("userData"));

const Visits = ({ completed }) => {
  const [visiblec, setVisiblec] = useState(false);
  const [visitsForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [visits, setVisits] = useState([]);
  const [filteredVisits, setFilteredVisits] = useState([]);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getVisits = async (searchTerm, page, limit) => {
    try {
      if (searchTerm === undefined) searchTerm = "";
      const response = await APICall(
        `/visits?page=${page}&limit=${limit}&query=${searchTerm}&isConducted=${completed ? true : false
        }&assignedTo=${userData.employee._id}`,
        {},
        1
      );

      return response.data;
    } catch (error) {
      message.error("Failed to fetch visits");
      setIsLoading(false);
    }
  };

  const fetchList = async () => {
    setIsLoading(true);
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";
    const response = await getVisits(searchTerm, page, limit);
    //sort by date in descending order

    setVisits(
      response?.documents.sort((a, b) => {
        return (
          new Date(completed ? b.scheduledDate : a.scheduledDate) -
          new Date(completed ? a.scheduledDate : b.scheduledDate)
        );
      })
    );
    setFilteredVisits(
      response?.documents.sort((a, b) => {
        return (
          new Date(completed ? b.scheduledDate : a.scheduledDate) -
          new Date(completed ? a.scheduledDate : b.scheduledDate)
        );
      })
    );
    setIsLoading(false);

    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: response.total,
      },
    });
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams), completed]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setVisits([]);
    }
  };

  const onSearch = (value) => {
    //filter visits
    const filteredVisits = visits.filter((visit) =>
      visit.customer.user.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredVisits(filteredVisits);
  };

  const handleCancelc = () => {
    setVisiblec(false);
  };

  const handleConductVisit = (formData) => {
    console.log(formData);
    visitsForm.resetFields();
    setVisiblec(false);
  };

  const requirementsArray = {
    adaptor: {
      name: "Adaptor",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBox: {
      name: "Hose Box",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    branchPipe: {
      name: "Branch Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hosePipe: {
      name: "Hose Pipe",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValve: {
      name: "Hydrant Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReel: {
      name: "Hose Reel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    fireExtinguishers: {
      name: "Fire Extinguisher",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseBoxGlass: {
      name: "Hose Box Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    keyGlass: {
      name: "Key Glass",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hydrantValveWheel: {
      name: "Hydrant Valve Wheel",
      existing: 0,
      good: 0,
      requirement: 0,
    },
    hoseReelBallValve: {
      name: "Hose Reel Ball Valve",
      existing: 0,
      good: 0,
      requirement: 0,
    },
  };

  const exportVisit = async (visitId) => {
    try {
      const res = await APICall(`/visits/${visitId}/conducted`, {}, 1);

      if (!res?.data?.visit?.isFireExtinguisher) {
        const finalRequirements = JSON.parse(JSON.stringify(requirementsArray));
        const exportData = {
          company: {
            name: "Fire Fight Safety Solutions",
            address:
              "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
            mobile: "9970548822",
            email: "info@ffss.in",
          },
          customer: {
            name: res?.data?.visit?.customerId?.userId?.name,
          },
          employee: {
            name: res?.data?.visit?.assignedTo?.userId?.name,
          },
          description: res?.data?.visit?.description,
          date: moment(res?.data?.visit?.visitDate).format("DD/MM/YYYY"),
          buildings: res.data.visitBuildings.map((sb) => {
            const areas = sb?.newFloors.map((nf, index) => ({
              area: nf?.floor.name,
              srNo: index + 1,
              equipments: nf?.equipments ?? {
                adaptor: "NA",
                hoseBox: "NA",
                branchPipe: "NA",
                hosePipe: "NA",
                hydrantValve: "NA",
                hoseReel: "NA",
                fireExtinguishers: "NA",
                hoseBoxGlass: "NA",
                keyGlass: "NA",
                hydrantValveWheel: "NA",
                hoseReelBallValve: "NA",
              },
            }));
            const requirements = JSON.parse(JSON.stringify(requirementsArray));
            areas.map((area) => {
              Object.keys(area.equipments).map((k) => {
                if (area.equipments[k] == "OK") {
                  requirements[k].existing = requirements[k].existing + 1;
                  requirements[k].good = requirements[k].good + 1;
                  finalRequirements[k].existing =
                    finalRequirements[k].existing + 1;
                  finalRequirements[k].good = finalRequirements[k].good + 1;
                } else if (area.equipments[k] == "Not OK") {
                  requirements[k].existing = requirements[k].existing + 1;
                  requirements[k].requirement = requirements[k].requirement + 1;
                  finalRequirements[k].existing =
                    finalRequirements[k].existing + 1;
                  finalRequirements[k].requirement =
                    finalRequirements[k].requirement + 1;
                }
              });
            });
            return {
              building: sb?.building?.name,
              notes: sb?.information?.notes ?? "",
              inCharge: sb?.information?.inCharge ?? "",
              areas,
              requirements,
            };
          }),
        };
        exportData.requirements = finalRequirements;
        downloadXLSX(exportData, "Visit");
      } else {
        console.log(res);
        const exportData = {
          company: {
            name: "Fire Fight Safety Solutions",
            address:
              "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
            mobile: "9970548822",
            email: "info@ffss.in",
          },
          customer: {
            name: res?.data?.visit?.customer?.user?.name,
          },
          employee: {
            name: res?.data?.visit?.employee?.user?.name,
          },
          date: moment(res?.data?.visit?.visitDate).format("DD/MM/YYYY"),

          buildings: res.data.visitBuildings.map((sb) => {
            const areas = sb?.newFloors.map((floor, index) => ({
              srNo: index + 1,
              area: floor?.floor.name,
              fireExtinguisherInfo: {
                type: floor?.fireExtinguisherInfo?.type ?? "NA",
                capacity: floor?.fireExtinguisherInfo?.capacity ?? "NA",
                pressure: floor?.fireExtinguisherInfo?.pressure ?? "NA",
                weight: floor?.fireExtinguisherInfo?.weight ?? "NA",
                serialNo: floor?.fireExtinguisherInfo?.serialNo ?? "NA",
                installationRefillingDate: floor?.fireExtinguisherInfo
                  ?.installationRefillingDate
                  ? moment(
                    floor?.fireExtinguisherInfo?.installationRefillingDate
                  ).format("DD/MM/YYYY")
                  : "NA",
                refillingDueDate: floor?.fireExtinguisherInfo?.refillingDueDate
                  ? moment(
                    floor?.fireExtinguisherInfo?.refillingDueDate
                  ).format("DD/MM/YYYY")
                  : "NA",
                freeAccess: floor?.fireExtinguisherInfo?.freeAccess ?? "NA",
                identificationTag:
                  floor?.fireExtinguisherInfo?.identificationTag ?? "NA",
                forABCPressureGuage:
                  floor?.fireExtinguisherInfo?.forABCPressureGuage ?? "NA",
                hoseTightFitting:
                  floor?.fireExtinguisherInfo?.hoseTightFitting ?? "NA",
                isSafetyPinGood:
                  floor?.fireExtinguisherInfo?.isSafetyPinGood ?? "NA",
                forCO2HoseCondition:
                  floor?.fireExtinguisherInfo?.forCO2HoseCondition ?? "NA",
                forCO2HornHandle:
                  floor?.fireExtinguisherInfo?.forCO2HornHandle ?? "NA",
                isFEOK: floor?.fireExtinguisherInfo?.isFEOK ?? "NA",
                remark: floor?.fireExtinguisherInfo?.remark ?? "NA",
              },
            }));
            return {
              building: sb?.building?.name,
              notes: sb?.information?.notes ?? "",
              inCharge: sb?.information?.inCharge ?? "",
              areas,
            };
          }),
        };

        downloadFireExtinguisherXLSX(exportData, "Visit");
      }
    } catch (error) {
      message.error(error);
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "serialNumber",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
          (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },

    {
      title: "Customer",
      key: "customer",
      render: (record) => {
        return record?.customer?.user?.name;
      },
    },
    {
      title: "Scheduled Date",
      key: "scheduledDate",
      render: (record) => {
        return moment(record?.scheduledDate).format("DD/MM/YY");
      },
      // align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "visitDate",
    },
    {
      title: "Action",
      render: (_, record) =>
        completed ? (
          // <Dropdown
          //   align="center"
          //   menu={{
          //     items: [
          //       {
          //         key: 2,
          //         label: (
          //           <Link
          //             to={`/visit/${record._id}/buildings?customerId=${record.customerId}&customerName=${record.customer.user.name}&pagename=Visits`}
          //           >
          //             Edit
          //           </Link>
          //         ),
          //       },
          //       {
          //         key: 1,
          //         label: "Export Visit",
          //         onClick: () => {
          //           exportVisit(record._id);
          //         },
          //       },
          //     ],
          //   }}
          // >
          //   <Button type="link">Actions</Button>
          // </Dropdown>
          <Space>
            <Link
              to={`/visit/${record._id}/buildings?customerId=${record.customerId}&customerName=${record.customer.user.name}&pagename=Visits`}
            >
              <Button type="primary" ghost size="small">
                Edit
              </Button>
            </Link>
            <Button
              type="primary"
              ghost
              size="small"
              onClick={() => {
                exportVisit(record._id);
              }}
            >
              Export
            </Button>
          </Space>
        ) : (
          <Link
            style={{ textDecoration: "none" }}
            to={`/visit/${record._id}/buildings?customerId=${record.customerId}&customerName=${record.customer.user.name}&pagename=Visits`}
          >
            <Button type="link">Conduct</Button>
          </Link>
        ),
      align: "center",
      fixed: "right",
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Card
          title={completed ? "Completed Visits" : "Upcoming Visits"}
          extra={
            <Search
              style={{
                width: 220,
              }}
              placeholder="Search by Customer"
              allowClear
              enterButton
              // loading={loading}
              size="middle"
              onSearch={onSearch}
            />
          }
        >
          <Row>
            <Col
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Table
                columns={columns}
                dataSource={filteredVisits}
                size="small"
                onChange={handleTableChange}
                loading={isLoading}
                scroll={{ x: "500px" }}
              /></Col>
          </Row>
        </Card>

        <Modal
          title="Conduct Visit"
          open={visiblec}
          onOk={() => {
            visitsForm.submit();
          }}
          onCancel={handleCancelc}
          centered
        >
          <Form
            name="visitsForm"
            form={visitsForm}
            onFinish={handleConductVisit}
            layout="vertical"
          >
            <Typography.Text>
              This action will save your current location.
            </Typography.Text>
            <Form.Item label="Remark" name="remark">
              <TextArea placeholder="e.g. Two extinguisher required." />
            </Form.Item>
          </Form>
        </Modal>
      </Col>
    </Row>
  );
};

export default Visits;
