import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Space,
  message,
  Card,
  Row,
  Col,
  Breadcrumb,
} from "antd";
import APICall from "../../../services/apiservices";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const ManageEnquiry = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      fetchEnquiry(id);
    }
  }, [id]);

  const fetchEnquiry = async (enquiryId) => {
    try {
      setLoading(true);
      const response = await APICall(`/enquiry/${enquiryId}`, {}, 1);
      const enquiry = response.data;
      form.setFieldsValue({
        ...enquiry,
        fromDate: moment(enquiry.fromDate),
      });
    } catch (error) {
      message.error("Failed to fetch enquiry data. Please try again later.");
      console.error("Error fetching enquiry data:", error);
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      if (id) {
        await APICall(`/enquiry/${id}`, values, 3);
        message.success("Enquiry updated successfully!");
      } else {
        await APICall("/enquiry", values, 2);
        message.success("Enquiry created successfully!");
      }
      form.resetFields();
      navigate("/enquiry");
    } catch (error) {
      message.error("Failed to save enquiry. Please try again later.");
      console.error("Error saving enquiry:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const handleCancel = () => {
    form.resetFields();
    form.setFieldsValue({
      fromDate: null,
    });
  };

  const disabledDate = (current) => {
    // Disable past dates
    return current && current < moment().startOf("day");
  };

  const handleDateChange = (date, dateString) => {
    // Handle date change
    console.log("Selected date:", dateString);
  };
  const breadcrumbItems = [
    <Breadcrumb.Item key="enquiry">
      <Link to="/enquiry">Enquiry</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="form">
      <Link to="#">{id ? "Edit" : "Create"} Enquiry</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <>
      <div
        type="inner"
        style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}
      >
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Card title={id ? "Edit Enquiry" : "Create Enquiry"}>
        <Row gutter={10}>
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Row gutter={10}>
                <Col span={8}>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please enter your name" },
                      {
                        pattern: /^[A-Za-z\s]+$/,
                        message: "Name should only contain alphabetic letters",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email" },
                      {
                        type: "email",
                        message: "Please enter a valid email",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your email" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Mobile"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your mobile number",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Mobile number should only contain digits",
                      },
                      {
                        min: 10,
                        message: "Mobile number must be at least 10 digits",
                      },
                      {
                        max: 12,
                        message: "Mobile number cannot exceed 12 digits",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your mobile number" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Number of Seats"
                    name="numberOfSeats"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the number of seats",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Please enter numbers of digits",
                      },
                    ]}
                  >
                    <Input placeholder="Enter the number of seats" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="From Date"
                    name="fromDate"
                    rules={[
                      {
                        required: true,
                        message: "Please select the from date",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select date"
                      style={{ width: "100%" }}
                      tabIndex={4}
                      disabledDate={disabledDate}
                      onChange={handleDateChange}
                      picker="date"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item>
                    <Space>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ backgroundColor: "#26b689" }}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={handleCancel}
                        style={{ borderColor: "red", color: "red" }}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ManageEnquiry;
