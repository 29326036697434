import {
  Col,
  Row,
  Button,
  Form,
  Input,
  Card,
  Space,
  Typography,
  message,
  Modal,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import APICall from "../services/apiservices";
import { FaRegUserCircle } from "react-icons/fa";
import { EyeOutlined, UserOutlined, EyeInvisibleOutlined} from "@ant-design/icons"

const { confirm } = Modal;

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setLoading(false);
    if (localStorage.getItem("accessToken")) {
      navigate("/dashboard");
    }
  });

  const onFinish = async (formData) => {
    try {
      setLoading(true);
      const response = await APICall("/auth/login", formData, 2, false);
      if (response.data.accessToken) {
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("userData", JSON.stringify(response.data.user));
        window.location.reload();
        setInterval(() => {
          navigate("/dashboard");
          setLoading(false);
        }, 2000);
      }
    } catch (error) {
      message.error("Invalid email or password");
      setLoading(false);
    }
  };

  return (
    <div>
      <Row
        justify="center"
        align="middle"
        style={{ height: "100vh", backgroundColor: "#1b75bb", backgroundImage: "url('/CRM.jpeg')", backgroundSize: "cover" }}
      >
        {loading ? (
          <Spin spinning={loading} />
        ) : (
          <Col span={6}>
            <Card>
              <Row justify="center">
                <Col span={24} align="middle">
                  <FaRegUserCircle style={{ color: "#1b75bb" }} size={45} />
                </Col>
                <Col span={24} align="middle">
                  <Typography.Title
                    level={4}
                    style={{
                      color: "#1b75bb",
                      margin: 0,
                      lineHeight: "inherit",
                      fontWeight: 700,
                    }}
                  >
                    THE CULTIV8
                  </Typography.Title>
                </Col>
              </Row>
              <Form layout="vertical" onFinish={onFinish}>
                <Form.Item label="Username" name="email">
                  <Input suffix={(<UserOutlined/>)}/>
                </Form.Item>
                <Form.Item label="Password" name="password">
                <Input.Password
    iconRender={visible => (visible ? <EyeInvisibleOutlined /> : <EyeOutlined />)}
    placeholder="Password"
  />
  </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={loading}
                  >
                    Login
                  </Button>
                </Form.Item>
                {/* <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Sign Up
                </Button>
              </Form.Item> */}
              </Form>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Login;
