import React from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, PDFDownloadLink, Image, Font, pdf } from '@react-pdf/renderer';
import moment from 'moment/moment';

// Create styles
const styles = StyleSheet.create({
  page: {
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      paddingVertical: 16
  },
  letterheader: {
      height: '150px',
      paddingHorizontal: 16
  },
  title: {
      alignItems: 'center',
      fontFamily: 'Times-Bold', 
      justifyContent: 'center',
      paddingHorizontal: 32
  },
  body: {
    fontFamily: 'Times-Bold', 
    textAlign: 'left',
    fontSize: 14,
    paddingHorizontal: 32
  },
  header: {
    flexDirection: 'row',
    paddingBottom: 48,
    fontSize: 10,
    fontFamily: 'Times-Roman',
    paddingHorizontal: 32
  }, 
  headerSectionLeft: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  headerSectionRight: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center'
  },
  letterfooter: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 140
},
});

// Create Document Component
const OfferLetter = ({letterheader, letterfooter, employee, date}) => (
    <Page size="A4" style={styles.page}>
        <View fixed>
            {letterheader && <View style={styles.letterheader}>
                {
                    <Image src={letterheader}/>
                }
                </View>
            }
        </View>
        {/* <View fixed>
            {letterfooter && <View style={styles.letterfooter}>
                {
                    <Image src={letterfooter}/>
                }
                </View>
            }
        </View> */}
        <View style={{...styles.title, paddingTop: letterheader ? 0 : 48}}>
            <Text style={{...styles.title, fontSize: 22, paddingBottom: 8, textDecoration: "underline"}}>Offer Cum Appointment Letter</Text>
        </View>
        <View style={{...styles.header, paddingBottom: 8}}>
            <View style={styles.headerSectionRight}>
                <Text style={{fontFamily: 'Times-Bold', paddingBottom: 5}}>Date: <Text style={{fontFamily: 'Times-Roman'}}>{date}</Text></Text>
            </View>
        </View>
        <View style={{...styles.body, textAlign: 'left', fontSize: 12}}>
            <Text>To</Text>
            <Text>{employee.name}</Text>
            <Text>{employee.address}</Text>
        </View>
        <View style={styles.title}>
            <Text style={{...styles.title, fontSize: 18, paddingVertical: 24, textAlign: 'left', textDecoration: "underline"}}>Sub: Offer Cum Appointment Letter</Text>
        </View>
        <View style={styles.body}>
            <Text style={{paddingBottom: 12, textAlign: 'left', fontSize: 12}}>{`Dear ${employee.name},`}</Text>
            <Text style={{textAlign: 'justify'}}>With Reference to your application/Resume and subsequent interview and discussions you had with us, we are pleased to appoint you in our firm on following terms & conditions.</Text>
            <Text style={{fontFamily: 'Times-Roman', textAlign: 'justify'}}>{` 
                • You will be joining your duty on or before ${employee.joiningDate}.
                \n• You will be designated as ${employee.designation}.
                \n• Your Salary will be Rs. ${employee.salary}.
                \n• Your notice period will be 30 days.
                \n• Office timing will be from 9:45 AM to 6:00.
                \n• This increment will take effect from 1 July ${((new Date()).getFullYear()) + 1}. So, you will receive revised salary from July month and afterwards.
                \n• If any employee found guilty or manipulating office work or sharing information to any other source strict action will be taken against him/her. 
                \n• Paid leave will be 10 days per year. You can encase paid leave if not used in the month of June every year. (Leaves from 1st July ${((new Date()).getFullYear())} to 1st July ${((new Date()).getFullYear()) + 1})
                \n• Company will issue medical insurance of 2 Lac per year. If you want more you can buy at your own.
                \n• Reward of Rs.3000 to 5000 will be given in every quarter at the start of the next month. It will be selected on the basis of best performance in that quarter.
                \n• FFSS will be terminate employee if found guilty and violation all company policies and Strict action will be taken.
                \n• Three late marks will lead to cut one day salary.
                \n• No over time will be paid on week day/ Overtime will be given on Sunday only.
                \n• Outstation lunch/ dinner charges paid Rs. 150/- per employee.
            `}</Text>
            <Text style={{paddingVertical: 12, fontFamily: 'Times-Bold', textAlign: 'justify'}}>Please, confirm your acceptance of the above by signing and returning a copy of this letter.</Text>
            <Text style={{paddingBottom: 32, fontFamily: 'Times-Bold', textAlign: 'justify'}}>Wishing you all the best.</Text>
            <Text style={{textAlign: 'left', fontFamily: 'Times-Bold', fontSize: 14, paddingBottom: 80}}>For Fire Fight Safety Solutions</Text>
            <Text style={{textAlign: 'left', fontFamily: 'Times-Bold', fontSize: 14}}>Mr. Gaurav Vaidya</Text>
            <Text style={{textAlign: 'left', fontFamily: 'Times-Bold', fontSize: 14}}>Director</Text>
            <Text style={{textAlign: 'right', fontFamily: 'Times-Bold', fontSize: 14, paddingTop: 80, paddingBottom: 16}}>Candidate Signature</Text>
            <Text style={{textAlign: 'right', fontFamily: 'Times-Bold', fontSize: 14}}>Date: {date}</Text>
        </View>
    </Page>
);

export default OfferLetter;