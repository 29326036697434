import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Descriptions,
  Spin,
  Row,
  Col,
  Input,
  Form,
  DatePicker,
  Checkbox,
  Space,
} from "antd";
import { Link, useParams } from "react-router-dom";
import APICall from "../../../services/apiservices";
import { PDFViewer, Document } from "@react-pdf/renderer";
import moment from "moment";
import { PDFDocument, handleDownloadPDF } from "../../../pdfs/pdfService";
import Quotation from "../../../pdfs/quotation";
import AMC from "../../../pdfs/amc";
import dayjs from "dayjs";
const { useForm } = Form;

const AMCExport = () => {
  const { amcId } = useParams();
  const [amcDetails, setAmcDetails] = useState();
  const [form] = useForm();

  useEffect(() => {
    getAMCDetails();
  }, []);

  const getAMCDetails = async () => {
    const response = await APICall(`/amc/${amcId}`, {}, 1);
    const amc = response.data;

    const amcData = {
      date: moment().format("DD/MM/YYYY"),
      amcNo: `FFSS/${
        new Date().getFullYear().toString().slice(-2) +
        "-" +
        (new Date().getFullYear() + 1).toString().slice(-2)
      }/AMC${amc.amcNo.toString().padStart(4, "0")}`,
      customer: {
        name: amc.customer.user.name,
        address: amc.customer.user.address,
      },
      contactPerson: {
        name: amc.contactPerson.name,
        mobile: amc.contactPerson.mobile,
      },
      contractDate: moment(amc.contractDate).format("DD/MM/YYYY"),
      expiryDate: moment(amc.expiryDate).format("DD/MM/YYYY"),
      type: amc.type,
      systems: amc.systemTypes.map((st) => st.systemTypeId.name),
      notes: amc.notes ? amc.notes : "",
      workOrderNo: amc.workOrderNo,
      contractAmount: `${amc.contractAmount.toString()}  ${
        amc.includeGST === true ? "with GST" : "without GST"
      }`,
      visitsCount: amc.visitsCount,
      visits: amc.visits.map((v, i) => ({
        number: i + 1,
        date: moment(v.scheduledDate).format("DD/MM/YYYY"),
      })),
      allData: amc,
    };

    setAmcDetails(amcData);
  };

  return (
    <Card
      title="AMC"
      // extra={
      //     amcDetails && <Button type="primary" onClick={() => handleDownloadPDF({
      //         pages: [
      //             <AMC {...amcDetails}/>
      //         ],
      //         fileName: 'AMC'
      //     })}>Download</Button>
      // }
    >
      <Form
        form={form}
        // layout="vertical"
      >
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item name="date" label="Date">
              <DatePicker
                placeholder={moment().format("YYYY-MM-DD")}
                defaultValue={dayjs()}
                style={{ width: "100%" }}
                tabIndex={1}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name="place" label="Place">
              <Input placeholder="e.g. Pune" tabIndex={2} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name="includeLetterheadHeader" valuePropName="checked">
              <Checkbox>Print With Letterhead header</Checkbox>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    const formData = form.getFieldsValue();
                    const tempAMCDetails = { ...amcDetails };
                    formData.date &&
                      (tempAMCDetails.date = moment(
                        new Date(formData.date)
                      ).format("DD/MM/YYYY"));
                    tempAMCDetails.place = formData.place || "";
                    formData.includeLetterheadHeader
                      ? (tempAMCDetails.letterheader =
                          tempAMCDetails.allData.letterhead.header)
                      : (tempAMCDetails.letterheader = undefined);
                    formData.includeLetterheadHeader
                      ? (tempAMCDetails.letterfooter =
                          tempAMCDetails.allData.letterhead.footer)
                      : (tempAMCDetails.letterfooter = undefined);
                    setAmcDetails({ ...tempAMCDetails });
                  }}
                  tabIndex={4}
                >
                  Create
                </Button>
                {amcDetails && (
                  <Button
                    type="primary"
                    onClick={() =>
                      handleDownloadPDF({
                        pages: [<AMC {...amcDetails} />],
                        fileName: "AMC",
                      })
                    }
                  >
                    Download
                  </Button>
                )}
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {amcDetails ? (
        <PDFViewer width={"100%"} height={1200}>
          <PDFDocument pages={[<AMC {...amcDetails} />]} />
        </PDFViewer>
      ) : (
        <Spin />
      )}
    </Card>
  );
};

export default AMCExport;
