import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Space,
  message,
  Card,
  Row,
  Col,
  Breadcrumb,
} from "antd";
import APICall from "../../../services/apiservices";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";

const { Option } = Select;

const ManageEntries = () => {
  const [form] = Form.useForm();
  const [clients, setClients] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchClients();
    if (id) {
      fetchGuestEntry(id);
    }
  }, [id]);

  const fetchClients = async () => {
    try {
      const response = await APICall("/user", {}, 1);
      const clientData = response.data.documents.map((doc) => doc.user);
      console.log("clientData", clientData);
      setClients(clientData);
    } catch (error) {
      console.error("Error fetching clients:", error);
      message.error("Failed to fetch clients. Please try again later.");
    }
  };

  const fetchGuestEntry = async (entryId) => {
    try {
      const response = await APICall(`/guest/${entryId}`, {}, 1);
      form.setFieldsValue(response.data); // Populate form with fetched data
      form.setFieldsValue({ clientId: response.data.clientId._id });
    } catch (error) {
      console.error("Error fetching guest entry:", error);
      message.error("Failed to fetch guest entry. Please try again later.");
    }
  };

  const onFinish = async (values) => {
    try {
      if (id) {
        await APICall(`/guest/${id}`, values, 3);
        message.success("Entry updated successfully!");
      } else {
        await APICall("/guest", values, 2);
        message.success("Entry saved successfully!");
      }
      form.resetFields();
      navigate("/guestEntry");
    } catch (error) {
      message.error("Failed to save entry. Please try again later.");
      console.error("Error saving entry:", error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  const breadcrumbItems = id ? (
    <>
      <Breadcrumb.Item key="entries">
        <Link to="/GuestEntry">Guest Entry</Link>
      </Breadcrumb.Item>

      <Breadcrumb.Item key="Form">
        <Link to="#">Edit</Link>
      </Breadcrumb.Item>
    </>
  ) : (
    <>
      <Breadcrumb.Item key="entries">
        <Link to="/GuestEntry">Guest Entry</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item key="Form">
        <Link to="#">Create</Link>
      </Breadcrumb.Item>
    </>
  );

  return (
    <>
      <div
        type="inner"
        style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}
      >
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Card title={id ? "Edit Entry" : "Create Entry"}>
        <Row gutter={10}>
          <Col span={24}>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Row gutter={10}>
                <Col span={8}>
                  <Form.Item
                    label="Select Client"
                    name="clientId"
                    rules={[
                      { required: true, message: "Please select a client" },
                    ]}
                  >
                    <Select
                      placeholder="Select client"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {clients.map((client) => (
                        <Option key={client._id} value={client._id}>
                          {client.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[
                      { required: true, message: "Please enter your name" },
                      {
                        pattern: /^[A-Za-z\s]+$/,
                        message:
                          "Name should only contain alphabetic characters",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your name" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      { required: true, message: "Please enter your email" },
                      {
                        type: "email",
                        message: "Please enter a valid email",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your email" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Mobile"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your mobile number",
                      },
                      {
                        pattern: /^[0-9]+$/,
                        message: "Mobile number should only contain digits",
                      },
                      {
                        max: 12,
                        message: "Mobile number cannot exceed 12 digits",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your mobile number" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Purpose Of Visit"
                    name="purposeOfVisit"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the purpose of visit",
                      },
                    ]}
                  >
                    <Input placeholder="Enter the purpose of visit" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item>
                    <Space>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ backgroundColor: "#26b689" }}
                      >
                        {id ? "Update" : "Save"}
                      </Button>
                      <Button
                        onClick={() => form.resetFields()}
                        style={{ borderColor: "red", color: "red" }}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ManageEntries;
