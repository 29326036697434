import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";
import CustomTablePDF from "./table";

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    paddingBottom: 150,
    paddingTop: 180
  },
  letterheader: {
    // position: "absolute",
    // height: "150px",
    // paddingHorizontal: 16,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
  },
  title: {
    alignItems: "center",
    fontFamily: "Times-Bold",
    justifyContent: "center",
    paddingHorizontal: 32,
  },
  body: {
    fontFamily: "Times-Bold",
    textAlign: "left",
    fontSize: 14,
    paddingHorizontal: 32,
  },
  header: {
    flexDirection: "row",
    paddingBottom: 16,
    fontSize: 14,
    fontFamily: "Times-Roman",
    paddingHorizontal: 32,
  },
  headerSectionLeft: {
    width: "15%",
    alignItems: "flex-start",
    justifyContent: "start",
  },
  headerSectionRight: {
    width: "85%",
    alignItems: "flex-start",
    justifyContent: "start",
  },
  letterfooter: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
});

const fields = [
  {
    title: "Item Name",
    custom: true,
    component: (item) =>
      `${item.name}`,
    width: "55%",
  },

  {
    title: "Description",
    custom: true,
    component: (item) =>
      `${item.description ?? '-'}`,
    width: "25%",
  },

  {
    title: "Quantity",
    custom: true,
    component: (item) => `${item.quantity}`,
    width: "20%",
    style: {
      textAlign: "center",
    },
  },
];

// Create Document Component
const InstallationCertificate = ({
  letterheader,
  letterfooter,
  headingDate,
  customer,
  data,
}) => (
  <Page size="A4" style={styles.page}>
    {letterheader && (
      <View fixed style={styles.letterheader}>{<Image src={letterheader} />}</View>
    )}
    <View style={{ ...styles.title, paddingTop: letterheader ? 16 : 64 }}>
      <Text
        style={{
          ...styles.title,
          fontSize: 22,
          paddingBottom: 32,
          textDecoration: "underline",
        }}
      >
        Installation Report
      </Text>
    </View>
    <View style={styles.header}>
      <View style={styles.headerSectionLeft}>
        <Text style={{ fontFamily: "Times-Bold" }}>Site Name: </Text>
      </View>
      <View style={styles.headerSectionRight}>
        <Text>{customer.name}</Text>
      </View>
    </View>
    <View style={styles.header}>
      <View style={styles.headerSectionLeft}>
        <Text style={{ fontFamily: "Times-Bold" }}>Address: </Text>
      </View>
      <View style={styles.headerSectionRight}>
        <Text>{customer.address}</Text>
      </View>
    </View>
    <View style={{ ...styles.title, paddingBottom: 16 }}>
      <Text style={{ ...styles.title, fontSize: 18 }}>Details Of Work</Text>
    </View>
    <View
      style={{
        marginHorizontal: 32,
      }}
    >
      <CustomTablePDF fields={fields} data={data} />
    </View>
    <View style={{ ...styles.body, paddingTop: 16, paddingBottom: 200 }} wrap={false}>
      <Text
        style={{ fontFamily: "Times-Bold", textAlign: "justify", fontSize: 12 }}
      >
        Note: We have Checked and Tested the system but it is the Duty of owner
        of the building to kept the entire systems operatonal every tme by
        keeping water in free water tank and all pumps in pressurized mode.
      </Text>
    </View>
    {letterfooter && (
      <View fixed style={styles.letterfooter}>{<Image src={letterfooter} />}</View>
    )}
  </Page>
);

export default InstallationCertificate;
