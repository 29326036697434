// auth.js
import { useState, useEffect, createContext, useContext } from "react";

const AuthContext = createContext();

// Create an authentication provider component
function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("accessToken")
  );
  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("userData"))
  );

  useEffect(() => {
    // Check if the user is authenticated
    const getUserData = () => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      return userData;
    };

    const isAuthenticated = () => {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        setUserData(getUserData());
        return true;
      }
      return false;
    };

    const isUserAuthenticated = isAuthenticated();

    // Set the authentication status
    setAuthenticated(isUserAuthenticated);
  }, []); // Run only once when the component is mounted

  return (
    <AuthContext.Provider value={{ authenticated, userData, setUserData }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthProvider;
