import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";
import FormAPDF from "./formA";
import FormBPDF from "./formB";
import InstallationCertificate from "./installationCertificate";
import TestingCertificate from "./testingCertificate";
import TrainingCertificate from "./trainingCertificate";

export const downloadPDF = async (documentJSX, fileName) => {
  const blob = await pdf(documentJSX).toBlob();
  //create blob
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.pdf`);
  // Append to html link element page
  document.body.appendChild(link);
  // Start download
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const PDFDocument = ({ pages }) => (
  <Document>{pages.map((page) => page)}</Document>
);

export const handleDownloadPDF = async ({ pages, fileName }) => {
  const PdfDocumen = <PDFDocument pages={pages} />;

  await downloadPDF(PdfDocumen, fileName);
};

const formAData = {
  letterheader: "",
  referenceNumber: "12/HSGY/2023/2024",
  headingDate: "14/07/2023",
  customer: {
    name: "Dr. Mendadkar's Children's Hospital",
    address:
      "C-101, Angolimala C.H.S., Next to Hotel Sawali, SG Barve Marg, Nehru Nagar, Kurla (East), Mumbai, 400024",
  },
  company: {
    name: "FIRE FIGHT SAFETY SOLUTIONS",
    address:
      "Sr. No 85 Off no 204/7A,'Kalpataru Estate Phase-III' Jawalkar Nagar, Pimple Gurav, Pune - 411027",
    licenseNumbers: ["MFS - LA / RF-38", "MFS - LA / RF-39"],
  },
  place: "Pune",
  footerDate: "14/07/2023",
};

const formBData = {
  letterheader: "",
  referenceNumber: "12/HSGY/2023/2024",
  headingDate: "14/07/2023",
  customer: {
    name: "Dr. Mendadkar's Children's Hospital",
    address:
      "C-101, Angolimala C.H.S., Next to Hotel Sawali, SG Barve Marg, Nehru Nagar, Kurla (East), Mumbai, 400024",
  },
  startDate: "01/07/2023",
  endDate: "30/06/2024",
  company: {
    name: "FIRE FIGHT SAFETY SOLUTIONS",
    address:
      "Sr. No 85 Off no 204/7A,'Kalpataru Estate Phase-III' Jawalkar Nagar, Pimple Gurav, Pune - 411027",
    licenseNumbers: ["MFS - LA / RF-38", "MFS - LA / RF-39"],
  },
  place: "Pune",
  footerDate: "14/07/2023",
};

const trainingCertificateData = {
  letterheader: "https://sb.ecobnb.net/app/uploads/sites/3/2020/01/nature.jpg",
  headingDate: "14/07/2023",
  customer: {
    name: "Dr. Mendadkar's Children's Hospital",
    address:
      "C-101, Angolimala C.H.S., Next to Hotel Sawali, SG Barve Marg, Nehru Nagar, Kurla (East), Mumbai, 400024",
  },
};

const installationCertificateData = {
  letterheader: "",
  customer: {
    name: "Dr. Mendadkar's Children's Hospital",
    address:
      "C-101, Angolimala C.H.S., Next to Hotel Sawali, SG Barve Marg, Nehru Nagar, Kurla (East), Mumbai, 400024",
  },
  data: [
    { name: "CO2 Type 4.5 Kg Fire Extinguisher ISI 15683", quantity: 1 },
    {
      name: "ABC 4 Kg Stored Pressure Type Fire Extinguisher ISI 15683",
      quantity: 100,
    },
  ],
};

const testingCertificateData = {
  letterheader: "",
  customer: {
    name: "Dr. Mendadkar's Children's Hospital",
    address:
      "C-101, Angolimala C.H.S., Next to Hotel Sawali, SG Barve Marg, Nehru Nagar, Kurla (East), Mumbai, 400024",
  },
  data: [
    {
      name: "CO2 Type 4.5 Kg Fire Extinguisher ISI 15683",
      quantity: 1,
      srNo: 1,
      testPressure: "35 KG/CM2",
      result: "OK",
    },
    {
      name: "ABC 4 Kg Stored Pressure Type Fire Extinguisher ISI 15683",
      quantity: 2,
      srNo: 2,
      testPressure: "35 KG/CM2",
      result: "OK",
    },
  ],
  certificateNumber: "FFSS/19-20/0433",
  date: "14/04/2017",
  extendedPeriod: "5 years",
};
{
  /* <PDFViewer>
<PDFDocument pages={[<FormA letterheader={'https://sb.ecobnb.net/app/uploads/sites/3/2020/01/nature.jpg'}/>]}/>
</PDFViewer> 
<PDFViewer>
<PDFDocument pages={[<FormA {...formAData}/>]}/>
</PDFViewer>
<PDFViewer>
<PDFDocument pages={[<FormB {...formBData}/>]}/>
</PDFViewer>
<PDFViewer>
<PDFDocument pages={[<TrainingCertificate {...trainingCertificateData}/>]}/>
</PDFViewer>
<PDFViewer>
<PDFDocument pages={[<InstallationCertificate {...installationCertificateData}/>]}/>
</PDFViewer>
<PDFViewer>
<PDFDocument pages={[<TestingCertificate {...testingCertificateData}/>]}/>
</PDFViewer>
                            */
}
