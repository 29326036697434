import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Col,
  Card,
  message,
  Breadcrumb,
  Radio,
  Select,
  Table,
  Space,
} from "antd";
import moment from "moment";
import {
  ShoppingOutlined,
  ExclamationCircleOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import APICall from "../../../services/apiservices";
import * as XLSX from "xlsx";

const { Search } = Input;
const { Item } = Form;
const { Option } = Select;

const GSTReport = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [purchasesData, setPurchasesData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("sameState");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const navigate = useNavigate();
  const [salesData, setSalesData] = useState([]);
  const params = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {},
    sorter: {},
  });

  const onSearch = async (value) => {
    setSearchTerm(value);
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  const filteredSalesData = salesData.filter((sales) =>
    sales?.user?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredPurchaseData = purchasesData.filter((purchases) =>
    purchases?.customerId?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (selectedMonth) {
      fetchSalesData(selectedMonth);
      fetchPurchasesData(selectedMonth);
    }
  }, [selectedMonth]);

  const handleFromDateChange = (date) => {
    setFromDate(date);
    if (date && toDate) {
      fetchSalesData(date, toDate);
      fetchPurchasesData(date, toDate);
    }
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    if (fromDate && date) {
      fetchSalesData(fromDate, date);
      fetchPurchasesData(fromDate, date);
    }
  };

  const fetchSalesData = async (fromDate, toDate) => {
    setIsLoading(true);
    try {
      const formattedFromDate = fromDate.format("YYYY-MM-DD");
      const formattedToDate = toDate.format("YYYY-MM-DD");
      const response = await APICall(
        `/quotation/sales?fromDate=${formattedFromDate}&toDate=${formattedToDate}`,
        {},
        1
      );
      console.log(response, "555");
      // Update the table data
      setSalesData(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error("Failed to fetch sales data. Please try again later.");
      console.error(error);
    }
  };

  const fetchPurchasesData = async (fromDate, toDate) => {
    setIsLoading(true);
    try {
      if (!fromDate || !toDate) {
        throw new Error("Both fromDate and toDate must be provided");
      }

      const formattedFromDate = fromDate.format("YYYY-MM-DD");
      const formattedToDate = toDate.format("YYYY-MM-DD");

      const response = await APICall(
        `/purchase/purchaseMonth?fromDate=${formattedFromDate}&toDate=${formattedToDate}`,
        {},
        1
      );
      setPurchasesData(response.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error("Failed to fetch purchases data. Please try again later.");
      console.error(error);
    }
  };

  const handleExport = () => {
    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Format sales data
    const formattedSalesData = salesData.map((sale, index) => ({
      "Sr. No": index + 1,
      Date: moment(sale.date).format("YYYY-MM-DD"),
      "Customer Name": sale.user.name,
      "Amount (₹)": sale.amount,
      "Discount (₹)": `${sale.discountAmount} (${sale.discount}%)`,
      "GST (₹)": sale.sgstAmount + sale.cgstAmount,
      "TDS (₹)": sale.tdsAmount || "NA",
      "Total Amount (₹)": sale.totalAmount,
    }));

    // Format purchase data
    const formattedPurchasesData = purchasesData.map((purchase, index) => ({
      "Sr. No": index + 1,
      Date: moment(purchase.date).format("YYYY-MM-DD"),
      "Party Detail": purchase.customerId,
      "PO No": purchase.poNo,
      "Basic Amount (₹)": purchase.basicAmount,
      "CGST (9%)": purchase.cgst,
      "SGST (9%)": purchase.sgst,
      IGST: purchase.igst,
      "Total GST (18%)": purchase.totalGst,
      "Total Amount (₹)": purchase.totalAmount,
    }));

    const salesSheet = XLSX.utils.json_to_sheet(formattedSalesData);
    XLSX.utils.book_append_sheet(wb, salesSheet, "Sales Data");

    // Add purchases data sheet
    const purchasesSheet = XLSX.utils.json_to_sheet(formattedPurchasesData);
    XLSX.utils.book_append_sheet(wb, purchasesSheet, "Purchases Data");

    // Write the workbook to an Excel file
    XLSX.writeFile(wb, "GST_Report.xlsx");
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Party Detail",
      dataIndex: "customerId",
      key: "customerId",
    },
    {
      title: "PO No",
      dataIndex: "poNo",
      key: "poNo",
    },
    {
      title: "Basic Amount (₹)",
      dataIndex: "basicAmount",
      key: "basicAmount",
    },
    {
      title: "CGST (9%)",
      dataIndex: "cgst",
      key: "cgst",
      render: (text) => (text ? text : "NA"),
    },
    {
      title: "SGST (9%)",
      dataIndex: "sgst",
      key: "sgst",
      render: (text) => (text ? text : "NA"),
    },
    {
      title: "IGST ",
      dataIndex: "igst",
      key: "igst",
      render: (text) => (text ? text : "NA"),
    },
    {
      title: "Total GST (18%)",
      dataIndex: "totalGst",
      key: "totalGst",
    },
    {
      title: "Total Amount (₹)",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
  ];

  const salesColumns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Date",
      key: "date",
      render: (_, data) => {
        return moment(data.date).format("YYYY-MM-DD");
      },
    },
    {
      title: "Customer Name",
      key: "name",
      render: (_, data) => {
        return data.user.name;
      },
    },
    {
      title: "Amount (₹)",
      key: "amount",
      render: (_, data) => {
        return data.amount;
      },
    },
    {
      title: "Discount",
      key: "discount",
      render: (_, data) => {
        const discount = data.client.discount || 0;
        const discountType = data.client.discountType || "%";
        return `${discount} (${discount} ${discountType})`;
      },
    },
    {
      title: "GST (₹)",
      key: "gst",
      render: (_, data) => {
        const totalGST = data.sgstAmount + data.cgstAmount;
        return `${totalGST}`;
      },
    },
    {
      title: "TDS (₹)",
      key: "tds",
      render: (_, data) => {
        return data.tdsAmount ? `${data.tdsAmount} ₹` : "NA";
      },
    },
    {
      title: "Total Amount (₹)",
      key: "totalAmount",
      render: (_, data) => {
        return data.totalAmount;
      },
    },
  ];

  const breadcrumbItems = [
    <Breadcrumb.Item key="report">
      <Link to="#">Report</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="table">
      <Link to="#">GST Report</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <>
      <div
        type="inner"
        style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}
      >
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Card
        title="GST Report"
        extra={[
          <Space>
            {/* <Select
              key="selectMonth"
              placeholder="Select Month"
              style={{ width: 120, marginRight: 10 }}
              onChange={handleMonthChange}
            >
              <Option value="January">January</Option>
              <Option value="February">February</Option>
              <Option value="March">March</Option>
              <Option value="April">April</Option>
              <Option value="May">May</Option>
              <Option value="June">June</Option>
              <Option value="July">July</Option>
              <Option value="August">August</Option>
              <Option value="September">September</Option>
              <Option value="October">October</Option>
              <Option value="November">November</Option>
              <Option value="December">December</Option>
            </Select> */}
            <DatePicker
              key="fromDate"
              placeholder="From Date"
              onChange={handleFromDateChange}
              style={{ width: 150 }}
            />
            <DatePicker
              key="toDate"
              placeholder="To Date"
              onChange={handleToDateChange}
              style={{ width: 150 }}
            />
            <Search
              style={{
                width: 220,
              }}
              placeholder="Search an Customer"
              allowClear
              enterButton
              // loading={isLoading}
              size="middle"
              onSearch={onSearch}
            />
            <Button key="export" type="primary" onClick={handleExport}>
              Export
            </Button>
          </Space>,
        ]}
      >
        <Row gutter={20}>
          {/* <Row gutter={10}> */}
          <Col span={8}>
            <Card
              title={
                <>
                  <span
                    style={{
                      fontSize: "24px",
                      marginRight: "8px",
                      color: "white",
                    }}
                  >
                    ₹
                  </span>
                  <span style={{ color: "white" }}>10000 Total Sale GST</span>
                </>
              }
              style={{
                backgroundColor: "#3e67fa",
                height: "57px",
                textAlign: "center",
                cursor: "pointer",
              }}
            ></Card>
          </Col>
          <Col span={8}>
            <Card
              title={
                <span style={{ color: "white" }}>
                  {" "}
                  <ShoppingOutlined
                    style={{ fontSize: "24px", marginRight: "8px" }}
                  />
                  ₹ 15000 Total Purchase GST
                </span>
              }
              style={{
                backgroundColor: "#32a834",
                height: "57px",
                textAlign: "center",
                cursor: "pointer",
              }}
            ></Card>
          </Col>
          <Col span={8}>
            <Card
              title={
                <span style={{ color: "white" }}>
                  <ExclamationCircleOutlined
                    style={{ fontSize: "24px", marginRight: "8px" }}
                  />
                  ₹ 5000 Outstanding
                </span>
              }
              style={{
                backgroundColor: "#f54747",
                height: "57px",
                textAlign: "center",
                cursor: "pointer",
              }}
            ></Card>
          </Col>
          {/* </Row> */}
          <Col span={24}>
            <Card title="Sales" bordered={true} style={{ marginTop: "20px" }}>
              <Table
                columns={salesColumns}
                dataSource={filteredSalesData}
                loading={isLoading}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Card
              title="Purchases"
              bordered={true}
              style={{ marginTop: "20px" }}
            >
              <Table
                columns={columns}
                dataSource={filteredPurchaseData}
                loading={isLoading}
              />
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default GSTReport;
