import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import { Link, useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Tag,
  message,
  Modal,
  Dropdown,
  Input,
  Space,
  Menu,
  Breadcrumb,
  Switch,
} from "antd";
import {
  DownOutlined,
  DownloadOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import APICall from "../../services/apiservices";
import jsPDF from "jspdf";
const { Search } = Input;

const { confirm } = Modal;

const Customers = ({}) => {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isActive, setIsActive] = useState(null);
  const [totalClientCount, setTotalClientCount] = useState(0);
  const [viewModalData, setViewModalData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const changeStatus = async (record) => {
    confirm({
      title: `${
        record?.user?.isActive ? "Inactive" : "Active"
      } employee account`,
      content: `Are you sure you want to ${
        record?.user?.isActive ? "disable" : "enable"
      } employee ${record?.user?.name}?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          let res;
          if (record?.user?.isActive) {
            res = await APICall(`/user/${record?.user?._id}/disable`, {}, 3);
          } else {
            res = await APICall(`/user/${record?.user?._id}/enable`, {}, 3);
          }
          // setReload(true);
          if (res.data) {
            message.success(
              `Employee  ${
                record?.user?.isActive ? "disabled" : "enabled"
              } successfully!`
            );
            fetchList();
          }
        } catch (error) {
          // console.log(error);
          message.error(error.response.data.message);
        }
      },
    });
  };

  const getCustomers = async (searchTerm, page, limit, isActive) => {
    try {
      let url = `/client?page=${page}&limit=${limit}&query=${searchTerm}`;

      if (isActive === true) {
        url = `/client?page=${page}&limit=${limit}&isActive=true&query=${searchTerm}`;
      } else if (isActive === false) {
        url = `/client?page=${page}&limit=${limit}&isInactive=true&query=${searchTerm}`;
      }

      const response = await APICall(url, {}, 1);
      console.log("API Response:", response);
      return response.data;
    } catch (error) {
      message.error("Failed to fetch customer details");
      setIsLoading(false);
      throw error;
    }
  };

  const fetchList = async () => {
    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";

    setIsLoading(true);

    try {
      const response = await getCustomers(searchTerm, page, limit, isActive);
      console.log("Customer list", response);
      setCustomers(response?.documents);
      setTotalClientCount(response?.total || 0);
      setIsLoading(false);

      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response?.total,
        },
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchList();
  }, [JSON.stringify(tableParams), isActive]);

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filterParam = queryParams.get("filter");
    setIsActive(
      filterParam === "active"
        ? true
        : filterParam === "inactive"
        ? false
        : null
    );
  }, [location]);

  const handleTableChange = (pagination) => {
    setTableParams((prev) => ({
      ...prev,
      pagination,
    }));

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setCustomers([]);
    }
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
      pagination: {
        ...tableParams.pagination,
        current: 1, // Reset to first page on new search
      },
    });
  };
  const openModal = (record) => {
    console.log("View Modal Data:", record);
    setViewModalData(record);
  };

  const closeModal = () => {
    setViewModalData(null);
  };
  const handleDeactivate = async (record) => {
    console.log("Deactivate", record.userId);
    try {
      const response = await APICall(`/user/${record.userId}/disable`, {}, 3);
      message.success("Client is blocked!");
      // message.success(response.message);
      fetchList();
    } catch (error) {
      message.error("Failed to deactivate customer");
    }
  };

  const handleActivate = async (record) => {
    try {
      const response = await APICall(`/client/activate/${record._id}`, {}, 2);
      message.success(response.message);
      fetchList();
    } catch (error) {
      message.error("Failed to activate customer");
    }
  };

  const handleDelete = async (record) => {
    try {
      console.log("Client record", record);
      const response = await APICall(`/client/${record._id}`, {}, 4);
      message.success("Client deleted successfully");
      fetchList();
    } catch (error) {
      message.error("Failed to delete customer");
    }
  };

  const handleFilterChange = (filter) => {
    if (filter === "all") {
      setIsActive(null);
    } else {
      setIsActive(filter === "active");
    }

    setTableParams((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        status: filter,
        searchTerm: prev.filters.searchTerm || "",
      },
      pagination: {
        ...prev.pagination,
        current: 1,
      },
    }));
  };

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDownloadPDF = async (selectedRecord) => {
    try {
      const res = await APICall(
        `/client/${selectedRecord?.user?._id}/password`,
        {},
        3
      );

      if (res?.data?.data) {
        const newPassword = res.data.data.password;

        const doc = new jsPDF();
        doc.text(`Your new password: ${newPassword}`, 10, 10);
        doc.save("password.pdf");
      } else {
        console.error("Failed to reset password or retrieve new password.");
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleCopyPassword = async (selectedRecord) => {
    try {
      const res = await APICall(
        `/client/${selectedRecord?.user?._id}/password`,
        {},
        3
      );

      if (res?.data?.data?.password) {
        const password = res.data.data.password;

        await navigator.clipboard.writeText(password);

        message.success("Password copied to clipboard!");
      } else {
        console.error("Failed to retrieve password.");
      }
    } catch (error) {
      console.error("Error copying password:", error);
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Client Type",
      key: "type",
      render: (_, record) => {
        return (
          <Link to={`/clients/clientDetails/${record.userId}`}>
            {record.type}
          </Link>
        );
      },
    },
    {
      title: "Client Name",
      key: "name",
      render: (_, record) => {
        return (
          <Link to={`/clients/clientDetails/${record.userId}`}>
            {record.user.name}
          </Link>
        );
      },
    },
    {
      title: "Seat Selected",
      key: "seatSelected",
      render: (_, record) => {
        const seats = record.clientBookingDetails?.seats || [];
        const seatNames = seats.map((seat) => seat.name).join(", ");
        return seatNames || "N/A";
      },
    },
    {
      title: "Total Conference Hours",
      key: "totalConferenceHours",
      render: (_, record) => {
        return record.clientBookingDetails &&
          record.clientBookingDetails.totalConferenceHour
          ? record.clientBookingDetails.totalConferenceHour
          : "N/A";
      },
    },
    {
      title: "Organization Name",
      key: "organizationName",
      render: (_, record) => {
        return record.organizationDetails &&
          record.organizationDetails.companyName
          ? record.organizationDetails.companyName
          : "NA";
      },
    },
    {
      title: "Mobile",
      key: "mobile",
      render: (_, record) => {
        return (
          <a href={`tel:+91${record.user.mobile}`}>{record.user.mobile}</a>
        );
      },
    },
    {
      title: "Email",
      key: "email",
      render: (_, record) => {
        return <a href={`mailto:${record.user.email}`}>{record.user.email}</a>;
      },
    },

    {
      title: "Access Status",
      key: "accessStatus",
      render: (_, record) => {
        const switchStyles = {
          cursor: "pointer",
          width: "50px",
          backgroundColor: record.user.isActive ? "#87d068" : "#d9d9d9",
          borderColor: record.user.isActive ? "#87d068" : "#d9d9d9",
        };
        const handleStyles = {
          backgroundColor: record.user.isActive ? "white" : "#8c8c8c",
        };

        return (
          <Switch
            checked={record.user.isActive}
            style={{
              cursor: "pointer",
              width: "40px",
              ...switchStyles,
              borderRadius: "20px",
            }}
            checkedChildren=""
            unCheckedChildren=""
            onChange={(checked) =>
              changeStatus(record, checked ? "enable" : "disable")
            }
            handleStyle={handleStyles}
          />
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                <Link
                  to={{
                    pathname: `/clients/manage/${record.userId}`,
                    search: `?filter=${
                      isActive === null
                        ? "all"
                        : isActive
                        ? "active"
                        : "inactive"
                    }`,
                    // state: { record },
                  }}
                >
                  Edit
                </Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link
                  to={{
                    pathname: `/clients/clientDetails/${record.userId}`,
                    search: `?filter=${tableParams.filters.status || "all"}`,
                    state: { record },
                  }}
                  state={{ record }}
                >
                  View
                </Link>
              </Menu.Item>
              {/* <Menu.Item
                key="3"
                onClick={() => {
                  if (record.user.isActive) {
                    handleDeactivate(record);
                  } else {
                    handleActivate(record);
                  }
                }}
              >
                {record.user.isActive ? "Block" : "Activate"}
              </Menu.Item> */}
              <Menu.Item
                key="4"
                onClick={() => {
                  confirm({
                    title: "Are you sure you want to delete this client?",
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    onOk() {
                      handleDelete(record);
                    },
                  });
                }}
              >
                Delete
              </Menu.Item>
              <Menu.Item key="5" onClick={() => showModal(record)}>
                Reset Password
              </Menu.Item>
            </Menu>
          }
          placement="bottom"
        >
          <Button type="link">Actions</Button>
        </Dropdown>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const breadcrumbItems = [
    <Breadcrumb.Item key="clients">
      <Link to="#">Clients</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <>
      <div style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}>
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Row>
        <Col span={24}>
          <Card
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                {isActive === null
                  ? "All Clients"
                  : isActive
                  ? "Active Clients"
                  : "Inactive Clients"}
                <div style={{ marginLeft: "4px" }}>({totalClientCount})</div>
              </div>
            }
            extra={
              <Row
                justify={"end"}
                align="top"
                style={{ paddingBottom: "10px" }}
              >
                <Space>
                  <Dropdown
                    overlay={
                      <Menu onClick={(e) => handleFilterChange(e.key)}>
                        <Menu.Item key="all">All</Menu.Item>
                        <Menu.Item key="active">Active</Menu.Item>
                        <Menu.Item key="inactive">Inactive</Menu.Item>
                      </Menu>
                    }
                    placement="bottomLeft"
                  >
                    <Button>
                      {isActive === null
                        ? "All"
                        : isActive
                        ? "Active"
                        : "Inactive"}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <Search
                    style={{
                      width: 220,
                    }}
                    placeholder="Search a Client"
                    allowClear
                    enterButton
                    size="middle"
                    onSearch={onSearch}
                  />
                  {(isActive === null || isActive) && (
                    <Link to="/clients/manage">
                      <Button
                        type="primary"
                        icon={<LuPlus style={{ paddingTop: "2px" }} />}
                      >
                        Create Client
                      </Button>
                    </Link>
                  )}
                </Space>
              </Row>
            }
          >
            <Table
              columns={columns}
              dataSource={customers}
              size="small"
              onChange={handleTableChange}
              pagination={{
                ...tableParams.pagination,
              }}
              loading={isLoading}
            />
          </Card>
        </Col>
      </Row>
      <Modal
        title="Client Details"
        open={!!viewModalData}
        onCancel={closeModal}
        footer={null}
      >
        {viewModalData && viewModalData.user && (
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginTop: 10 }}>
                <p>Type: {viewModalData.type}</p>
                <p>Name: {viewModalData.user.name}</p>
                <p>Mobile: {viewModalData.user.mobile}</p>
                <p>Mobile: {viewModalData.user.email}</p>
                <p>
                  Status: {viewModalData.user.isActive ? "Active" : "Inactive"}
                </p>
                {/* Other item details */}
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        title="Reset Password"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {selectedRecord ? (
          <>
            <Button
              type="primary"
              shape="round"
              onClick={() => handleDownloadPDF(selectedRecord)}
              style={{ margin: "10px 42px 6px 34px" }}
              icon={<DownloadOutlined />}
            >
              Download Password PDF
            </Button>
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                handleCopyPassword(selectedRecord);
              }}
              style={{ marginRight: "10px" }}
              icon={<CopyOutlined />}
            >
              Copy Password
            </Button>
          </>
        ) : (
          <p>No</p>
        )}
      </Modal>
    </>
  );
};

export default Customers;
