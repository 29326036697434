import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  Row,
  Col,
  Form,
  Radio,
  Select,
  Button,
  Badge,
  Space,
  Modal,
  Typography,
  Dropdown,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import APICall from "../../services/apiservices";

const { Option } = Select;
const { useForm } = Form;

const Attendance = () => {
  const [form] = Form.useForm();
  const [attendanceForm] = useForm();
  const [selectedValue, setSelectedValue] = useState("Weekly");
  const [pageSize, setPageSize] = useState(10);
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);
  const [attendanceDate, setAttendanceDate] = useState(Date.now());
  const [loading, setLoading] = useState(true);
  const [attendances, setAttendances] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getEmployeeAttendance(7);
  }, []);

  const getEmployeeAttendance = async (size) => {
    try {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const body = {};
      switch (size) {
        case 7:
          body.startDate = new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
          body.endDate = new Date();
          break;
        case 30:
          body.startDate = new Date(Date.now() - 29 * 24 * 60 * 60 * 1000);
          body.endDate = new Date();
          break;
        case 365:
          body.startDate = new Date(Date.now() - 364 * 24 * 60 * 60 * 1000);
          body.endDate = new Date();
          break;
        default:
          break;
      }
      const response = await APICall(
        `/employee/attendance?employeeId=${userData.employee._id}&startDate=${body.startDate}&endDate=${body.endDate}`,
        body,
        1
      );
      setAttendances(response.data[0].finalAttendance.reverse());
      setLoading(false);
      setCurrentPage(1);
    } catch (error) {
      message.error("Failed to fetch data");
    }
  };

  const handleSelect = (value) => {
    setSelectedValue(value);
  };

  const markTodayAttendance = () => {
    console.log(dayjs(Date.now()).format("DD/MM/YYYY"), "marked");
    setShowAttendanceModal(false);
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srNo",
      key: "srNo",
      render: (_, record, index) => {
        return (currentPage - 1) * pageSize + index + 1;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, record) => {
        return dayjs(record.date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Status",
      key: "status",
      render: (record) =>
        !record.employeeId ? (
          <>-</>
        ) : record.isHalfDay ? (
          <Badge status="default" text="Half Day" />
        ) : (
          <Badge status="success" text="Present" />
        ),
    },
    {
      title: "Extra Hours",
      dataIndex: "extraHours",
      key: "extraHours",
      render: (_, record) => (!record.otHours ? 0 : record.otHours),
    },
  ];

  useEffect(() => {
    switch (selectedValue) {
      case "Weekly":
        getEmployeeAttendance(7);
        break;
      case "Monthly":
        getEmployeeAttendance(30);
        break;
      case "Yearly":
        getEmployeeAttendance(365);
        break;
      default:
        getEmployeeAttendance(7);
    }
  }, [selectedValue]);

  return (
    <Row>
      <Col span={24}>
        <Card
          title="Attendance"
          extra={
            <Space>
              <Select
                defaultValue="Weekly"
                style={{ width: 120 }}
                onChange={handleSelect}
              >
                <Option value="Weekly">This Week</Option>
                <Option value="Monthly">This Month</Option>
                <Option value="Yearly"> This Year</Option>
              </Select>
              {/* <Button
                type="primary"
                onClick={() => {
                  setShowAttendanceModal(true);
                  setAttendanceDate(Date.now());
                }}
              >
                Mark Todays Attendance
              </Button> */}
            </Space>
          }
        >
          <Row>
            <Col
              lg={24}
              md={24}
              sm={24}
              xs={24}
            >
              <Table
                columns={columns}
                dataSource={attendances}
                bordered
                pagination={{
                  defaultPageSize: 10,
                  current: currentPage,
                  onChange: (page) => {
                    setCurrentPage(page);
                  },
                }}
                rowKey={(record) => record.id}
              /></Col>
          </Row>
        </Card>
      </Col>
      <Modal
        title="Mark Attendance"
        open={showAttendanceModal}
        onOk={attendanceForm.submit}
        onCancel={() => {
          setShowAttendanceModal(false);
        }}
        centered
      >
        {/* <Form form={attendanceForm} onFinish={onattendanceFormFinish}>
          <Space direction="vertical">
              <Typography.Text>
                {`Do you want to mark today's (${dayjs().format('DD-MM-YYYY')}) attendance?`}
              </Typography.Text>
              <Form.Item name={'status'} initialValue={'present'}>
                <Radio.Group defaultValue="present" buttonStyle="solid">
                  <Radio value="present">Present</Radio>
                  <Radio value="absent">Absent</Radio>
                  <Radio value="halfDay">Half Day</Radio>
                </Radio.Group>
              </Form.Item>
            </Space>
          </Form> */}
      </Modal>
    </Row>
  );
};

export default Attendance;
