import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Col,
  Row,
  message,
  Spin,
  Radio,
  DatePicker,
  Table,
  Dropdown,
  Modal,
  Space,
  Upload,
  Menu,
  Image,
  Divider,
  Breadcrumb,
  Select,
} from "antd";
import moment, { duration } from "moment";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import APICall from "../../../services/apiservices";
import axios from "axios";
import {
  DownOutlined,
  UploadOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "moment/locale/en-gb";
moment.locale("en-gb");
// import { useHistory } from 'react-router-dom';

const { confirm } = Modal;
const { Option } = Select;

const ManageCustomer = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [filter, setFilter] = useState(null);
  const { customerId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [editSeatForm] = Form.useForm();
  const [seatForm] = Form.useForm();
  const [previewImage, setPreviewImage] = useState(null);
  const [panImage, setpanImage] = useState(null);
  const [cinImage, setCinImage] = useState(null);
  const [companyPanImage, setCompanyPanImage] = useState(null);
  const [contactPersonForm] = Form.useForm();
  const [isSeatModalOpen, setIsSeatModalOpen] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerAllData, setCustomerAllData] = useState(null);
  const [clientEmployees, setClientEmployees] = useState([]);
  const [clientseats, setClientSeats] = useState([]);
  const [editingRecordIndex, setEditingRecordIndex] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [itemValue, setItemValue] = useState(null);
  const [itemToAdd, setItemToAdd] = useState(null);
  const [clientType, setClientType] = useState("Organization");
  const [items, setItems] = useState([]);
  const [img, setImg] = useState();
  const [selectedItem, setSelectedItem] = useState([]);
  const [itemForm] = Form.useForm();
  const [photoFileId, setPhotoFileId] = useState();
  const [aadhaarFileId, setAadhaarFileId] = useState(null);
  const [panCardId, setPanCardId] = useState(null);
  const [aadhaarCardId, setAadhaarCardId] = useState(null);
  const [showDepositFields, setShowDepositFields] = useState(false);
  const [showTDSFields, setShowTDSFields] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditContactModalVisible, setIsEditContactModalVisible] =
    useState(false);
  const [isEditSeatModalVisible, setIsEditSeatModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [editingRecordSeat, setEditingRecordSeat] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileName, setFileName] = useState("");
  const [panFileId, setPanFileId] = useState(null);
  const [companyPanFileId, setCompanyPanFileId] = useState(null);
  const [aadharFileId, setAadharFileId] = useState(null);
  const [seatFileId, setSeatFileId] = useState(null);
  const [cinFileId, setCinFileId] = useState(null);
  const [documents, setDocuments] = useState([]);

  // const history = useHistory();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {},
    sorter: {},
  });

  const handleTDSChange = (e) => {
    setShowTDSFields(e.target.value === "yes");
  };

  useEffect(() => {
    const isTds = form.getFieldValue("isTds");
    if (isTds === "yes") {
      setShowTDSFields(true);
    }
  }, [form]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filterParam = queryParams.get("filter");
    setFilter(filterParam);
  }, [location]);

  useEffect(() => {
    if (customerId) {
      getCustomerDetails(); // Fetch customer details when customerId changes
    }
  }, [customerId]);

  const handleEdit = (record) => {
    setEditingRecord(record);
    setIsEditContactModalVisible(true);
    editForm.setFieldsValue(record);
  };

  const showImage = (img) => {
    setImg(img);
    setVisible(true);
  };

  const handleEditOk = (values) => {
    setClientEmployees((prevEmployees) =>
      prevEmployees.map((employee) =>
        employee.email === editingRecord.email
          ? { ...employee, ...values }
          : employee
      )
    );
    setIsEditContactModalVisible(false);
    message.success("Employee updated successfully!");
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onOk = (values) => {
    handleEditOk(values);
  };

  const handleDepositChange = (e) => {
    setShowDepositFields(e.target.value === "yes");
    form.setFieldsValue({ isDeposit: e.target.value });
  };

  const handleOk = async () => {
    try {
      if (await contactPersonForm.validateFields()) {
        const formData = contactPersonForm.getFieldsValue();
        if (
          !clientEmployees.find(
            (cp) => cp.mobile === formData.mobile || cp.email === formData.email
          )
        ) {
          const tempClientEmployees = [...clientEmployees];
          setClientEmployees([...tempClientEmployees, formData]);
          contactPersonForm.resetFields();
          message.success("Employee added successfully.");
          setIsModalOpen(false);
        } else {
          message.error(
            "You already added a employee with same email or mobile"
          );
          return;
        }
      }
    } catch (error) {}
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onEditCancel = () => {
    setIsEditContactModalVisible(false);
  };

  const onEditOk = (values) => {
    handleEditOk(values);
  };

  const fetchItemList = async (searchValue) => {
    const res = await APICall(
      `/seat?page=1&limit=30&name=${searchValue}`,
      {},
      1
    );
    return res.data.documents.map((item) => ({
      label: `${item.name}`,
      value: item._id,
      data: item,
    }));
  };

  const handleRemoveEmployee = async (record, index) => {
    if (record._id) {
      Modal.confirm({
        title: `Remove Employee?`,
        content: `Do you want to remove ${record.name}?`,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: async () => {
          const response = await APICall(
            `/client/deleteClientEmployee`,
            { clientEmployeeId: record._id },
            2
          );
          message.success("Client Employee deleted successfully!");
          const updatedEmployees = clientEmployees.filter(
            (v) => v._id !== record._id
          );
          setClientEmployees(updatedEmployees);
        },
        onCancel: () => {
          // message.info("Delete canceled");
        },
      });
    } else {
      Modal.confirm({
        title: `Remove Employee?`,
        content: `Do you want to remove ${record.name}?`,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk: () => {
          setClientEmployees((prevClientEmployees) => {
            const newClientEmployees = [...prevClientEmployees];
            newClientEmployees.splice(index, 1);
            return newClientEmployees;
          });
          message.success("Employee remove successfully!");
        },
        onCancel: () => {
          // message.info("Delete canceled");
        },
      });
    }
  };

  const getCustomerDetails = async () => {
    try {
      const response = await APICall(`/client/${customerId}`, {}, 1);
      const customerData = response.data;

      console.log(customerData, "customerData");
      setPreviewImage(customerData?.user?.aadharURL);
      setpanImage(customerData?.user?.panURL);
      setCinImage(customerData?.cinURL);
      setCompanyPanImage(customerData?.companyPancardURL);
      const fetchedCustomerData = response.data;
      setCustomerAllData(fetchedCustomerData);
      const type = customerData?.type || "Organization";
      setClientType(type);

      const initialValues = {
        clientType: type,
        name: customerData?.user?.name,
        email: customerData?.user?.email,
        mobile: customerData?.user?.mobile,
        isActive: customerData?.user?.isActive,
        panNumber: customerData?.user?.panNumber,
        aadhaarId: customerData?.user?.aadhaarId,
        designation: customerData?.user?.designation,
        totalConferenceHour:
          customerData?.clientBookings?.[0]?.totalConferenceHour || 0,
        startDate: customerData?.clientBookings?.[0]?.startDate
          ? moment(customerData.clientBookings[0].startDate)
          : null,
        // duration: customerData?.clientBookings?.[0]?.duration,
        invoiceType: customerData?.clientBookings?.[0]?.invoiceType,
        isDeposit: customerData?.clientBookings?.[0]?.isDeposit,
        isTds: customerData?.clientBookings?.[0]?.isTds,
        totalMonths: customerData?.clientBookings?.[0]?.totalMonths,
        deposit: customerData?.clientBookings?.[0]?.deposit,
        tds: customerData?.clientBookings?.[0]?.tds,
        durationDays:
          customerData?.clientBookings?.[0]?.durationDays || undefined,
        durationMonths:
          customerData?.clientBookings?.[0]?.durationMonths || undefined,
        durationYears:
          customerData?.clientBookings?.[0]?.durationYears || undefined,
        companyName: customerData?.organizationDetails?.companyName,
        companyContact: customerData?.organizationDetails?.companyContact,
        companyEmail: customerData?.organizationDetails?.companyEmail,
        companyAddress: customerData?.organizationDetails?.companyAddress,
        companyState: customerData?.organizationDetails?.companyState,
        companyPincode: customerData?.organizationDetails?.companyPincode,
        gstNumber: customerData?.organizationDetails?.gstNumber,
        cin: customerData?.organizationDetails?.cin,
        companyPanNumber: customerData?.organizationDetails?.companyPanNumber,
        companyCin: customerData?.organizationDetails?.companyCin,
        website: customerData?.organizationDetails?.website,
        cinFileId: customerData?.organizationDetails?.cinFileId,
        companyPanFileId: customerData?.organizationDetails?.companyPanFileId,
        discount: customerData?.discount,
        discountType: customerData?.discountType,
      };
      form.setFieldsValue(initialValues);
      setCinFileId(customerData?.organizationDetails?.cinFileId || null);
      setCompanyPanFileId(
        customerData?.organizationDetails?.companyPanFileId || null
      );
      setSelectedItems(customerData?.clientSeats[0]?.seats);
      setClientEmployees(customerData?.clientEmployees);
      setShowDepositFields(initialValues.isDeposit === "yes");
      setShowTDSFields(initialValues.isTds === "yes");
      setIsLoading(false);
    } catch (error) {
      message.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const isDeposit = form.getFieldValue("isDeposit");
    if (isDeposit === "yes") {
      setShowDepositFields(true);
    }
  }, [form]);

  useEffect(() => {
    form.setFieldValue("state", "Maharashtra");
    if (customerId) {
      setIsLoading(true);
      getCustomerDetails();
    }
  }, []);

  const onFinish = async (formData) => {
    setIsLoading(true);
    if (clientType === "Individual") {
      const documents = [];

      if (panFileId) {
        documents.push({
          documentName: "Pan Card",
          fileId: panFileId,
        });
      }

      if (aadharFileId) {
        documents.push({
          documentName: "Aadhar Card",
          fileId: aadharFileId,
        });
      }

      const { durationDays, durationMonths, durationYears } = formData;
      if (!durationDays && !durationMonths && !durationYears) {
        message.error("Duration is required.");
        return;
      }

      const combinedData = {
        ...formData,
        seats: selectedItems,
        documents,
        panFileId: panFileId,
        aadharFileId: aadharFileId,
      };

      const seatData = selectedItems.map((item) => ({
        _id: item._id,
        name: item.name,
        description: item.description,
        price: item.price,
        quantity: item.quantity,
        photoFileId: item.photoFileId,
      }));
      console.log(combinedData, "individual");
      try {
        let response;
        if (customerId) {
          console.log("update");
          response = await APICall(`/client/${customerId}`, combinedData, 3);
          if (response.data) {
            const startDate = new Date(combinedData.startDate);
            const endDate = new Date(startDate);
            endDate.setDate(
              endDate.getDate() + parseInt(combinedData.duration)
            );

            const clientId = response.data._id;
            const bookingId = customerAllData?.clientBookings[0]?._id;

            const bookingData = {
              // clientId: clientId,
              totalConferenceHour: parseFloat(combinedData.totalConferenceHour),
              startDate: startDate,
              durationDays: parseInt(combinedData.durationDays),
              durationMonths: parseInt(combinedData.durationMonths),
              durationYears: parseInt(combinedData.durationYears),
              endDate: endDate,
              isDeposit: combinedData.isDeposit,
              deposit: showDepositFields
                ? parseFloat(combinedData.deposit)
                : null,
              totalMonths: showDepositFields
                ? parseInt(combinedData.totalMonths)
                : null,
              isTds: combinedData.isTds,
              invoiceType: combinedData.invoiceType,
              tds: combinedData.tds,
              seats: seatData,
            };
            const res = await APICall(`/booking/${bookingId}`, bookingData, 3);

            if (res.status === 200) {
              setIsLoading(false);
              message.success("Individual client updated successfully.");
              navigate(`/customers`);
            } else {
              setIsLoading(false);
              message.error("Failed to create booking.");
            }
          }
        } else {
          response = await APICall(`/client`, combinedData, 2);
          if (response.data) {
            const startDate = new Date(combinedData.startDate);
            const endDate = new Date(startDate);
            endDate.setDate(
              endDate.getDate() + parseInt(combinedData.duration)
            );
            const clientId = response.data._id;
            console.log(combinedData, "combinedData");
            const bookingData = {
              clientId: clientId,
              totalConferenceHour: parseFloat(combinedData.totalConferenceHour),
              startDate: startDate,
              durationDays: parseInt(combinedData.durationDays),
              durationMonths: parseInt(combinedData.durationMonths),
              durationYears: parseInt(combinedData.durationYears),
              // endDate: endDate,
              isDeposit: combinedData.isDeposit,
              deposit: showDepositFields
                ? parseFloat(combinedData.deposit)
                : null,
              totalMonths: showDepositFields
                ? parseInt(combinedData.totalMonths)
                : null,
              // isTds: combinedData.isTds,
              invoiceType: combinedData.invoiceType,
              // tds: combinedData.tds,
              seats: seatData,
            };
            console.log("Booking", bookingData);

            const res = await APICall("/booking", bookingData, 2);

            if (res.status === 200) {
              setIsLoading(false);
              message.success("Individual client created successfully.");
              navigate(`/customers`);
            } else {
              setIsLoading(false);
              message.error("Failed to create booking.");
            }
          }
        }

        // const response = await APICall(`/client`, formData, 2);
        // if (response.data) {
        //   setIsLoading(false);
        //   message.success("Personal client created successfully.");
        //   navigate(`/customers`);
        //   // selectedItems
        // }
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    } else {
      const {
        companyName,
        companyAddress,
        gstNumber,
        website,
        companyContact,
        companyPincode,
        companyEmail,
        companyState,
        companyPanNumber,
        companyCin,
        uploadPanCard,
        uploadAadharCard,
        discountType,
        ...rest
      } = formData;
      const documents = [];

      if (panFileId) {
        documents.push({
          documentName: "Pan Card",
          fileId: panFileId,
        });
      }

      if (aadharFileId) {
        documents.push({
          documentName: "Aadhar Card",
          fileId: aadharFileId,
        });
      }

      if (cinFileId) {
        documents.push({
          documentName: "CIN",
          fileId: cinFileId,
        });
      }

      if (companyPanFileId) {
        documents.push({
          documentName: "Company Pan Card",
          fileId: companyPanFileId,
        });
      }

      const organizationDetails = {
        companyName,
        companyAddress,
        gstNumber,
        website,
        companyContact,
        companyPincode,
        cinFileId,
        companyPanFileId,
        companyEmail,
        companyState,
        companyPanNumber,
        companyCin,
      };

      const finalFormData = {
        ...rest,
        clientType,
        organizationDetails,
        clientEmployees: [...clientEmployees],
        documents,
        discountType,
        panFileId: panFileId,
        aadharFileId: aadharFileId,
      };
      formData.clientEmployees = [...clientEmployees];
      const seatData = selectedItems.map((item) => ({
        _id: item._id,
        name: item.name,
        description: item.description,
        price: item.price,
        quantity: item.quantity,
        photoFileId: item.photoFileId,
        totalPrice: item.price * item.quantity,
      }));

      const combinedData = { ...formData, seats: seatData };
      // const seatIds = selectedItems.map((item) => item._id);
      console.log(combinedData, "all data");

      try {
        let response;
        if (customerId) {
          console.log(finalFormData, "update");
          response = await APICall(`/client/${customerId}`, finalFormData, 3);
          if (response.data) {
            const startDate = new Date(formData.startDate);
            const endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() + parseInt(formData.duration));

            const clientId = response.data._id;
            const bookingId = customerAllData?.clientBookings[0]?._id;

            const bookingData = {
              // clientId: clientId,
              totalConferenceHour: parseFloat(formData.totalConferenceHour),
              startDate: startDate,
              durationDays: parseInt(formData.durationDays),
              durationMonths: parseInt(formData.durationMonths),
              durationYears: parseInt(formData.durationYears),
              endDate: endDate,
              isDeposit: formData.isDeposit,
              deposit: showDepositFields ? parseFloat(formData.deposit) : null,
              totalMonths: showDepositFields
                ? parseInt(formData.totalMonths)
                : null,
              isTds: formData.isTds,
              invoiceType: formData.invoiceType,
              tds: formData.tds,
              seats: seatData,
            };
            const res = await APICall(`/booking/${bookingId}`, bookingData, 3);

            if (res.status === 200) {
              setIsLoading(false);
              message.success("Client updated successfully.");
              navigate(`/customers`);
            } else {
              setIsLoading(false);
              message.error("Failed to create booking.");
            }
          }
        } else {
          console.log(finalFormData, "create");
          response = await APICall(`/client`, finalFormData, 2);
          if (response.data) {
            const startDate = new Date(formData.startDate);
            const endDate = new Date(startDate);
            endDate.setDate(endDate.getDate() + parseInt(formData.duration));
            const clientId = response.data._id;

            const bookingData = {
              clientId: clientId,
              totalConferenceHour: parseFloat(formData.totalConferenceHour),
              startDate: startDate,
              durationDays: parseInt(formData.durationDays),
              durationMonths: parseInt(formData.durationMonths),
              durationYears: parseInt(formData.durationYears),
              endDate: endDate,
              isDeposit: formData.isDeposit,
              deposit: showDepositFields ? parseFloat(formData.deposit) : null,
              totalMonths: showDepositFields
                ? parseInt(formData.totalMonths)
                : null,
              isTds: formData.isTds,
              invoiceType: formData.invoiceType,

              tds: formData.tds,
              seats: seatData,
            };
            console.log(bookingData, "666");
            const res = await APICall("/booking", bookingData, 2);

            if (res.status === 200) {
              setIsLoading(false);
              message.success("Client created successfully.");
              navigate(`/customers`);
            } else {
              setIsLoading(false);
              message.error("Failed to create booking.");
            }
          }
        }
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    }
  };

  const changeNumerics = (data) => {
    // Logic to update numerical values
  };

  const handleAddItem = async () => {
    try {
      await seatForm.validateFields();
      const values = seatForm.getFieldsValue();
      const updatedItem = {
        ...itemToAdd,
        price: values.itemPrice,
        quantity: values.itemQuantity,
        totalPrice: values.itemQuantity * values.itemPrice,
      };
      console.log(updatedItem, "55");

      setSelectedItems((prevItems) => [...prevItems, updatedItem]);
      message.success("Seat added successfully.");
      seatForm.resetFields();
    } catch (error) {
      console.error("Error adding item:", error);
      message.error("Failed to add seat.");
    } finally {
      setIsSeatModalOpen(false);
    }
  };

  const showModal = (modalType) => {
    if (modalType === "employee") {
      setIsModalOpen(true);
    } else if (modalType === "item") {
      setIsSeatModalOpen(true);
    }
  };

  const handleView = async (itemId) => {
    try {
      console.log("Fetching item with ID:", itemId);
      // const response = await APICall(`/Seat/${itemId}`, {}, 1);
      console.log("View", itemId);
      setSelectedItem(itemId); // Set the selected item's details
      setIsModalVisible(true); // Show the modal
    } catch (error) {
      message.error(error);
    }
  };
  // const handleCancel = () => {
  //   setVisible(false); // Hide the modal
  // };

  useEffect(() => {
    const getItems = async () => {
      try {
        const response = await APICall("/item", {}, 1);
        setItems(response.data.documents);
        setIsLoading(false);
      } catch (error) {
        message.error(error.response.data.message);
        setIsLoading(false);
      }
    };

    // getItems();
  }, []);

  const handleItemDetailsCancel = () => {
    setIsModalVisible(false);
    setSelectedItem(null); // Optionally clear the selected item state
  };

  const handleCancelEmployeeModal = () => {
    setIsModalOpen(false);
  };

  const handleCancelSeatModal = () => {
    setIsSeatModalOpen(false);
  };

  const handleCinUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setCinImage(reader.result);
    };
    reader.readAsDataURL(file);
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Client",
    };
    try {
      const fileResponse = await APICall("/file", fileData, 2);
      await axios.put(fileResponse.data.data[0].url, file);
      setCinFileId(fileResponse.data.data[0].id);
      message.success("CIN uploaded successfully!");
      setImageUrl(fileResponse.data.data[0].url);
    } catch (error) {
      message.error("Failed to upload CIN image");
    }

    return false;
  };

  const handleAadharUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Client",
    };
    try {
      const fileResponse = await APICall("/file", fileData, 2);
      await axios.put(fileResponse.data.data[0].url, file);
      setAadharFileId(fileResponse.data.data[0].id);
      message.success("Aadhar card uploaded successfully!");
      setImageUrl(fileResponse.data.data[0].url);
    } catch (error) {
      message.error("Failed to upload aadhar card image");
    }

    return false;
  };

  const handleSeatUpload = async (file) => {
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Seat",
    };
    try {
      const fileResponse = await APICall("/file", fileData, 2);
      await axios.put(fileResponse.data.data[0].url, file);
      setSeatFileId(fileResponse.data.data[0].id);
      setFileName(file.name);
      setImageUrl(fileResponse.data.data[0].url);
      message.success("Image uploaded successfully!");
    } catch (error) {
      message.error("Failed to upload seat image");
    }

    return false;
  };

  const handleCompanyPanUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setCompanyPanImage(reader.result);
    };
    reader.readAsDataURL(file);
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Client",
    };
    try {
      const fileResponse = await APICall("/file", fileData, 2);
      await axios.put(fileResponse.data.data[0].url, file);
      setCompanyPanFileId(fileResponse.data.data[0].id);
      message.success("Pan card uploaded successfully!");
      setImageUrl(fileResponse.data.data[0].url);
    } catch (error) {
      message.error("Failed to upload pan card image");
    }

    return false;
  };

  const handlePanUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      setpanImage(reader.result);
    };
    reader.readAsDataURL(file);
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Client",
    };
    try {
      const fileResponse = await APICall("/file", fileData, 2);
      await axios.put(fileResponse.data.data[0].url, file);
      setPanFileId(fileResponse.data.data[0].id);
      message.success("Pan card uploaded successfully!");
      setImageUrl(fileResponse.data.data[0].url);
    } catch (error) {
      message.error("Failed to upload pan card image");
    }

    return false;
  };

  const handleFileUpload = async (file, type) => {
    console.log(file, "create all files");
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Client",
      // module: "Create Client",
    };

    try {
      const fileResponse = await APICall("/file", fileData, 2);
      await axios.put(fileResponse.data.data[0].url, file);

      const fileId = fileResponse.data.data[0].id;
      setPhotoFileId(fileResponse.data.data[0].id);
      if (type === "PAN") {
        setPanCardId(fileId);
      } else if (type === "AADHAR") {
        setAadhaarCardId(fileId);
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
    return false;
  };

  const disabledDate = (current) => {
    return current && current < moment().subtract(1, "days").endOf("day");
  };

  const handleDelete = (id) => {
    // Add your delete logic here
    message.success(`Deleted item with id: ${id}`);
  };

  useEffect(() => {
    setClientType("Organization");
  }, []);

  useEffect(() => {
    if (editingRecordSeat) {
      setFileName(editingRecordSeat.photoFileId || "");
      setSeatFileId(editingRecordSeat.photoFileId || "");
    }
  }, [editingRecordSeat]);

  const handleEditSeat = (record, index) => {
    console.log(record, "555");
    setEditingRecordSeat(record);
    setEditingRecordIndex(index);
    setIsEditSeatModalVisible(true);
    editSeatForm.setFieldsValue(record);
    setFileName(record.photoFileId || "");
  };

  const onEditOkSeat = (values) => {
    handleEditOkSeat(values);
  };

  const handleEditOkSeat = (values) => {
    const price = values.price * values.quantity;
    console.log(values, "56");
    setSelectedItems((prevSeats) =>
      prevSeats.map((seat, index) =>
        index === editingRecordIndex
          ? { ...seat, ...values, price, photoFileId: seatFileId }
          : seat
      )
    );
    setIsEditSeatModalVisible(false);
    message.success("Seat updated successfully!");
  };

  const itemColumns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image",
      render: (_, record) => {
        console.log("image", record);
        return <Image src={record.photoURL} width={60} />;
      },
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => {
        return record.name;
      },
      width: "15%",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (_, record) => {
        return record.description;
      },
      width: "15%",
    },
    {
      title: "Price (₹)",
      dataIndex: "price",
      render: (_, record) => {
        return <>₹{record.price}</>;
      },
      width: "10%",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (_, record) => {
        return record.quantity;
      },
      width: "10%",
    },
    {
      title: "Total Price (₹)",
      dataIndex: "totalPrice",
      render: (_, record) => {
        return record.totalPrice;
      },
      width: "15%",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record, index) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="view" onClick={() => handleView(record)}>
                View
              </Menu.Item>
              <Menu.Item
                key="edit"
                onClick={() => handleEditSeat(record, index)}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                key="delete"
                danger
                onClick={() => {
                  confirm({
                    title: `Remove Seats?`,
                    content: `Do you want to remove ${record.name}?`,
                    okText: "Yes",
                    okType: "danger",
                    cancelText: "No",
                    onOk: () => {
                      setSelectedItems((prevItems) => {
                        const newItems = [...prevItems];
                        newItems.splice(index, 1); // Remove the item at the specific index
                        return newItems;
                      });
                      message.success("Removed seat!");
                    },
                    onCancel: () => {
                      // message.info("Delete canceled");
                    },
                  });
                }}
              >
                Remove
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="primary" ghost>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "name",
      render: (_, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (_, record) => {
        return record.name;
      },
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      render: (_, record, index) => {
        return record.mobile;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (_, record, index) => {
        return record.email;
      },
    },
    {
      title: "Designation",
      dataIndex: "designation",
      render: (_, record, index) => {
        return record.designation;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: "15%",
      render: (_, record, index) => (
        <Dropdown
          menu={{
            items: [
              {
                key: 1,
                label: (
                  <span
                    style={{ color: "red" }}
                    onClick={() => handleRemoveEmployee(record, index)}
                  >
                    Remove
                  </span>
                ),
              },
              {
                key: 2,
                label: (
                  <span
                    style={{ color: "blue" }}
                    onClick={() => handleEdit(record)}
                  >
                    Edit
                  </span>
                ),
              },
            ],
          }}
          placement="bottom"
        >
          <Button type="link">Actions</Button>
        </Dropdown>
      ),
      align: "center",
    },
  ];

  const breadcrumbItems = customerId ? (
    <>
      <Breadcrumb.Item key="clients">
        <Link to={`/customers?filter=${filter || "all"}`}>Clients</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item key="Form">
        <Link to="#">Edit</Link>
      </Breadcrumb.Item>
    </>
  ) : (
    <>
      <Breadcrumb.Item key="clients">
        <Link to="/customers">Clients</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item key="Form">
        <Link to="#">Create</Link>
      </Breadcrumb.Item>
    </>
  );

  const validateDuration = (rule, value) => {
    const { durationDays, durationMonths, durationYears } = form.getFieldsValue(
      ["durationDays", "durationMonths", "durationYears"]
    );

    if (
      (durationDays === undefined || durationDays === null) &&
      (durationMonths === undefined || durationMonths === null) &&
      (durationYears === undefined || durationYears === null)
    ) {
      return Promise.reject("Duration is required.");
    }

    return Promise.resolve();
  };

  const handlePhotoDelete = () => {
    setPreviewImage(null);
    setAadhaarFileId(null);
    message.success("Aadhar card image removed successfully!");
  };

  const handlePanDelete = () => {
    setpanImage(null);
    setPanFileId(null);
    message.success("Pan card image removed successfully!");
  };

  const handleCinDelete = () => {
    setCinImage({ name: "" });
    setCinFileId(null);
    message.info("File deleted.");
  };

  const handleCompanyPanDelete = () => {
    setCompanyPanImage(null);
    setCompanyPanFileId(null);
    message.success("Comapany Pan card image removed successfully!");
  };

  return (
    <>
      <div style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}>
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      {isLoading ? (
        <Row
          justify="center"
          align="middle"
          style={{ position: "absolute", top: "50%", left: "50%" }}
        >
          <Spin tip={"Loading"} />
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Card title={customerId ? "Edit Client" : "Create Client"}>
              <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                  isActive: true,
                  clientType: clientType,
                  durationDays: "",
                  durationMonths: "",
                  durationYears: "",
                }}
              >
                <Row gutter={20}>
                  <Col span={8}>
                    <Form.Item
                      name="clientType"
                      label="Client Type"
                      rules={[
                        {
                          required: true,
                          message: "Please select client type.",
                        },
                      ]}
                    >
                      <Radio.Group
                        onChange={(e) => {
                          const value = e.target.value;
                          setClientType(value);
                          form.setFieldsValue({ clientType: value });
                        }}
                        value={clientType}
                        disabled={!!customerId}
                      >
                        <Radio
                          value="Organization"
                          disabled={customerId && clientType !== "Organization"}
                        >
                          Organization
                        </Radio>
                        <Radio
                          value="Individual"
                          disabled={customerId && clientType !== "Individual"}
                        >
                          Individual
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                {clientType === "Individual" && (
                  <>
                    <Card
                      title="Personal Information"
                      bordered={false}
                      style={{ marginBottom: "20px" }}
                    >
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                              {
                                required: true,
                                message: "Client name is required.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. Suryodaya Sankul"
                              tabIndex={1}
                            />
                          </Form.Item>

                          <Form.Item
                            name="panNumber"
                            label="PAN Number"
                            rules={[
                              {
                                // required: true,
                                pattern: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/,
                                message:
                                  "Invalid PAN Card Number. Please enter a valid PAN Card Number.",
                              },
                            ]}
                          >
                            <Input placeholder="e.g. ABCDE1234F" tabIndex={4} />
                          </Form.Item>
                          <Form.Item label="Upload PAN Card" name="file">
                            <Upload
                              Upload
                              accept=".jpg, .png"
                              beforeUpload={handlePanUpload}
                              multiple={false}
                            >
                              <Button tabIndex={7}>Click to Upload</Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="mobile"
                            label="Mobile"
                            rules={[
                              {
                                required: true,
                                message: "Customer mobile number is required.",
                              },
                              {
                                pattern: /^[0-9]{10}$/,
                                message:
                                  "Invalid mobile number. Please enter a valid 10-digit mobile number.",
                              },
                            ]}
                          >
                            <Input placeholder="e.g. 9876543210" tabIndex={2} />
                          </Form.Item>

                          <Form.Item
                            name="aadhaarId"
                            label="Aadhar Number"
                            rules={[
                              {
                                // required: true,
                                pattern: /^\d{12}$/,
                                message:
                                  "Invalid Aadhar Card Number. Please enter a valid Aadhar Card Number.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. 1234 5678 9101"
                              tabIndex={5}
                            />
                          </Form.Item>
                          <Form.Item label="Upload Aadhar Card" name="file">
                            <Upload
                              Upload
                              accept=".jpg, .png"
                              beforeUpload={handleAadharUpload}
                              multiple={false}
                            >
                              <Button tabIndex={8}>Click to Upload</Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                required: true,
                                message: "Customer email is required.",
                              },
                              {
                                type: "email",
                                message:
                                  "Invalid email address. Please enter a valid email address.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. ram.shah124@gmail.com"
                              tabIndex={3}
                            />
                          </Form.Item>
                          <Form.Item
                            name="designation"
                            label="Designation"
                            rules={[
                              {
                                // required: true,
                                message: "Client designatinon is required.",
                              },
                            ]}
                          >
                            <Input placeholder="e.g. Admin" tabIndex={6} />
                          </Form.Item>
                          <Form.Item
                            name="isActive"
                            label="Account Status"
                            rules={[
                              {
                                required: true,
                                message: "Please select the account status",
                              },
                            ]}
                          >
                            <Radio.Group
                              name="isActive"
                              defaultValue={true}
                              tabIndex={9}
                            >
                              <Radio value={true}>Active</Radio>
                              <Radio value={false}>Inactive</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                    <Divider />
                    <Card
                      title="Booking Details"
                      bordered={false}
                      style={{ marginBottom: "20px", marginTop: "-22px" }}
                    >
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item
                            name="totalConferenceHour"
                            label="Total Conference Hours"
                            rules={[
                              {
                                required: true,
                                message: "Total conference hours are required.",
                              },
                              {
                                pattern: /^\d+(\.\d{1,2})?$/,
                                message:
                                  "Please enter a valid number for total conference hours.",
                              },
                            ]}
                          >
                            <Input placeholder="e.g. 10.5" tabIndex={10} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="startDate"
                            label="Start Date"
                            rules={[
                              {
                                required: true,
                                message: "Start date is required.",
                              },
                            ]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              format="DD-MM-YYYY"
                              // disabledDate={disabledDate}
                              tabIndex={11}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="duration"
                            label="Duration"
                            rules={[
                              {
                                required: true,
                                validator: validateDuration,
                              },
                            ]}
                          >
                            <Row gutter={20}>
                              <Col span={8}>
                                <Form.Item name="durationDays" noStyle>
                                  <Input placeholder="Days" tabIndex={12} />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name="durationMonths" noStyle>
                                  <Input placeholder="Months" tabIndex={13} />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item name="durationYears" noStyle>
                                  <Input placeholder="Years" tabIndex={14} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="isDeposit"
                            label="Is Deposit"
                            rules={[
                              {
                                required: true,
                                message: "select the deposit",
                              },
                            ]}
                          >
                            <Radio.Group
                              onChange={handleDepositChange}
                              tabIndex={15}
                            >
                              <Radio value="yes">Yes</Radio>
                              <Radio value="no">No</Radio>
                            </Radio.Group>
                          </Form.Item>

                          {showDepositFields && (
                            <Row gutter={20}>
                              <Col span={12}>
                                <Form.Item
                                  name="totalMonths"
                                  label="Total Months"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Total months are required for deposit.",
                                    },
                                    {
                                      pattern: /^\d+$/,
                                      message:
                                        "Please enter a valid number of months.",
                                    },
                                  ]}
                                >
                                  <Input placeholder="e.g. 6" />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name="deposit"
                                  label="Deposit Amount"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Deposit amount is required.",
                                    },
                                    {
                                      pattern: /^\d+$/,
                                      message: "Please enter a valid amount.",
                                    },
                                  ]}
                                >
                                  <Input placeholder="e.g. 5000" />
                                </Form.Item>
                              </Col>
                            </Row>
                          )}
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="invoiceType"
                            label="Invoice Type"
                            rules={[
                              {
                                required: true,
                                message: "Please select invoice type.",
                              },
                            ]}
                          >
                            <Radio.Group tabIndex={16}>
                              <Radio value="withGST">With GST</Radio>
                              <Radio value="withoutGST">Without GST</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                    <Divider />
                    <Card
                      title="Seating"
                      style={{ marginBottom: "20px" }}
                      extra={
                        <Button
                          type="primary"
                          onClick={() => showModal("item")}
                          tabIndex={17}
                          ghost
                        >
                          Add Seats
                        </Button>
                      }
                    >
                      <Table
                        columns={itemColumns}
                        dataSource={selectedItems}
                        size="large"
                      />
                    </Card>
                  </>
                )}

                {clientType === "Organization" && (
                  <>
                    <Card
                      title="Owner Details"
                      bordered={false}
                      style={{ marginBottom: "20px" }}
                    >
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                              {
                                required: true,
                                message: "Client name is required.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. Suryodaya Sankul"
                              tabIndex={1}
                            />
                          </Form.Item>

                          <Form.Item
                            name="panNumber"
                            label="PAN Number"
                            rules={[
                              {
                                // required: true,
                                pattern: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/,
                                message:
                                  "Invalid PAN Card Number. Please enter a valid PAN Card Number.",
                              },
                            ]}
                          >
                            <Input placeholder="e.g. ABCDE1234F" tabIndex={4} />
                          </Form.Item>
                          <Form.Item label="Upload PAN Card" name="file">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Upload
                                Upload
                                accept=".jpg, .png"
                                beforeUpload={handlePanUpload}
                                showUploadList={false}
                                multiple={false}
                              >
                                <Button
                                  tabIndex={7}
                                  icon={
                                    customerId ? (
                                      <ReloadOutlined />
                                    ) : (
                                      <UploadOutlined />
                                    )
                                  }
                                >
                                  {customerId ? "Re-upload" : "Click to Upload"}
                                </Button>
                              </Upload>
                              {panImage && (
                                <Space
                                  style={{
                                    marginLeft: "5px",
                                  }}
                                >
                                  <Image
                                    src={panImage}
                                    alt="Profile Preview"
                                    width={30}
                                    height={30}
                                  />
                                  <Button
                                    type="text"
                                    icon={
                                      <DeleteOutlined
                                        style={{ color: "red" }}
                                      />
                                    }
                                    onClick={handlePanDelete}
                                  />
                                </Space>
                              )}
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="mobile"
                            label="Mobile"
                            rules={[
                              {
                                required: true,
                                message: "Client mobile number is required.",
                              },
                              {
                                pattern: /^[0-9]{10}$/,
                                message:
                                  "Invalid mobile number. Please enter a valid 10-digit mobile number.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. 9876543210"
                              type="text"
                              tabIndex={2}
                            />
                          </Form.Item>

                          <Form.Item
                            name="aadhaarId"
                            label="Aadhar Number"
                            rules={[
                              {
                                // required: true,
                                pattern: /^\d{12}$/,
                                message:
                                  "Invalid Aadhar Card Number. Please enter a valid Aadhar Card Number.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. 1234 5678 9101"
                              tabIndex={5}
                            />
                          </Form.Item>

                          <Form.Item label="Upload Aadhar Card" name="file">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Upload
                                Upload
                                accept=".jpg, .png"
                                beforeUpload={handleAadharUpload}
                                multiple={false}
                                showUploadList={false}
                              >
                                <Button
                                  tabIndex={8}
                                  icon={
                                    customerId ? (
                                      <ReloadOutlined />
                                    ) : (
                                      <UploadOutlined />
                                    )
                                  }
                                >
                                  {customerId ? "Re-upload" : "Click to Upload"}
                                </Button>
                              </Upload>
                              {previewImage && (
                                <Space
                                  style={{
                                    marginLeft: "5px",
                                  }}
                                >
                                  <Image
                                    src={previewImage}
                                    alt="Profile Preview"
                                    width={30}
                                    height={30}
                                  />
                                  <Button
                                    type="text"
                                    icon={
                                      <DeleteOutlined
                                        style={{ color: "red" }}
                                      />
                                    }
                                    onClick={handlePhotoDelete}
                                  />
                                </Space>
                              )}
                            </div>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                              {
                                required: true,
                                message: "Customer email is required.",
                              },
                              {
                                type: "email",
                                message:
                                  "Invalid email address. Please enter a valid email address.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. ram.shah124@gmail.com"
                              tabIndex={3}
                            />
                          </Form.Item>
                          <Form.Item
                            name="designation"
                            label="Designation"
                            rules={[
                              {
                                // required: true,
                                message: "Client designatinon is required.",
                              },
                            ]}
                          >
                            <Input placeholder="e.g. Admin" tabIndex={6} />
                          </Form.Item>
                          <Form.Item
                            name="isActive"
                            label="Account Status"
                            rules={[
                              {
                                required: true,
                                message: "Please select the account status",
                              },
                            ]}
                          >
                            <Radio.Group
                              name="isActive"
                              defaultValue={true}
                              tabIndex={9}
                            >
                              <Radio value={true}>Active</Radio>
                              <Radio value={false}>Inactive</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                    <Divider />
                    <Card
                      title="Organization Details"
                      bordered={false}
                      style={{ marginBottom: "20px", marginTop: "-22px" }}
                    >
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item
                            name="companyName"
                            label="Name"
                            rules={[
                              {
                                required: true,
                                message: "Company name is required.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. Techeval Softwares Pvt. Ltd."
                              tabIndex={10}
                            />
                          </Form.Item>

                          <Form.Item
                            name="companyAddress"
                            label="Address"
                            rules={[
                              {
                                required: true,
                                message: "Company address is required.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. 123 Main Street"
                              tabIndex={13}
                            />
                          </Form.Item>
                          <Form.Item
                            name="gstNumber"
                            label="GSTIN"
                            rules={[
                              {
                                // required: true,
                                pattern:
                                  /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[0-9A-Z]{1}$/,
                                message:
                                  "Invalid GSTIN. Please enter a valid GSTIN.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. 12ABCDE1234F1Z5"
                              tabIndex={16}
                            />
                          </Form.Item>
                          <Form.Item
                            name="website"
                            label="Website"
                            rules={[
                              {
                                // required: true,
                                message: "Website is required.",
                              },
                              {
                                type: "url",
                                message: "Please enter a valid URL.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. https://example.com"
                              tabIndex={19}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="companyContact"
                            label="Contact"
                            rules={[
                              {
                                required: true,
                                message: "company contact number is required.",
                              },
                              {
                                pattern: /^[0-9]{10}$/,
                                message:
                                  "Invalid mobile number. Please enter a valid 10-digit mobile number.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. 9876543210"
                              type="text"
                              tabIndex={11}
                            />
                          </Form.Item>
                          <Form.Item
                            name="companyPincode"
                            label="Pincode"
                            rules={[
                              {
                                required: true,
                                message: "Pincode is required.",
                              },
                              {
                                pattern: /^[1-9][0-9]{5}$/,
                                message:
                                  "Invalid pincode. Please enter a valid 6-digit pincode.",
                              },
                            ]}
                          >
                            <Input placeholder="e.g. 400001" tabIndex={14} />
                          </Form.Item>
                          <Form.Item
                            name="companyPanNumber"
                            label="PAN Number"
                            rules={[
                              {
                                // required: true,
                                pattern: /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/,
                                message:
                                  "Invalid PAN Card Number. Please enter a valid PAN Card Number.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. ABCDE1234F"
                              tabIndex={18}
                            />
                          </Form.Item>
                          {/* <Form.Item
                            name="companyCin"
                            label="CIN"
                            rules={[
                              {
                                // required: true,
                                pattern:
                                  /^[LU][0-9]{5}[A-Z]{2}[0-9]{4}[A-Z]{3}[0-9]{6}$/,
                                message:
                                  "Invalid CIN. Please enter a valid CIN.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. L12345KA1234PTC123456"
                              tabIndex={17}
                            />
                          </Form.Item>
                          <Form.Item label="Upload CIN" name="file">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Upload
                                Upload
                                accept=".jpg, .png"
                                beforeUpload={handleCinUpload}
                                multiple={false}
                                showUploadList={false}
                              >
                                <Button
                                  tabIndex={20}
                                  icon={
                                    customerId ? (
                                      <ReloadOutlined />
                                    ) : (
                                      <UploadOutlined />
                                    )
                                  }
                                >
                                  {customerId ? "Re-upload" : "Click to Upload"}
                                </Button>
                              </Upload>
                              {cinImage && (
                                <Space
                                  style={{
                                    marginLeft: "5px",
                                  }}
                                >
                                  <Image
                                    src={cinImage}
                                    alt="Profile Preview"
                                    width={30}
                                    height={30}
                                    style={{ borderRadius: "15px" }}
                                  />
                                  <Button
                                    type="text"
                                    icon={
                                      <DeleteOutlined
                                        style={{ color: "red" }}
                                      />
                                    }
                                    onClick={handleCinDelete}
                                  />
                                </Space>
                              )}
                            </div>
                          </Form.Item> */}
                        </Col>

                        <Col span={8}>
                          <Form.Item
                            name="companyEmail"
                            label="Email"
                            rules={[
                              {
                                required: true,
                                message: "company email is required.",
                              },
                              {
                                type: "email",
                                message:
                                  "Invalid email address. Please enter a valid email address.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. techeval@gmail.com"
                              tabIndex={12}
                            />
                          </Form.Item>

                          <Form.Item
                            name="companyState"
                            label="State"
                            rules={[
                              {
                                required: true,
                                message: "State is required.",
                              },
                            ]}
                          >
                            <Input
                              placeholder="e.g. Maharashtra"
                              tabIndex={15}
                            />
                          </Form.Item>

                          <Form.Item label="Upload PAN Card" name="file">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Upload
                                Upload
                                accept=".jpg, .png"
                                beforeUpload={handleCompanyPanUpload}
                                multiple={false}
                                showUploadList={false}
                              >
                                <Button
                                  tabIndex={21}
                                  icon={
                                    customerId ? (
                                      <ReloadOutlined />
                                    ) : (
                                      <UploadOutlined />
                                    )
                                  }
                                >
                                  {customerId ? "Re-upload" : "Click to Upload"}
                                </Button>
                              </Upload>
                              {companyPanImage && (
                                <Space
                                  style={{
                                    marginLeft: "5px",
                                  }}
                                >
                                  <Image
                                    src={companyPanImage}
                                    alt="Profile Preview"
                                    width={30}
                                    height={30}
                                  />
                                  <Button
                                    type="text"
                                    icon={
                                      <DeleteOutlined
                                        style={{ color: "red" }}
                                      />
                                    }
                                    onClick={handleCompanyPanDelete}
                                  />
                                </Space>
                              )}
                            </div>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>

                    <Card
                      title="Employees"
                      style={{ marginBottom: "20px" }}
                      extra={
                        <Button
                          type="primary"
                          onClick={() => showModal("employee")}
                          ghost
                          tabIndex={22}
                        >
                          Add Employee
                        </Button>
                      }
                    >
                      <Table
                        columns={columns}
                        dataSource={clientEmployees}
                        size="large"
                      />
                    </Card>
                    <Divider />
                    <Card
                      title="Booking Details"
                      bordered={false}
                      style={{ marginBottom: "20px", marginTop: "-22px" }}
                    >
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item
                            name="totalConferenceHour"
                            label="Total Conference Hours"
                            rules={[
                              {
                                required: true,
                                message: "Total conference hours are required.",
                              },
                              {
                                pattern: /^\d+(\.\d{1,2})?$/,
                                message:
                                  "Please enter a valid number for total conference hours.",
                              },
                            ]}
                          >
                            <Input placeholder="e.g. 10.5" tabIndex={29} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="startDate"
                            label="Start Date"
                            rules={[
                              {
                                required: true,
                                message: "Start date is required.",
                              },
                            ]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              format="DD-MM-YYYY"
                              // disabledDate={disabledDate}
                              tabIndex={30}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="duration"
                            label="Duration"
                            rules={[
                              {
                                required: true,
                                validator: validateDuration,
                              },
                            ]}
                          >
                            <Row gutter={20}>
                              <Col span={8}>
                                <Form.Item
                                  name="durationDays"
                                  noStyle
                                  rules={[
                                    {
                                      validator: (_, value) => {
                                        if (!value) {
                                          return Promise.reject(
                                            "Please enter a value"
                                          );
                                        } else if (isNaN(value)) {
                                          return Promise.reject(
                                            "Value must be a number"
                                          );
                                        } else if (value <= 0) {
                                          return Promise.reject(
                                            "Days must be greater than 0"
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                >
                                  <Input placeholder="Days" tabIndex={31} />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name="durationMonths"
                                  noStyle
                                  rules={[
                                    {
                                      validator: (_, value) => {
                                        if (!value) {
                                          return Promise.reject(
                                            "Please enter a value"
                                          );
                                        } else if (isNaN(value)) {
                                          return Promise.reject(
                                            "Value must be a number"
                                          );
                                        } else if (value <= 0) {
                                          return Promise.reject(
                                            "Months must be greater than 0"
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                >
                                  <Input placeholder="Months" tabIndex={32} />
                                </Form.Item>
                              </Col>
                              <Col span={8}>
                                <Form.Item
                                  name="durationYears"
                                  noStyle
                                  rules={[
                                    {
                                      validator: (_, value) => {
                                        if (!value) {
                                          return Promise.reject(
                                            "Please enter a value"
                                          );
                                        } else if (isNaN(value)) {
                                          return Promise.reject(
                                            "Value must be a number"
                                          );
                                        } else if (value <= 0) {
                                          return Promise.reject(
                                            "Years must be greater than 0"
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                >
                                  <Input placeholder="Years" tabIndex={33} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="isDeposit"
                            label="Is Deposit"
                            rules={[
                              {
                                required: true,
                                message: "Please select the deposit",
                              },
                            ]}
                          >
                            <Radio.Group onChange={handleDepositChange}>
                              <Radio tabIndex={35} value="yes">
                                Yes
                              </Radio>
                              <Radio tabIndex={36} value="no">
                                No
                              </Radio>
                            </Radio.Group>
                          </Form.Item>

                          {showDepositFields && (
                            <Row gutter={20}>
                              <Col span={12}>
                                <Form.Item
                                  name="totalMonths"
                                  label="Total Months"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Total months are required for deposit.",
                                    },
                                    {
                                      pattern: /^\d+$/,
                                      message:
                                        "Please enter a valid number of months.",
                                    },
                                  ]}
                                >
                                  <Input placeholder="e.g. 6" tabIndex={37} />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name="deposit"
                                  label="Deposit Amount"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Deposit amount is required.",
                                    },
                                    {
                                      pattern: /^\d+$/,
                                      message: "Please enter a valid amount.",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="e.g. 5000"
                                    tabIndex={38}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          )}
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="isTds"
                            label="Is TDS"
                            rules={[
                              {
                                required: true,
                                message: "Please select the TDS",
                              },
                            ]}
                          >
                            <Radio.Group onChange={handleTDSChange}>
                              <Radio value="yes" tabIndex={39}>
                                Yes
                              </Radio>
                              <Radio value="no" tabIndex={40}>
                                No
                              </Radio>
                            </Radio.Group>
                          </Form.Item>

                          {showTDSFields && (
                            <Row gutter={20}>
                              <Col span={12}>
                                <Form.Item
                                  name="tds"
                                  label="TDS Percentage"
                                  rules={[
                                    {
                                      required: true,
                                      message: "TDS percentage is required.",
                                    },
                                    {
                                      pattern: /^\d+$/,
                                      message:
                                        "Please enter a valid percentage.",
                                    },
                                  ]}
                                >
                                  <Input placeholder="e.g. 10" tabIndex={41} />
                                </Form.Item>
                              </Col>
                            </Row>
                          )}
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            name="invoiceType"
                            label="Invoice Type"
                            rules={[
                              {
                                required: true,
                                message: "Please select invoice type.",
                              },
                            ]}
                          >
                            <Radio.Group>
                              <Radio tabIndex={42} value="withGST">
                                With GST
                              </Radio>
                              <Radio tabIndex={43} value="withoutGST">
                                Without GST
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                    <Divider />
                    <Card
                      title="Discount"
                      bordered={false}
                      style={{ marginBottom: "20px", marginTop: "-22px" }}
                    >
                      <Row gutter={20}>
                        <Col span={8}>
                          <Form.Item
                            name="discount"
                            label="Discount"
                            style={{ marginBottom: 0 }}
                          >
                            <Input placeholder="5%" tabIndex={29} />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item
                            name="discountType"
                            initialValue="%"
                            style={{ marginTop: "30px" }}
                          >
                            <Select defaultValue="%" style={{ width: "60%" }}>
                              <Option value="%">%</Option>
                              <Option value="₹">₹</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                    <Divider />
                    <Card
                      title="Seating"
                      style={{ marginBottom: "20px" }}
                      extra={
                        <Button
                          type="primary"
                          onClick={() => showModal("item")}
                          ghost
                          tabIndex={44}
                        >
                          Add Seats
                        </Button>
                      }
                    >
                      <Table
                        columns={itemColumns}
                        dataSource={selectedItems}
                        size="large"
                      />
                    </Card>
                  </>
                )}
                <Form.Item style={{ marginTop: "20px" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    tabIndex={50}
                    style={{ backgroundColor: "#26b689" }}
                  >
                    {customerId ? "Save" : "Create"}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      )}
      <Form form={contactPersonForm} layout="vertical" onFinish={handleOk}>
        <Modal
          title="Add Employee"
          open={isModalOpen}
          onCancel={handleCancelEmployeeModal}
          okText="Add"
          onOk={() => {
            contactPersonForm.submit();
          }}
          centered
          footer={[
            <Button
              key="cancel"
              onClick={handleCancelEmployeeModal}
              tabIndex={28}
            >
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={() => contactPersonForm.submit()}
              tabIndex={27}
            >
              Add
            </Button>,
          ]}
        >
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Employee's name is required.",
                  },
                ]}
              >
                <Input placeholder="e.g. Rajat Patil" tabIndex={23} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="mobile"
                label="Mobile"
                rules={[
                  {
                    required: true,
                    message: "Employee's mobile number is required.",
                  },
                  {
                    pattern: /^[0-9]{10}$/,
                    message:
                      "Invalid mobile number. Please enter a valid 10-digit mobile number.",
                  },
                ]}
              >
                <Input tabIndex={24} placeholder="e.g. 9876543210" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Employee's email is required.",
                  },
                  {
                    type: "email",
                    message:
                      "Invalid email address. Please enter a valid email address.",
                  },
                ]}
              >
                <Input placeholder="e.g. rajat.patil@gmail.com" tabIndex={25} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="designation" label="Designation">
                <Input placeholder="e.g. Secretary" tabIndex={26} />
              </Form.Item>
            </Col>
            {/* <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Add
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </Space>
            </Form.Item> */}
          </Row>
        </Modal>
      </Form>
      <Modal
        title="Add Seats"
        open={isSeatModalOpen}
        onCancel={handleCancelSeatModal}
        centered
        footer={[
          <Button key="cancel" onClick={handleCancelSeatModal} tabIndex={49}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => seatForm.submit()}
            tabIndex={48}
          >
            Add
          </Button>,
        ]}
      >
        <Form form={seatForm} layout="vertical" onFinish={handleAddItem}>
          <Form.Item
            name="itemName"
            label="Seat Name"
            rules={[{ required: true, message: "Please enter seat name" }]}
          >
            <DebounceSelect
              tabIndex={45}
              value={itemValue}
              placeholder="e.g. Cabin"
              fetchOptions={fetchItemList}
              onChange={(newValue, selectedItem) => {
                setItemValue(newValue);
                const { price } = selectedItem.data;
                console.log(selectedItem, "Resposne");
                setItemToAdd(selectedItem.data);
                seatForm.setFieldsValue({
                  item: selectedItem.data,
                  itemPrice: price,
                  itemQuantity: 1,
                });
              }}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="itemPrice"
            label="Seat Price"
            rules={[
              // { required: true, message: "Please enter seat price" },
              {
                required: true,
                validator: (_, value) =>
                  value && value > 0
                    ? Promise.resolve()
                    : Promise.reject(
                        "Please enter seat price & Price must be a positive number"
                      ),
              },
            ]}
            style={{ width: "100%", whiteSpace: "nowrap" }}
          >
            <Input placeholder="e.g. 30000" tabIndex={46} />
          </Form.Item>
          <Form.Item
            name="itemQuantity"
            label="Seat Quantity"
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  value && value > 0
                    ? Promise.resolve()
                    : Promise.reject(
                        "Please enter seat quantity & Quantity must be a positive number"
                      ),
              },
            ]}
            style={{ width: "100%" }}
          >
            <Input placeholder="e.g. 1" tabIndex={47} />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Seat Details"
        open={isModalVisible}
        onCancel={handleItemDetailsCancel}
        footer={null}
      >
        {selectedItem && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <img
                src={selectedItem.photoURL}
                alt={selectedItem.name}
                style={{
                  width: 100,
                  height: 100,
                  objectFit: "cover",
                  marginRight: 20,
                }}
              />
              <div>
                <p>Name: {selectedItem.name}</p>
                <p>Price: {selectedItem.price}</p>
                <p>Description: {selectedItem.description}</p>
                <p>Quantity: {selectedItem.quantity}</p>
                {/* Other item details */}
              </div>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        open={isEditContactModalVisible}
        title="Edit Employee"
        onCancel={onEditCancel}
        onOk={() => {
          editForm.validateFields().then((values) => {
            onEditOk(values);
          });
        }}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input the name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="mobile"
            label="Mobile"
            rules={[
              { required: true, message: "Please input the mobile" },
              {
                pattern: /^[0-9]*$/,
                message: "Please enter only numbers",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Please input the email" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="designation" label="Designation">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={isEditSeatModalVisible}
        title="Edit Seat"
        onCancel={() => setIsEditSeatModalVisible(false)}
        onOk={() => {
          editSeatForm.validateFields().then((values) => {
            onEditOkSeat(values);
          });
        }}
      >
        <Form form={editSeatForm} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input the name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please input the description" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[{ required: true, message: "Please input the price" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="quantity"
            label="Quantity"
            rules={[{ required: true, message: "Please input the quantity" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Image" name="file">
            <Upload
              Upload
              accept=".jpg, .png"
              beforeUpload={handleSeatUpload}
              multiple={false}
            >
              <Button>Click to Upload</Button>
            </Upload>
            {fileName && (
              <div style={{ marginTop: 8, color: "red" }}>{fileName}</div>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ManageCustomer;
