import React from "react";
import { Form, Input, Button, Card, Row, Col, DatePicker, Space } from "antd";
import { Link } from "react-router-dom";

const ManageVisits = ({ isEdit, onFinish }) => {
  const [form] = Form.useForm();


  const handleClear = () => {
    form.resetFields();
  };


  return (
    <Row>
      <Col span={24}>
        <Card
          title={isEdit ? "Edit Visit" : "Schedule Visit"}
          extra={
            <Link to="/visits">
              <Button type="primary" tabIndex={7}>Back</Button>
            </Link>
          }
        >
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            wrapperCol={{
              span: 14,
            }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="visitNumber"
                  label="Visit Number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the visit number",
                    },
                    {
                      type: "number",
                      message: "Please enter a valid number",
                    },
                  ]}
                >
                  <Input placeholder="Enter visit number" tabIndex={1}/>
                </Form.Item>

                <Form.Item
                  name="assignedTo"
                  label="Assigned To"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the assigned person",
                    },
                  ]}
                >
                  <Input placeholder="Enter assigned person" tabIndex={3} />
                </Form.Item>
                <Form.Item
                  name="visitDate"
                  label="Visit Date"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the visit date",
                    },
                  ]}
                >
                  <DatePicker placeholder="Enter visit date"  tabIndex={5}/>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="customer"
                  label="Customer"
                  rules={[
                    {
                      required: true,
                      message: "Please enter the customer name",
                    },
                  ]}
                >
                  <Input placeholder="Enter customer name"  tabIndex={2}/>
                </Form.Item>

                <Form.Item
                  name="scheduledDate"
                  label="Scheduled Date"
                  rules={[
                    {
                      required: true,
                      message: "Please select the scheduled date",
                    },
                  ]}
                >
                  <DatePicker placeholder=" Scheduled date" tabIndex={4} />
                </Form.Item>
              </Col>
              <Space style={{marginLeft: '10px'}}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    // style={{ float: "right" }}
                    tabIndex={6}
                  >
                    {isEdit ? "Save" : "Create"}
                  </Button>
                </Form.Item>
                <Form.Item>
                <Button
                  type="primary"
                  ghost
                  onClick={handleClear}
                >
                  Clear
                </Button>
                </Form.Item>
              </Space>
            </Row>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default ManageVisits;
