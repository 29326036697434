import React, { useState, useRef } from "react";
import { Empty, Select, Spin } from "antd";

const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, ...props }) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const searchTimeout = useRef(null);

  const handleSearch = (value) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        setOptions(newOptions);
        setFetching(false);
      });
    }, debounceTimeout);
  };

  return (
    <Select
      showSearch
      allowClear
      filterOption={false}
      onSearch={handleSearch}
      notFoundContent={fetching ? <Spin /> : null}
      {...props}
    >
      {options.map((opt) => (
        <Select.Option key={opt.value} value={opt.value} data={opt.data}>
          {opt.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DebounceSelect;
