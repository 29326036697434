import React, { useEffect, useState } from "react";
import { LuPlus } from "react-icons/lu";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  message,
  Modal,
  Dropdown,
  Space,
  Input,
  Menu,
  Breadcrumb,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import APICall from "../../services/apiservices";
import Quotation from "../../pdfs/quotation";
// import PrintPreview from "../../pdfs/printPreview";
import { handleDownloadPDF } from "../../pdfs/pdfService";
import { useAuth } from "../../auth/AuthProvider";

const Quotations = () => {
  const { userData } = useAuth();
  const navigate = useNavigate();
  const { confirm } = Modal;
  const { Search } = Input;
  const { quotationId } = useParams();
  const [quotations, setQuotations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const getQuotations = async (searchTerm, page, limit) => {
    try {
      const response = await APICall(
        `/quotation?page=${page}&limit=${limit}&query=${searchTerm}`,
        {},
        1
      );
      console.log(response.data, "111");
      return response.data;
    } catch (error) {
      message.error("Failed to fetch Quotation details");
      return null;
    }
  };

  const fetchList = async () => {
    setIsLoading(true); // Set loading to true at the start

    const page = tableParams.pagination.current;
    const limit = tableParams.pagination.pageSize;
    const searchTerm = tableParams.filters.searchTerm || "";

    try {
      const response = await getQuotations(searchTerm, page, limit);

      if (response && response.documents) {
        const filteredDocuments = response.documents.filter(
          (doc) => doc.userId === userData._id
        );
        setQuotations(filteredDocuments);

        // Only update pagination if it has changed
        if (response.total !== tableParams.pagination.total) {
          setTableParams((prevParams) => ({
            ...prevParams,
            pagination: {
              ...prevParams.pagination,
              total: response.total,
            },
          }));
        }
      }
    } catch (error) {
      message.error("Failed to fetch Quotation details");
    } finally {
      setIsLoading(false); // Always set loading to false at the end
    }
  };

  useEffect(() => {
    fetchList();
  }, [
    tableParams.pagination.current,
    tableParams.pagination.pageSize,
    tableParams.filters.searchTerm,
  ]);

  const handleDelete = async (record) => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete quotation?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await APICall(`/quotation/${record._id}`, {}, 4);
          message.success("Quotation deleted successfully!");
          fetchList();
        } catch (error) {
          message.error("Failed to delete quotation");
        }
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
  };

  const handleDownload = (data) => {
    console.log(data, "555");
    const quotationData = {
      ...data,
      letterheader: "/quotation_header.png",
      allData: data,
      customer: {
        name: data.user.name,
        address: data.user.address,
      },
      totalAmountWords: data.totalAmount.toFixed(2),
      date: moment(data.date).format("DD/MM/YYYY"),
      quoatationNo: `QUOTE/${
        new Date().getFullYear().toString().slice(-2) +
        "-" +
        (new Date().getFullYear() + 1).toString().slice(-2)
      }/${(data?.quotationNo).toString().padStart(4, "0")}`,
    };
    const itemsData = data?.items?.map((item) => {
      const i = item.itemId;
      return {
        image: data.photoFileId
          ? data.photoFileId
          : "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png",
        name: i && i.name ? i.name : "Item Name Not Available",
        hsnCode: i && i.hsn ? i.hsn : "HSN Code Not Available",
        description:
          i && i.description ? i.description : "Item Description Not Available",
        amount: item.price || 0,
        quantity: item.quantity || 0,
        totalAmount: (item.price || 0) * (item.quantity || 0),
      };
    });

    quotationData.data = itemsData;
    handleDownloadPDF({
      pages: [<Quotation {...quotationData} />],
      fileName: `${data?.user?.name}-Quotation-${data?.quotationNo}`,
    });
  };

  // const handlePrint = async (quotationId) => {
  //   const response = await APICall(`/quotation/${quotationId}`, {}, 1);
  //   const quotation = response.data.quotation;

  //   const quotationData = {
  //     ...quotation,
  //     letterheader: "/quotation_header.png",
  //     allData: quotation,
  //     customer: {
  //       name: quotation.customer.user.name,
  //       address: quotation.customer.user.address,
  //     },
  //     totalAmountWords: withDecimal(quotation.totalAmount),
  //     date: moment(quotation.date).format("DD/MM/YYYY"),
  //     quoatationNo: `QUOTE/${
  //       new Date().getFullYear().toString().slice(-2) +
  //       "-" +
  //       (new Date().getFullYear() + 1).toString().slice(-2)
  //     }/${(quotation?.quotationNo).toString().padStart(4, "0")}`,
  //   };

  //   const data = response.data.items.map((i) => ({
  //     image:
  //       i.item.photoURL ||
  //       "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png",
  //     name: i.item.name,
  //     hsnCode: i.item.hsn,
  //     description: i.item.description,
  //     amount: i.price,
  //     quantity: i.quantity,
  //     totalAmount: i.price * i.quantity,
  //   }));

  //   quotationData.data = data;

  //   console.log("Complete Quotation Data:", quotationData);
  //   const htmlString = ReactDOMServer.renderToStaticMarkup(
  //     <PrintPreview
  //       letterheader={quotationData.letterheader}
  //       letterfooter={quotationData.letterfooter}
  //       date={quotationData.date}
  //       customer={quotationData.customer}
  //       data={quotationData.data}
  //       quoatationNo={quotationData.quoatationNo}
  //       subject={quotationData.subject}
  //       amount={quotationData.amount}
  //       discountAmount={quotationData.discountAmount}
  //       cgstAmount={quotationData.cgstAmount}
  //       sgstAmount={quotationData.sgstAmount}
  //       totalAmount={quotationData.totalAmount}
  //       totalAmountWords={quotationData.totalAmountWords}
  //       termsAndConditions={quotationData.termsAndConditions}
  //     />
  //   );

  //   const printWindow = window.open("", "", "width=800,height=600");

  //   printWindow.document.write(
  //     `<!DOCTYPE html>
  //     <html>
  //     <head>
  //       <title>Print Preview</title>
  //       <style>
  //         @media print {
  //           body {
  //             margin: 0;
  //             padding: 0;
  //             font-family: Times, serif;
  //           }
  //           img {
  //             max-width: 100%;
  //             height: auto;
  //           }
  //         }
  //       </style>
  //     </head>
  //     <body>${htmlString}</body>
  //     </html>`
  //   );

  //   printWindow.document.close();
  //   printWindow.onload = () => {
  //     printWindow.focus();
  //     printWindow.print();
  //     printWindow.close();
  //   };
  // };

  // function convertNumberToWords(amount) {
  //   var words = new Array();
  //   words[0] = "";
  //   words[1] = "One";
  //   words[2] = "Two";
  //   words[3] = "Three";
  //   words[4] = "Four";
  //   words[5] = "Five";
  //   words[6] = "Six";
  //   words[7] = "Seven";
  //   words[8] = "Eight";
  //   words[9] = "Nine";
  //   words[10] = "Ten";
  //   words[11] = "Eleven";
  //   words[12] = "Twelve";
  //   words[13] = "Thirteen";
  //   words[14] = "Fourteen";
  //   words[15] = "Fifteen";
  //   words[16] = "Sixteen";
  //   words[17] = "Seventeen";
  //   words[18] = "Eighteen";
  //   words[19] = "Nineteen";
  //   words[20] = "Twenty";
  //   words[30] = "Thirty";
  //   words[40] = "Forty";
  //   words[50] = "Fifty";
  //   words[60] = "Sixty";
  //   words[70] = "Seventy";
  //   words[80] = "Eighty";
  //   words[90] = "Ninety";
  //   amount = amount.toString();
  //   var atemp = amount.split(".");
  //   var number = atemp[0].split(",").join("");
  //   var n_length = number.length;
  //   var words_string = "";
  //   if (n_length <= 9) {
  //     var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
  //     var received_n_array = new Array();
  //     for (var i = 0; i < n_length; i++) {
  //       received_n_array[i] = number.substr(i, 1);
  //     }
  //     for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
  //       n_array[i] = received_n_array[j];
  //     }
  //     for (var i = 0, j = 1; i < 9; i++, j++) {
  //       if (i == 0 || i == 2 || i == 4 || i == 7) {
  //         if (n_array[i] == 1) {
  //           n_array[j] = 10 + parseInt(n_array[j]);
  //           n_array[i] = 0;
  //         }
  //       }
  //     }
  //     let value = "";
  //     for (var i = 0; i < 9; i++) {
  //       if (i == 0 || i == 2 || i == 4 || i == 7) {
  //         value = n_array[i] * 10;
  //       } else {
  //         value = n_array[i];
  //       }
  //       if (value != 0) {
  //         words_string += words[value] + " ";
  //       }
  //       if (
  //         (i == 1 && value != 0) ||
  //         (i == 0 && value != 0 && n_array[i + 1] == 0)
  //       ) {
  //         words_string += "Crores ";
  //       }
  //       if (
  //         (i == 3 && value != 0) ||
  //         (i == 2 && value != 0 && n_array[i + 1] == 0)
  //       ) {
  //         words_string += "Lakhs ";
  //       }
  //       if (
  //         (i == 5 && value != 0) ||
  //         (i == 4 && value != 0 && n_array[i + 1] == 0)
  //       ) {
  //         words_string += "Thousand ";
  //       }
  //       if (
  //         i == 6 &&
  //         value != 0 &&
  //         n_array[i + 1] != 0 &&
  //         n_array[i + 2] != 0
  //       ) {
  //         words_string += "Hundred and ";
  //       } else if (i == 6 && value != 0) {
  //         words_string += "Hundred ";
  //       }
  //     }
  //     words_string = words_string.split("  ").join(" ");
  //   }
  //   return words_string;
  // }

  // function withDecimal(n) {
  //   var nums = n.toString().split(".");
  //   var whole = convertNumberToWords(nums[0]) + "Rupees ";
  //   if (nums.length == 2) {
  //     var fraction = convertNumberToWords(nums[1]);
  //     return whole + "and " + fraction + "Paise";
  //   } else {
  //     return whole;
  //   }
  // }

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
    },
    {
      title: "Date",
      key: "date",
      render: (_, data) => {
        return moment(data.date).format("YYYY-MM-DD");
      },
    },
    {
      title: "Customer Name",
      key: "name",
      render: (_, data) => {
        return data.user.name;
      },
    },
    {
      title: "Amount (₹)",
      key: "amount",
      render: (_, data) => {
        return data.amount;
      },
    },
    {
      title: "Discount ",
      key: "discount",
      render: (_, data) => {
        return `${data.client.discount || 0} (${data.client.discount || 0} ${
          data.client.discountType || "%"
        })`;
      },
    },
    {
      title: "GST (₹)",
      key: "gst",
      render: (_, data) => {
        const totalGST = data.sgstAmount + data.cgstAmount;
        return `${totalGST}`;
      },
    },
    {
      title: "TDS (₹)",
      key: "tds",
      render: (_, data) => {
        return `${data.tdsAmount} `;
      },
    },
    {
      title: "Total Amount (₹)",
      key: "totalAmount",
      render: (_, data) => {
        const discountRate = data.client.discount;
        const discountType = data.client.discountType;

        let discountAmount = 0;

        if (discountType === "%") {
          // Calculate percentage-based discount
          discountAmount = (data.totalAmount * discountRate) / 100;
        } else if (discountType === "₹") {
          // Apply fixed rupee discount
          discountAmount = discountRate;
        }
        const finalAmount = data.totalAmount - discountAmount;
        return finalAmount.toFixed(2);
      },
    },
    {
      title: "Actions",
      key: "actions",
      fixed: "right",
      render: (_, data) => (
        <Dropdown
          overlay={
            <Menu>
              {/* <Menu.Item key="1">
                <Link to={`/quotations/manage/${data._id}`}>Edit</Link>
              </Menu.Item> */}
              <Menu.Item key="2">
                <Link to={`/quotations/${data._id}`}>Preview</Link>
              </Menu.Item>
              {/* <Menu.Item key="3">
                <a onClick={() => handlePrint(data._id)}>Print</a>
              </Menu.Item> */}
              <Menu.Item
                key="4"
                type="link"
                onClick={() => handleDownload(data)}
              >
                Download
              </Menu.Item>
              <Menu.Item
                key="5"
                danger
                type="link"
                onClick={() => handleDelete(data)}
              >
                Delete
              </Menu.Item>
            </Menu>
          }
          placement="bottomCenter"
        >
          <Button type="link">Actions</Button>
        </Dropdown>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevParams) => ({
      ...prevParams,
      pagination: {
        ...pagination,
      },
      filters: {
        ...filters,
      },
    }));
  };

  const onSearch = (value) => {
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
      pagination: {
        ...tableParams.pagination,
        current: 1, // Reset to first page on new search
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/quotations/manage">New</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/quotations/month">Monthly</Link>
      </Menu.Item>
    </Menu>
  );

  const breadcrumbItems = [
    <Breadcrumb.Item key="clients">
      <Link to="#">Invoices</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <>
      <div
        type="inner"
        style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}
      >
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Row>
        <Col span={24}>
          <Card
            title="Invoices"
            extra={
              <Row
                justify={"end"}
                align="top"
                style={{ paddingBottom: "10px" }}
              >
                <Space>
                  <Search
                    style={{
                      width: 220,
                    }}
                    placeholder="Search by customer"
                    allowClear
                    enterButton
                    size="middle"
                    onSearch={onSearch}
                  />
                  {/* <Dropdown
                    overlay={menu}
                    trigger={["hover"]}
                    placement="bottomRight"
                  >
                    <Button
                      type="primary"
                      icon={<LuPlus style={{ paddingTop: "2px" }} />}
                    >
                      Create Invoices
                    </Button>
                  </Dropdown> */}
                  {/* <Link to="/quotations/manage">
                    <Button
                      type="primary"
                      icon={<LuPlus style={{ paddingTop: "2px" }} />}
                    >
                      Create Invoices
                    </Button>
                  </Link> */}
                </Space>
              </Row>
            }
          >
            <Table
              loading={isLoading}
              columns={columns}
              dataSource={quotations}
              onChange={handleTableChange}
              size="small"
              pagination={tableParams.pagination}
              scroll={{ x: "1000px" }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Quotations;
