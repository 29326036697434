import React from "react";
import { Text, View, StyleSheet, Page } from "@react-pdf/renderer";

function checkStrEmpty(str) {
  return !(str && str.length > 1 && str.split(" ").join("").length > 0);
}

function CustomTablePDF(props) {
  const { fields = [], data = [] } = props;
  let tableCol = {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: "#000",
    borderRightWidth: 1,
    borderBottomWidth: 0.5,
    borderTopWidth: 0.5,
    borderTopColor: "#000",
  };
  return (
    <View style={[styles.table, props.quotation && { marginHorizontal: 16 }]}>
      {!props?.amc && (
        <View style={[styles.tableRow, styles.headerBg]}>
          {fields.map((_item, _idx) => (
            <View
              key={_idx}
              style={[
                tableCol,
                { width: _item.width + "%" },
                { borderBottomWidth: 1 },
              ]}
            >
              <Text style={[styles.tableCellHeader, { textAlign: "center" }]}>
                {_item.title}
              </Text>
            </View>
          ))}
        </View>
      )}

      {data.map(
        (item, idx) =>
          item && (
            <View
              key={idx}
              style={[
                styles.tableRow,
                idx === 0 && { borderTopWidth: 1 },
                props.quotation && { borderTopWidth: 1 },
                ,
                props.amc && { borderTopWidth: 0 },
              ]}
              wrap={false}
            >
              {fields.map((_item, _idx) => {
                let val = item[_item.value] || "";
                let value_alt =
                  (_item.value_alt && item[_item.value_alt]) || "";

                if (_item.custom) {
                  return (
                    <View
                      key={_idx}
                      style={[
                        tableCol,
                        { width: _item.width + "%" },
                        // props.quotation &&
                        //   idx !== data.length - 1 && { borderBottomWidth: 0 },
                      ]}
                    >
                      <Text
                        style={[
                          styles.tableCell,
                          _item.style ? _item.style : {},
                        ]}
                      >
                        {_item.component(item)}
                      </Text>
                    </View>
                  );
                } else {
                  return (
                    <View
                      style={[styles.tableCol, { width: _item.width + "%" }]}
                    >
                      <Text
                        style={[styles.tableCell, item.style ? item.style : {}]}
                      >
                        {checkStrEmpty(val) ? value_alt : val || "-"}
                      </Text>
                    </View>
                  );
                }
              })}
            </View>
          )
      )}
    </View>
  );
}

const BORDER_COLOR = "#000";
const BORDER_STYLE = "solid";
const styles = StyleSheet.create({
  headerBg: {
    backgroundColor: "#fff",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    fontFamily: "Times-Roman",
    borderTopWidth: 1,
  },
  tableRow: {
    margin: 0,
    flexDirection: "row",
    borderLeftWidth: 1,
    // borderTopWidth: 1,
  },

  tableCellHeader: {
    margin: 2,
    fontSize: 13,
    // fontWeight: "bold",
    fontFamily: "Times-Bold",
    // fontFamily: "CustomRoboto",
  },
  tableCell: {
    margin: 2,
    fontSize: 12,
    // height: 30,
    // fontFamily: "CustomRoboto",
  },
  textCenter: {
    textAlign: "center",
  },
});

export default CustomTablePDF;
