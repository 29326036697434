import React from "react";
import { utils, write } from "xlsx-js-style";

// const ExportSurvey = () => {
const allBorder = {
  left: {
    style: "thin",
    color: {
      rgb: "000000",
    },
  },
  right: {
    style: "thin",
    color: {
      rgb: "000000",
    },
  },
  bottom: {
    style: "thin",
    color: {
      rgb: "000000",
    },
  },
  top: {
    style: "thin",
    color: {
      rgb: "000000",
    },
  },
};

const companyHeaderStyle = {
  alignment: {
    horizontal: "center",
    vertical: "center",
    wrapText: true,
  },
  font: {
    bold: true,
    sz: 28,
    name: "Times New Roman",
  },
  fill: {
    fgColor: { rgb: "C2D69B" },
  },
  border: allBorder,
};

const companySubHeaderStyle = {
  alignment: {
    horizontal: "center",
    vertical: "center",
    wrapText: true,
  },
  font: {
    bold: true,
    sz: 14,
    name: "Times New Roman",
  },
  fill: {
    fgColor: { rgb: "C2D69B" },
  },
  border: allBorder,
};

const boldStyle = {
  font: {
    bold: true,
    sz: 12,
    name: "Times New Roman",
  },
  border: allBorder,
};

const centerAlignment = {
  alignment: {
    horizontal: "center",
    vertical: "center",
    wrapText: true,
  },
};

const columnTitleStyle = {
  ...boldStyle,
  ...centerAlignment,
  fill: {
    fgColor: { rgb: "FABF8F" },
  },
};

const getFinalArray = (array, placeholder = "") => {
  const totalLength = 13;
  const finalArray = [...array];
  const otherArray = [];
  if (finalArray.length <= totalLength)
    for (let i = finalArray.length; i < totalLength; i++)
      otherArray.push(placeholder);
  const newFinalArray = [...finalArray, ...otherArray];
  return newFinalArray;
};

//   const exportData = {
//     company: {
//       name: "Fire Fight Safety Solutions",
//       address:
//         "Sr. No.85, Office No.7A/204, Kalpataru Estate, Jawalkar Nagar,Pimple Gurav, Pune - 411061",
//       mobile: "9970548822",
//       email: "info@ffss.in",
//     },
//     customer: {
//       name: "The Wood's Housing Society, Kalewadi.",
//     },
//     employee: {
//       name: "Samir Kale",
//     },
//     date: "07/08/2023",
//     requirements: {
//       adaptor: {
//         name: "Adaptor",
//         existing: 2,
//         good: 1,
//         requirement: 1,
//       },
//       hoseBox: {
//         name: "Hose Box",
//         existing: 2,
//         good: 1,
//         requirement: 1,
//       },
//       branchPipe: {
//         name: "Branch Pipe",
//         existing: 2,
//         good: 1,
//         requirement: 1,
//       },
//       hosePipe: {
//         name: "Hose Pipe",
//         existing: 2,
//         good: 1,
//         requirement: 1,
//       },
//       hydrantValve: {
//         name: "Hydrant Valve",
//         existing: 2,
//         good: 1,
//         requirement: 1,
//       },
//       hoseReel: {
//         name: "Hose Reel",
//         existing: 2,
//         good: 1,
//         requirement: 1,
//       },
//       fireExtinguishers: {
//         name: "Fire Extinguisher",
//         existing: 2,
//         good: 1,
//         requirement: 1,
//       },
//       hoseBoxGlass: {
//         name: "Hose Box Glass",
//         existing: 2,
//         good: 1,
//         requirement: 1,
//       },
//       keyGlass: {
//         name: "Key Glass",
//         existing: 2,
//         good: 1,
//         requirement: 1,
//       },
//       hydrantValveWheel: {
//         name: "Hydrant Valve Wheel",
//         existing: 2,
//         good: 1,
//         requirement: 1,
//       },
//       hoseReelBallValve: {
//         name: "Hose Reel Ball Valve",
//         existing: 2,
//         good: 1,
//         requirement: 1,
//       },
//     },
//     buildings: [
//       {
//         building: "Capital A",
//         siteIncharge: "Satyajit Tolchire",
//         notes:
//           "1. All Hydrant Valve need servicing\n2. Terrace Booster Pump Need Servicing\n3. Alternate Floor should need fire extinguishers.\n4. Hose Box Glass 1st, 3rd, 5th and Basement Front Side Not OK\n5. Key Glass Basement Front Side Not OK and Hydrant Valve Not OK",
//         areas: [
//           {
//             srNo: 1,
//             area: "Floor 1",
//             equipments: {
//               adaptor: "OK",
//               hoseBox: "Not OK",
//               branchPipe: "OK",
//               hosePipe: "OK",
//               hydrantValve: "OK",
//               hoseReel: "Not OK",
//               fireExtinguishers: "OK",
//               hoseBoxGlass: "OK",
//               keyGlass: "OK",
//               hydrantValveWheel: "OK",
//               hoseReelBallValve: "Not OK",
//             },
//           },
//           {
//             srNo: 2,
//             area: "Floor 2",
//             equipments: {
//               adaptor: "Not OK",
//               hoseBox: "OK",
//               branchPipe: "OK",
//               hosePipe: "Not OK",
//               hydrantValve: "OK",
//               hoseReel: "OK",
//               fireExtinguishers: "OK",
//               hoseBoxGlass: "OK",
//               keyGlass: "OK",
//               hydrantValveWheel: "Not OK",
//               hoseReelBallValve: "OK",
//             },
//           },
//         ],
//         requirements: {
//           adaptor: {
//             name: "Adaptor",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hoseBox: {
//             name: "Hose Box",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           branchPipe: {
//             name: "Branch Pipe",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hosePipe: {
//             name: "Hose Pipe",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hydrantValve: {
//             name: "Hydrant Valve",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hoseReel: {
//             name: "Hose Reel",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           fireExtinguishers: {
//             name: "Fire Extinguisher",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hoseBoxGlass: {
//             name: "Hose Box Glass",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           keyGlass: {
//             name: "Key Glass",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hydrantValveWheel: {
//             name: "Hydrant Valve Wheel",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hoseReelBallValve: {
//             name: "Hose Reel Ball Valve",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//         },
//       },
//       {
//         building: "Capital B",
//         siteIncharge: "Satyajit Tolchire",
//         notes:
//           "1. All Hydrant Valve need servicing\n2. Terrace Booster Pump Need Servicing",
//         areas: [
//           {
//             srNo: 1,
//             area: "Basement",
//             equipments: {
//               adaptor: "OK",
//               hoseBox: "Not OK",
//               branchPipe: "OK",
//               hosePipe: "OK",
//               hydrantValve: "OK",
//               hoseReel: "Not OK",
//               fireExtinguishers: "OK",
//               hoseBoxGlass: "OK",
//               keyGlass: "OK",
//               hydrantValveWheel: "OK",
//               hoseReelBallValve: "Not OK",
//             },
//           },
//           {
//             srNo: 2,
//             area: "Floor 1",
//             equipments: {
//               adaptor: "Not OK",
//               hoseBox: "OK",
//               branchPipe: "OK",
//               hosePipe: "Not OK",
//               hydrantValve: "OK",
//               hoseReel: "OK",
//               fireExtinguishers: "OK",
//               hoseBoxGlass: "OK",
//               keyGlass: "OK",
//               hydrantValveWheel: "Not OK",
//               hoseReelBallValve: "OK",
//             },
//           },
//           {
//             srNo: 2,
//             area: "Floor 2",
//             equipments: {
//               adaptor: "Not OK",
//               hoseBox: "OK",
//               branchPipe: "OK",
//               hosePipe: "Not OK",
//               hydrantValve: "OK",
//               hoseReel: "OK",
//               fireExtinguishers: "OK",
//               hoseBoxGlass: "OK",
//               keyGlass: "OK",
//               hydrantValveWheel: "Not OK",
//               hoseReelBallValve: "OK",
//             },
//           },
//         ],
//         requirements: {
//           adaptor: {
//             name: "Adaptor",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hoseBox: {
//             name: "Hose Box",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           branchPipe: {
//             name: "Branch Pipe",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hosePipe: {
//             name: "Hose Pipe",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hydrantValve: {
//             name: "Hydrant Valve",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hoseReel: {
//             name: "Hose Reel",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           fireExtinguishers: {
//             name: "Fire Extinguisher",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hoseBoxGlass: {
//             name: "Hose Box Glass",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           keyGlass: {
//             name: "Key Glass",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hydrantValveWheel: {
//             name: "Hydrant Valve Wheel",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//           hoseReelBallValve: {
//             name: "Hose Reel Ball Valve",
//             existing: 2,
//             good: 1,
//             requirement: 1,
//           },
//         },
//       },
//     ],
//   };

const getBuildingSheet = ({
  company,
  customer,
  employee,
  date,
  building,
  type = "Survey",
  description,
}) => {
  const areas = [];
  const requirements = [];

  building.areas.map((area, index) =>
    areas.push([
      { v: `${index + 1}`, s: { ...boldStyle, ...centerAlignment } },
      { v: area.area, s: { ...boldStyle, ...centerAlignment } },
      ...Object.values(area.equipments).map((e) => ({
        v: e,
        s: centerAlignment,
      })),
    ])
  );

  Object.values(building.requirements).map((equipment, index) =>
    requirements.push(
      getFinalArray([
        { v: `${index + 1}`, s: { ...boldStyle, ...centerAlignment } },
        { v: equipment.name, s: { ...boldStyle, ...centerAlignment } },
        { v: equipment.existing, s: centerAlignment },
        { v: equipment.good, s: centerAlignment },
        { v: equipment.requirement, s: centerAlignment },
      ])
    )
  );

  const data = [
    getFinalArray([{ v: company.name, s: companyHeaderStyle }]),
    getFinalArray([{ v: company.address, s: companySubHeaderStyle }]),
    getFinalArray([
      {
        v: `Contact: ${company.mobile} E-Mail: ${company.email}`,
        s: companySubHeaderStyle,
      },
    ]),
    getFinalArray([""]),
    getFinalArray([
      {
        v: `Name Of Account: ${customer.name}`,
        s: { ...boldStyle, alignment: {} },
      },
      "",
      "",
      "",
      "",
      "",
      "",
      {
        v: `Fire Audit ${type} Report ${description ? `(${description})` : ""}`,
        s: { ...companySubHeaderStyle, fill: { fgColor: { rgb: "FFFFFF" } } },
      },
    ]),
    getFinalArray([
      {
        v: `Conducted by: ${employee.name}`,
        s: { ...boldStyle, alignment: {} },
      },
    ]),
    getFinalArray([
      {
        v: `Site Incharge Name: ${building.inCharge}`,
        s: { ...boldStyle, alignment: {} },
      },
      "",
      "",
      "",
      "",
      "",
      "",
      {
        v: `Wing: ${building.building}`,
        s: { ...companySubHeaderStyle, fill: { fgColor: { rgb: "FFFFFF" } } },
      },
    ]),
    getFinalArray([
      { v: `Date Of ${type}: ${date}`, s: { ...boldStyle, alignment: {} } },
    ]),
    getFinalArray([]),
    getFinalArray([
      { v: "Sr. No.", s: columnTitleStyle },
      { v: `Area of ${type} (Location)`, s: columnTitleStyle },
      { v: "Existing Equipment", s: columnTitleStyle },
    ]),
    getFinalArray([
      "",
      "",
      { v: "Adaptor", s: columnTitleStyle },
      { v: "Hose Box", s: columnTitleStyle },
      { v: "Branch Pipe", s: columnTitleStyle },
      { v: "Hose Pipe", s: columnTitleStyle },
      { v: "Hydrant Valve", s: columnTitleStyle },
      { v: "Hose Reel", s: columnTitleStyle },
      { v: "Fire Extinguishers", s: columnTitleStyle },
      { v: "Hose Box Glass", s: columnTitleStyle },
      { v: "Key Glass", s: columnTitleStyle },
      { v: "Hydrant Valve Wheel", s: columnTitleStyle },
      { v: "Hose Reel Ball Valve", s: columnTitleStyle },
    ]),
    ...areas,
    getFinalArray([]),
    getFinalArray([
      {
        v: "Total Unit And Requirment",
        s: { ...boldStyle, ...centerAlignment },
      },
      "",
      "",
      "",
      "",
      "",
      { v: "Notes", s: { ...boldStyle, alignment: {} } },
    ]),
    getFinalArray([
      { v: "Sr. No", s: columnTitleStyle },
      { v: "Product Type", s: columnTitleStyle },
      { v: "Qty Existing", s: columnTitleStyle },
      { v: "Qty In Good Condition", s: columnTitleStyle },
      { v: "Qty Required", s: columnTitleStyle },
      "",
      {
        v: building.notes,
        s: { alignment: { vertical: "top", wrapText: true } },
      },
    ]),
    ...requirements,
  ];

  // Create a worksheet
  const ws = utils.aoa_to_sheet(data);

  // Add border to each cell
  Object.keys(ws).map((k) => {
    if (typeof ws[k] == "object") {
      ws[k].s = ws[k].s
        ? { ...ws[k].s, border: allBorder }
        : { border: allBorder };
    }
  });

  ws["!rows"] = [{ hpt: 40 }, { hpt: 20 }, { hpt: 20 }];

  ws["!cols"] = [
    { wch: 15 },
    { wch: 20 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
  ];

  const equipmentBreakPadding = areas.length + 11;

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 12 } }, // Header
    { s: { r: 1, c: 0 }, e: { r: 1, c: 12 } }, // Subheader
    { s: { r: 2, c: 0 }, e: { r: 2, c: 12 } }, // Subheader
    { s: { r: 3, c: 0 }, e: { r: 3, c: 12 } }, // Break

    { s: { r: 4, c: 0 }, e: { r: 4, c: 6 } }, // Survey Detail
    { s: { r: 5, c: 0 }, e: { r: 5, c: 6 } }, // Survey Detail
    { s: { r: 6, c: 0 }, e: { r: 6, c: 6 } }, // Survey Detail
    { s: { r: 7, c: 0 }, e: { r: 7, c: 6 } }, // Survey Detail

    { s: { r: 4, c: 7 }, e: { r: 5, c: 12 } }, // Survey Report
    { s: { r: 6, c: 7 }, e: { r: 7, c: 12 } }, // Building/Wing

    { s: { r: 8, c: 0 }, e: { r: 8, c: 12 } }, // break

    { s: { r: 9, c: 0 }, e: { r: 10, c: 0 } }, // Sr. No.
    { s: { r: 9, c: 1 }, e: { r: 10, c: 1 } }, // Area of Survey (Location)
    { s: { r: 9, c: 2 }, e: { r: 9, c: 12 } }, // Existing Equipment

    {
      s: { r: equipmentBreakPadding, c: 0 },
      e: { r: equipmentBreakPadding, c: 12 },
    }, // break

    {
      s: { r: equipmentBreakPadding + 1, c: 0 },
      e: { r: equipmentBreakPadding + 1, c: 4 },
    }, // Quantity Table Header

    {
      s: { r: equipmentBreakPadding + 1, c: 6 },
      e: { r: equipmentBreakPadding + 1, c: 9 },
    }, // Notes Header

    {
      s: { r: equipmentBreakPadding + 2, c: 6 },
      e: { r: equipmentBreakPadding + 13, c: 9 },
    }, // Notes
  ];
  ws["!merges"] = merge;

  return ws;
};

const getFinalReportSheet = ({ buildingCount, requirements }) => {
  const requirementsArray = [];

  Object.values(requirements).map((equipment, index) =>
    requirementsArray.push([
      { v: `${index + 1}`, s: { ...boldStyle, ...centerAlignment } },
      { v: equipment.name, s: { ...boldStyle, ...centerAlignment } },
      { v: equipment.good, s: centerAlignment },
      { v: equipment.requirement, s: centerAlignment },
    ])
  );

  const data = [
    [
      {
        v: `Units and Requirments (Total Buildings: ${buildingCount})`,
        s: companySubHeaderStyle,
      },
      "",
      "",
      "",
    ],
    [
      { v: "Sr. No", s: columnTitleStyle },
      { v: "Product Type", s: columnTitleStyle },
      { v: "Qty In Good Condition", s: columnTitleStyle },
      { v: "Qty Required", s: columnTitleStyle },
    ],
    ...requirementsArray,
  ];

  // Create a worksheet
  const ws = utils.aoa_to_sheet(data);

  // Add border to each cell
  Object.keys(ws).map((k) => {
    if (typeof ws[k] == "object") {
      ws[k].s = ws[k].s
        ? { ...ws[k].s, border: allBorder }
        : { border: allBorder };
    }
  });

  ws["!rows"] = [{ hpt: 20 }];

  ws["!cols"] = [
    { wch: 10 },
    { wch: 20 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
  ];

  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 3 } }, // Header
  ];
  ws["!merges"] = merge;

  return ws;
};

const downloadXLSX = (exportData, type = "Survey") => {
  // Create a workbook and add the worksheet
  const wb = utils.book_new();

  for (const building of exportData.buildings) {
    const ws = getBuildingSheet({
      company: exportData.company,
      customer: exportData.customer,
      employee: exportData.employee,
      date: exportData.date,
      description: exportData.description,
      building,
      type,
    });
    utils.book_append_sheet(wb, ws, building.building);
  }

  const finalReportWs = getFinalReportSheet({
    buildingCount: exportData.buildings.length,
    requirements: exportData.requirements,
  });
  utils.book_append_sheet(wb, finalReportWs, "Final Report");

  // Convert the workbook to a buffer
  const buffer = write(wb, { bookType: "xlsx", type: "buffer" });

  // Convert the buffer to a Blob
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  // Create a download link
  const downloadLink = URL.createObjectURL(blob);

  // Trigger the download
  const link = document.createElement("a");
  link.href = downloadLink;
  link.download = `${type}_Sheet.xlsx`;
  link.click();

  // Clean up the object URL
  URL.revokeObjectURL(downloadLink);
};

//   return <span onClick={downloadXLSX}>Export Survey</span>;
// };

export default downloadXLSX;
