import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Descriptions,
  Row,
  Col,
  Image,
  Avatar,
  Tag,
  Table,
  Space,
  Breadcrumb,
} from "antd";
import { useParams, Link, useLocation } from "react-router-dom";
import APICall from "../../../services/apiservices";
import moment from "moment";

const ClientDetails = () => {
  const location = useLocation();
  const [filter, setFilter] = useState(null);
  const params = useParams();
  const [visible, setVisible] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [img, setImg] = useState();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const filterParam = queryParams.get("filter");
    setFilter(filterParam);
  }, [location]);

  const showImage = (img) => {
    setImg(img);
    console.log(img);
    setVisible(true);
  };

  const getEmployee = async () => {
    try {
      const res = await APICall(`/client/${params.clientId}`, {}, 1);
      setClientData(res.data);
      console.log(res.data);
    } catch (error) {
      console.error("Error fetching client data:", error);
    }
  };

  useEffect(() => {
    getEmployee();
  }, []);

  const columns = [
    {
      title: "Sr. No",
      dataIndex: "srNo",
      key: "srNo",
      width: 80,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
  ];

  const dataSource = clientData?.clientEmployees?.map((employee, index) => ({
    key: employee._id, // unique key for each row
    srNo: index + 1,
    name: employee.name,
    email: employee.email,
    mobile: employee.mobile,
    designation: employee.designation,
  }));

  const seatColumns = [
    {
      title: "Sr. No",
      dataIndex: "srNo",
      key: "srNo",
      width: 70,
    },
    {
      title: "Seat Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Total Price",
      dataIndex: "totalPrice",
      key: "totalPrice",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("DD-MM-YYYY"),
    },
    {
      title: "Image",
      dataIndex: "photoURL",
      key: "photoURL",
      render: (photoURL) => (
        <Avatar
          src={
            photoURL
              ? photoURL
              : `https://xsgames.co/randomusers/avatar.php?g=pixel&key=1`
          }
          size={100}
          onClick={() => {
            showImage(photoURL);
          }}
          style={{
            cursor: "pointer",
            width: "30px",
            height: "30px",
            borderRadius: "0px",
          }}
        />
      ),
    },
  ];

  const seatDataSource = clientData?.clientSeats[0]?.seats?.map(
    (seat, index) => ({
      key: seat._id,
      srNo: index + 1,
      name: seat.name,
      description: seat.description,
      price: seat.price,
      quantity: seat.quantity,
      createdAt: seat.createdAt,
      photoURL: seat.photoURL,
      totalPrice: seat.totalPrice,
    })
  );

  const breadcrumbs = [
    <Breadcrumb.Item key="clients">
      <Link to={`/customers?filter=${filter || "all"}`}>Clients</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="Form">
      <Link to="#">Details</Link>
    </Breadcrumb.Item>,
  ];

  const cardTitle =
    clientData?.type === "Organization"
      ? "Owner Details"
      : "Personal Information";

  return (
    <>
      <div style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}>
        <Breadcrumb separator=">">{breadcrumbs}</Breadcrumb>
      </div>
      <Card title="Client Details">
        <Row gutter={10}>
          <Col span={24}>
            <Card
              title={cardTitle}
              bordered={false}
              style={{ marginBottom: "20px" }}
            >
              <Row gutter={10}>
                <Col span={24}>
                  <Descriptions
                    layout="horizontal"
                    bordered
                    size="small"
                    column={3}
                  >
                    <Descriptions.Item label="Client Type">
                      <Tag color="magenta">{clientData?.type}</Tag>
                    </Descriptions.Item>

                    <Descriptions.Item label="Name">
                      {clientData?.user?.name}
                    </Descriptions.Item>

                    <Descriptions.Item label="Mobile">
                      {clientData?.user?.mobile}
                    </Descriptions.Item>

                    <Descriptions.Item label="Email">
                      {clientData?.user?.email}
                    </Descriptions.Item>

                    <Descriptions.Item label="Pan Number">
                      {clientData?.user?.panNumber}
                    </Descriptions.Item>

                    <Descriptions.Item label="Aadhaar Number">
                      {clientData?.user?.aadhaarId}
                    </Descriptions.Item>

                    <Descriptions.Item label="Designation">
                      {clientData?.user?.designation}
                    </Descriptions.Item>

                    <Descriptions.Item label="Pan Card">
                      {/* <Button
                      type="link"
                      onClick={() => {
                        showImage(clientData?.user.panURL);
                      }}
                      size="small"
                    >
                      {clientData?.user?.panFileId}
                    </Button> */}
                      <Space>
                        <Image
                          src={clientData?.user.panURL}
                          alt={clientData?.user?.panFileId}
                          width={30}
                          height={30}
                        />
                      </Space>
                    </Descriptions.Item>

                    <Descriptions.Item label="Aadhar Card">
                      {/* <Button
                      type="link"
                      onClick={() => {
                        showImage(clientData?.user.aadharURL);
                      }}
                      size="small"
                    >
                      {clientData?.user?.aadharFileId}
                    </Button> */}
                      <Space>
                        <Image
                          src={clientData?.user.aadharURL}
                          alt={clientData?.user?.aadharFileId}
                          width={30}
                          height={30}
                        />
                      </Space>
                    </Descriptions.Item>
                  </Descriptions>
                  <Image
                    preview={{
                      visible,
                      src: img,
                      onVisibleChange: (value) => {
                        setVisible(value);
                      },
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          {clientData?.type === "Organization" && (
            <Col span={24}>
              <Card
                title="Organization Details"
                bordered={false}
                style={{ marginBottom: "20px" }}
              >
                <Descriptions layout="horizontal" bordered size="small">
                  <Descriptions.Item label="Name">
                    {clientData?.organizationDetails?.companyName}
                  </Descriptions.Item>

                  <Descriptions.Item label="Contact">
                    {clientData?.organizationDetails?.companyContact}
                  </Descriptions.Item>

                  <Descriptions.Item label="Email">
                    {clientData?.organizationDetails?.companyEmail}
                  </Descriptions.Item>

                  <Descriptions.Item label="Address">
                    {clientData?.organizationDetails?.companyAddress}
                  </Descriptions.Item>

                  <Descriptions.Item label="Pincode">
                    {clientData?.organizationDetails?.companyPincode}
                  </Descriptions.Item>

                  <Descriptions.Item label="State">
                    {clientData?.organizationDetails?.companyState}
                  </Descriptions.Item>

                  <Descriptions.Item label="GSTIN">
                    {clientData?.organizationDetails?.gstNumber}
                  </Descriptions.Item>

                  {/* <Descriptions.Item label="CIN">
                    {clientData?.organizationDetails?.companyCin}
                  </Descriptions.Item> */}

                  <Descriptions.Item label="Pan Number">
                    {clientData?.organizationDetails?.companyPanNumber}
                  </Descriptions.Item>

                  <Descriptions.Item label="Website">
                    <a
                      href={clientData?.organizationDetails?.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {clientData?.organizationDetails?.website}
                    </a>
                  </Descriptions.Item>

                  {/* <Descriptions.Item label="CIN Image"> */}
                  {/* <Button
                    type="link"
                    onClick={() => {
                      showImage(clientData?.cinURL);
                    }}
                    size="small"
                  >
                    {clientData?.organizationDetails?.cinFileId}
                  </Button> */}
                  {/* <Space>
                      <Image
                        src={clientData?.cinURL}
                        alt={clientData?.organizationDetails?.cinFileId}
                        width={30}
                        height={30}
                        style={{ borderRadius: "15px" }}
                      />
                    </Space>
                  </Descriptions.Item> */}

                  <Descriptions.Item label="Pancard">
                    {/* <Button
                    type="link"
                    onClick={() => {
                      showImage(clientData?.companyPancardURL);
                    }}
                    size="small"
                  >
                    {clientData?.organizationDetails?.companyPanFileId}
                  </Button> */}
                    <Space>
                      <Image
                        src={
                          clientData?.companyPancardURL ||
                          "https://via.placeholder.com/150"
                        }
                        alt={clientData?.organizationDetails?.companyPanFileId}
                        width={30}
                        height={30}
                      />
                    </Space>
                  </Descriptions.Item>
                </Descriptions>
                <Image
                  preview={{
                    visible,
                    src: img,
                    onVisibleChange: (value) => {
                      setVisible(value);
                    },
                  }}
                />
              </Card>
            </Col>
          )}
          <Col span={24}>
            {clientData?.clientEmployees &&
              clientData.clientEmployees.length > 0 && (
                <Card
                  title="Client Employee"
                  bordered={false}
                  style={{ marginBottom: "20px" }}
                >
                  <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    bordered
                    size="small"
                  />
                </Card>
              )}
          </Col>
          <Col span={24}>
            <Card
              title="Booking Details"
              bordered={false}
              style={{ marginBottom: "20px" }}
            >
              <Descriptions layout="horizontal" bordered size="small">
                <Descriptions.Item label="Total Conference Hours">
                  {clientData?.clientBookings[0]?.totalConferenceHour}
                </Descriptions.Item>

                <Descriptions.Item label="Start Date">
                  {moment(clientData?.clientBookings[0]?.startDate).format(
                    "DD-MM-YYYY"
                  )}
                </Descriptions.Item>

                <Descriptions.Item label="Days">
                  {clientData?.clientBookings[0]?.durationDays}
                </Descriptions.Item>

                <Descriptions.Item label="Months">
                  {clientData?.clientBookings[0]?.durationMonths}
                </Descriptions.Item>

                <Descriptions.Item label="Year">
                  {clientData?.clientBookings[0]?.durationYears}
                </Descriptions.Item>

                {/* <Descriptions.Item label="Duration">
                {clientData?.clientBookings[0]?.durationDays
                  ? `${clientData.clientBookings[0].durationDays} days`
                  : clientData?.clientBookings[0]?.durationMonths
                  ? `${clientData.clientBookings[0].durationMonths} months`
                  : clientData?.clientBookings[0]?.durationYears
                  ? `${clientData.clientBookings[0].durationYears} years`
                  : "N/A"}
              </Descriptions.Item> */}

                <Descriptions.Item label="Is Deposit">
                  {clientData?.clientBookings[0]?.isDeposit}
                </Descriptions.Item>

                <Descriptions.Item label="Deposit Amount">
                  {clientData?.clientBookings[0]?.deposit}
                </Descriptions.Item>

                <Descriptions.Item label="Invoice Type">
                  {clientData?.clientBookings[0]?.invoiceType}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={24}>
            <Card
              title="Seats"
              bordered={false}
              style={{ marginBottom: "20px" }}
            >
              <Table
                columns={seatColumns}
                dataSource={seatDataSource}
                pagination={false}
                bordered
                size="small"
              />
            </Card>
          </Col>
          <Col span={24}>
            <Card title="Documents" bordered={false}>
              <Descriptions layout="vertical" bordered size="small">
                <Descriptions.Item label="Aadhaar Card">
                  <Image
                    src={clientData?.user?.aadharURL}
                    style={{ width: "100px", height: "auto" }}
                  />
                </Descriptions.Item>

                <Descriptions.Item label="Pan Card">
                  <Image
                    src={clientData?.user?.panURL}
                    style={{ width: "100px", height: "auto" }}
                  />
                </Descriptions.Item>
                {clientData?.type === "Organization" && (
                  <>
                    <Descriptions.Item label="Company Pan Card">
                      <Image
                        src={clientData?.companyPancardURL}
                        style={{ width: "100px", height: "auto" }}
                      />
                    </Descriptions.Item>
                  </>
                )}
              </Descriptions>
            </Card>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ClientDetails;
