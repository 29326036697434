import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Space,
  Input,
  Breadcrumb,
  message,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import APICall from "../../services/apiservices";
import { useAuth } from "../../auth/AuthProvider";

const { Search } = Input;
const { confirm } = Modal;

const EnquiryTable = ({ enquiries: initialEnquiries = [] }) => {
  const { userData } = useAuth();
  const [enquiries, setEnquiries] = useState(initialEnquiries);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchEnquiries();
  }, []);

  const fetchEnquiries = async () => {
    try {
      setLoading(true);
      const response = await APICall("/enquiry", {}, 1);
      const filteredEnquiries = response.data.documents.filter(
        (enquiry) => enquiry.clientId === userData._id
      );
      setEnquiries(filteredEnquiries);
    } catch (error) {
      console.error("Error fetching enquiries:", error);
      message.error("Failed to fetch enquiries. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (recordId) => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete this enquiry?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        try {
          await APICall(`/enquiry/${recordId}`, {}, 4);
          message.success("Enquiry deleted successfully!");
          const updatedEnquiry = enquiries.filter(
            (enquiry) => enquiry._id !== recordId
          );
          setEnquiries(updatedEnquiry);
        } catch (error) {
          message.error(
            error.response?.data?.message || "An error occurred while deleting."
          );
        }
      },
    });
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
    filters: {},
    sorter: {},
  });

  const onSearch = (value) => {
    setSearchTerm(value);
    setTableParams({
      ...tableParams,
      filters: {
        ...tableParams.filters,
        searchTerm: value,
      },
    });
  };

  const filteredEnquiries = enquiries.filter((enquiry) =>
    enquiry.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srNo",
      key: "srNo",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Number of Seats",
      dataIndex: "numberOfSeats",
      key: "numberOfSeats",
    },
    {
      title: "From Date",
      dataIndex: "fromDate",
      key: "fromDate",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space>
          <Link to={`/enquiry/manage/${record._id}`}>Edit</Link>
          <Button type="link" danger onClick={() => handleDelete(record._id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const breadcrumbItems = [
    <Breadcrumb.Item key="enquiry">
      <Link to="#">Enquiry</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <>
      <div
        type="inner"
        style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}
      >
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Card
        title="Enquiry List"
        style={{ marginBottom: 20 }}
        extra={
          <>
            <Space>
              <Search
                style={{
                  width: 220,
                }}
                placeholder="Search an Enquiry"
                allowClear
                enterButton
                // loading={loading}
                size="middle"
                onSearch={onSearch}
              />
              <Button type="primary" style={{ marginRight: 10 }}>
                <Link to="/enquiry/manage">
                  <PlusOutlined /> Create Enquiry
                </Link>
              </Button>
            </Space>
          </>
        }
      >
        <Table
          columns={columns}
          dataSource={filteredEnquiries}
          rowKey={(record) => record.id}
        />
      </Card>
    </>
  );
};

export default EnquiryTable;
