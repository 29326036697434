import axios from "axios";
// export const serverURL = `https://visually-sunny-squirrel.ngrok-free.app`;
export const serverURL = `https://api.cultiv8.dannynow.com`;
// export const serverURL = `http://localhost:3018`;

export const getAuthHeaders = () => {
  const accessToken = localStorage.getItem("accessToken");
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return { headers };
};

/**
 * Methods
 * 1: GET;
 * 2: POST;
 * 3: PATCH;
 * 4: DELETE;
 * 5: PUT
 */
const APICall = (url, data, method, authorization = true) => {
  return new Promise(async (resolve, reject) => {
    try {
      const finalURL = `${serverURL}${url}`;
      const headers = authorization ? getAuthHeaders() : {};
      let response;

      if (method === 1) {
        response = await axios.get(finalURL, headers);
      } else if (method === 2) {
        response = await axios.post(finalURL, data, headers);
      } else if (method === 3) {
        response = await axios.patch(finalURL, data, headers);
      } else if (method === 4) {
        response = await axios.delete(finalURL, headers);
      } else if (method === 5) {
        response = await axios.put(finalURL, data, headers);
      }
      return resolve(response);
    } catch (error) {
      // console.log(error);
      if (
        error.toString().includes("status code 401") &&
        localStorage.accessToken
      ) {
        // logoutUser().then(() => {
        //   window.location.href = "/./";
        // });
      }
      reject(error);
    }
  });
};
export default APICall;
