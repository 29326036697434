import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Col,
  Row,
  message,
  Spin,
  Radio,
} from "antd";
import { Link, resolvePath, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import APICall from "../../../services/apiservices";
import { Select } from "antd";
import axios from "axios";
import Item from "antd/es/list/Item";
import { DatePicker } from "antd";
const { Option } = Select;
const ManageSurvey = ({ isEdit }) => {
  const { surveyId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [form] = Form.useForm();

  const [oldCust, setOldCust] = useState();
  const [oldEmpl, setOldEmpl] = useState();
  const [oldType, setOldType] = useState();
  const [oldSNo, setOldSNo] = useState();
  const getSurveyDetails = async () => {
    try {
      const response = await APICall(`/survey/${surveyId}`, {}, 1);

      setOldCust(response?.data?.data?.customer?.user?.name);
      setOldEmpl(response?.data?.data?.employee?.user?.name);
      setOldType(response?.data?.data?.type);
      setOldSNo(response?.data?.data?.surveyNo);

      setIsLoading2(false);
    } catch (error) {
      message.error(error.response.data.message);
      setIsLoading2(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setIsLoading2(true);
      getSurveyDetails();
    }
  }, []);
  const params = useParams();

  const onFinish = async (formData) => {
    setIsLoading(!isLoading);

    if (isEdit) {
      const surveyNo = oldSNo;
      const customerId = formData.customer;
      const employeeId = formData.employee;
      const surveyDate = formData.date;
      const type = formData.type;
      const data = { surveyNo, customerId, employeeId, surveyDate, type };

      try {
        const response = await APICall(`/survey/${params.surveyId}`, data, 3);
        if (response.data) {
          setIsLoading(false);
          message.success("Survey has been updated successfully.");
        }
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    } else {
      try {
        console.log(formData);
        const customerId = formData.customer;
        const employeeId = formData.employee;
        const surveyDate = formData.date;
        const type = formData.type;
        const surveyNo = surveyNum;
        const data = { surveyNo, customerId, employeeId, type, surveyDate };
        const response = await APICall(`/survey`, data, 2);
        if (response.data) {
          setIsLoading(false);
          message.success("Survey has been created successfully.");
        }
      } catch (error) {
        setIsLoading(false);
        message.error(error.response.data.message);
      }
    }
  };

  const [empl, setEmpl] = useState([]);
  const [cust, setCust] = useState([]);
  const [surveyNum, setSurveyNum] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchEmpl = async (page, limit) => {
    try {
      const response = await APICall(
        `/employee?page=${page}&limit=${limit}`,
        {},
        1
      );
      setEmpl(response.data.documents);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCust = async (page, limit) => {
    try {
      const response = await APICall(
        `/customer?page=${page}&limit=${limit}`,
        {},
        1
      );
      setCust(response.data.documents);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSurveyNumber = async () => {
    try {
      const response = await APICall(`/survey/number`, {}, 1);
      setSurveyNum(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchCust(1, 10);
    fetchEmpl(1, 10);
    fetchSurveyNumber();
  }, []);

  return (
    <>
      {isLoading || isLoading2 ? (
        <Row
          justify="center"
          align="middle"
          style={{ position: "absolute", top: "50%", left: "50%" }}
        >
          <Spin tip={"Loading"} />
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <Card title={isEdit ? "Edit Survey" : "Create Survey"}></Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default ManageSurvey;
