import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Descriptions,
  Spin,
  Row,
  Col,
  Input,
  Form,
  DatePicker,
  Checkbox,
  Space,
} from "antd";
import { Link, useParams } from "react-router-dom";
import APICall from "../../../services/apiservices";
import { PDFViewer, Document } from "@react-pdf/renderer";
import moment from "moment";
import { PDFDocument, handleDownloadPDF } from "../../../pdfs/pdfService";
import Quotation from "../../../pdfs/quotation";
import AMC from "../../../pdfs/amc";
import OfferLetter from "../../../pdfs/offerLetter";
import dayjs from "dayjs";
const { useForm } = Form;

const OfferLetterDetails = () => {
  const { employeeId } = useParams();
  const [employeeDetails, setEmployeeDetails] = useState();
  const [form] = useForm();

  useEffect(() => {
    getEmployeeDetails();
  }, []);

  const getEmployeeDetails = async () => {
    const response = await APICall(`/employee/${employeeId}`, {}, 1);
    const employee = response.data;

    const employeeData = {
      date: moment().format("DD/MM/YYYY"),
      employee: {
        name: employee?.user?.name,
        address: employee?.user?.address,
        joiningDate: moment(employee?.joiningDate).format("DD/MM/YYYY"),
        salary: employee?.salary,
        designation: employee?.designation,
      },
      allData: employee,
    };

    setEmployeeDetails(employeeData);
  };

  return (
    <Card
      title="Offer Letter"
      // extra={
      //     amcDetails && <Button type="primary" onClick={() => handleDownloadPDF({
      //         pages: [
      //             <AMC {...amcDetails}/>
      //         ],
      //         fileName: 'AMC'
      //     })}>Download</Button>
      // }
    >
      <Form
        form={form}
        // layout="vertical"
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="date" label="Date">
              <DatePicker
                placeholder={moment().format("YYYY-MM-DD")}
                defaultValue={dayjs()}
                style={{ width: "100%" }}
                tabIndex={2}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="includeLetterheadHeader" valuePropName="checked">
              <Checkbox>Print With Letterhead header</Checkbox>
            </Form.Item>
          </Col>

          {/* <Col span={8}>
                        <Form.Item
                            name='includeLetterheadFooter'
                            valuePropName="checked"
                        >
                            <Checkbox>Print With Letterhead footer</Checkbox>
                        </Form.Item>
                    </Col> */}

          <Col span={8}>
            <Form.Item>
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    const formData = form.getFieldsValue();
                    const tempEmployeeDetails = { ...employeeDetails };
                    formData.date &&
                      (tempEmployeeDetails.date = moment(
                        new Date(formData.date)
                      ).format("DD/MM/YYYY"));
                    formData.includeLetterheadHeader
                      ? (tempEmployeeDetails.letterheader =
                          tempEmployeeDetails.allData.letterhead.header)
                      : (tempEmployeeDetails.letterheader = undefined);
                    // formData.includeLetterheadFooter ? tempEmployeeDetails.letterfooter = tempEmployeeDetails.allData.letterhead.footer : tempEmployeeDetails.letterfooter = undefined
                    setEmployeeDetails({ ...tempEmployeeDetails });
                  }}
                >
                  Create
                </Button>
                {employeeDetails && (
                  <Button
                    type="primary"
                    onClick={() =>
                      handleDownloadPDF({
                        pages: [<OfferLetter {...employeeDetails} />],
                        fileName: "OfferLetter",
                      })
                    }
                  >
                    Download
                  </Button>
                )}
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {employeeDetails ? (
        <PDFViewer width={"100%"} height={1200}>
          <PDFDocument pages={[<OfferLetter {...employeeDetails} />]} />
        </PDFViewer>
      ) : (
        <Spin />
      )}
    </Card>
  );
};

export default OfferLetterDetails;
