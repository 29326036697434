import React, { useEffect, useState } from "react";
import {
    Card,
    Col,
    Row,
    Form,
    Space,
    Statistic,
    Table,
    Typography,
    Descriptions,
    Image,
    Button,
    message,
    Progress,
    Input,
    Modal,
    Select,
} from "antd";
import { useGeolocated } from "react-geolocated";

const { useForm } = Form;

const ConductSuvey = () => {

    const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        userDecisionTimeout: null,
    });

    const [form] = useForm();
    const [wingForm] = useForm();
    const [areaForm] = useForm();
    
    const [wings, setWings] = useState([])
    const [wingModal, setWingModal] = useState(false)
    const [areaModal, setAreaModal] = useState({open: false, wingName: ''})

    const onWingFormFinish = (formdata) => {
        if(wings.find(wing => wing.name === formdata.name)) {
            message.warning('Already added a wing with similar name')
        }else {
            setWings([...wings, {...formdata, areas: []}])
            message.info(`Added new wing: ${formdata.name}`)
        }
    }

    const onAreaFormFinish = (formdata) => {
        const tempWings = [...wings]
        tempWings.map(wing => {
            if(wing.name === areaModal.wingName) {
                if(wing.areas.find(area => area.name === formdata.name)) {
                    message.warning('Already added an area with similar name')
                }else {
                    wing.areas.push({name: formdata.name, products: []})
                    message.info(`Added new wing: ${formdata.name}`)
                }
            }
        })
        setWings(tempWings)
    }

    const equipments = [
        {
            name: "ADAPTOR",
            status: 'NA'
        },
        {
            name: "Hose Box",
            status: 'NA'
        },
        {
            name: "Branch Pipe",
            status: 'NA'
        },
        {
            name: "Hose Pipe",
            status: 'NA'
        },
        {
            name: "Hydrant Valve",
            status: 'NA'
        },
        {
            name: "Hose Reel",
            status: 'NA'
        },
        {
            name: "Fire Extinguishers",
            status: 'NA'
        },
        {
            name: "Hose Box Glass",
            status: 'NA'
        },
        {
            name: "Key Glass",
            status: 'NA'
        },
        {
            name: "Hydrant valve Wheel",
            status: 'NA'
        },
        {
            name: "Hose Reel Ball Valve",
            status: 'NA'
        },
    ]

    return (
        <Row className="scroll-container">
            <Col span={24}>
                <Form form={form} onFinish={(formdata) => console.log(formdata)}>
                    <Card 
                        title={"Conduct Survey"}
                        extra={
                            <Space>
                                <Button type='primary' ghost onClick={() => setWingModal(true)}>
                                    Add Wing
                                </Button>
                                <Button type='primary' htmlType="submit">
                                    Save
                                </Button>
                            </Space>
                        }
                    >
                        <Form.List name="wings">
                            {(fields, { add, remove }) => (
                                <Space direction='vertical' style={{ display: 'flex',}} size={'middle'}>
                                    {
                                        wings.length === 0 ? <>No wings added. Please add wings.</> : 
                                        wings.map(wing => (
                                            <Card 
                                                type="inner" 
                                                title={wing.name}
                                                extra={
                                                    <Button type='primary' ghost onClick={() => setAreaModal({open: true, wingName: wing.name})}>
                                                        Add Area
                                                    </Button>
                                                }
                                            >
                                                <Form.List name={wing}>
                                                    {(fields, { add, remove }) => (
                                                            <Space direction='vertical' style={{ display: 'flex',}} size={'middle'}>
                                                                {
                                                                    wing.areas.length == 0 ? <>No Areas Added. Please add area.</> :
                                                                    wing.areas.map(area => (
                                                                        <Card type="inner" title={area.name}>
                                                                            <Form>
                                                                                <Form.Item name={''}>
                                                                                    <Select
                                                                                        placeholder="Select option"
                                                                                        allowClear
                                                                                    >
                                                                                        <Select.Option value="NA">NA</Select.Option>
                                                                                        <Select.Option value="OK">OK</Select.Option>
                                                                                        <Select.Option value="NOT OK">Not OK</Select.Option>
                                                                                    </Select>
                                                                                </Form.Item>
                                                                            </Form>
                                                                        </Card>
                                                                    ))
                                                                }
                                                            </Space>
                                                        )
                                                    }
                                                </Form.List>
                                            </Card>
                                        ))
                                    }
                                </Space>
                            )}
                        </Form.List>
                    </Card>
                </Form>
            </Col>
            <Modal
                title="Add Wing"
                open={wingModal}
                okText={"Add Wing"}
                centered
                onCancel={() => {
                    setWingModal(false);
                    wingForm.resetFields();
                }}
                onOk={wingForm.submit}
            >
                <Form form={wingForm} layout="vertical" onFinish={onWingFormFinish}>
                    <Form.Item 
                        name={"name"} 
                        label="Name of the Wing"
                        rules={[
                            {
                                required: true,
                                message: "Please enter name of the wing",
                            },
                        ]}
                    >
                        <Input placeholder="e.g. Wing A, Capital B, etc"/>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title={`Add Area in ${areaModal.wingName}`}
                open={areaModal.open}
                okText={"Add Area"}
                centered
                onCancel={() => {
                    setAreaModal({open: false, wingName: ''});
                    areaForm.resetFields();
                }}
                onOk={areaForm.submit}
            >
                <Form form={areaForm} layout="vertical" onFinish={onAreaFormFinish}>
                    <Form.Item 
                        name={"name"} 
                        label="Name of the Area"
                        rules={[
                            {
                                required: true,
                                message: "Please enter name of the wing",
                            },
                        ]}
                    >
                        <Input placeholder="e.g. Floor No 1, Basement Front, etc"/>
                    </Form.Item>
                </Form>
            </Modal>
        </Row>
    )
}

export default ConductSuvey;