import React, { useEffect, useState } from "react";
import { LiaExternalLinkAltSolid } from "react-icons/lia";
import {
  BiLogoWhatsapp,
  BiLogoInstagram,
  BiLogoFacebookCircle,
  BiLogoTwitter,
} from "react-icons/bi";
import { Card, Col, Descriptions, Row, message, Button, Space } from "antd";
import APICall from "../../services/apiservices";

const Contact = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState();
  const getCompanyDetails = async () => {
    try {
      const res = await APICall(`/company/649bff0f87a35d7bececa3d8`, {}, 1);
      setCompanyDetails(res.data);
      setIsLoading(false);
    } catch (error) {
      message.error(error);
    }
  };

  useEffect(() => {
    getCompanyDetails();
  }, []);
  return (
    <Row>
      <Col span={24}>
        <Card title="Contact Details" loading={isLoading}>
          <Descriptions bordered layout="vertical">
            <Descriptions.Item label="Company">
              {companyDetails?.name}
            </Descriptions.Item>
            <Descriptions.Item label="Mobile">
              <a href={`tel:${companyDetails?.mobile}`}>
                {companyDetails?.mobile}
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              <a href={`mailto:${companyDetails?.email}`}>
                {companyDetails?.email}
              </a>
            </Descriptions.Item>

            <Descriptions.Item label="Website">
              <a href={`${companyDetails?.website}`}>
                <Button type="link" style={{ padding: 0 }}>
                  {companyDetails?.website}
                </Button>
                <LiaExternalLinkAltSolid />
              </a>
            </Descriptions.Item>
            <Descriptions.Item label="Address" span={2}>
              <a
                href={`https://www.google.com/maps/search/${
                  companyDetails?.address.split(",")[1]
                }`}
                target="_blank"
              >
                {companyDetails?.address}
              </a>
            </Descriptions.Item>
            <Descriptions.Item span={2} label="Social Media">
              <Space>
                <a
                  href={`https://api.whatsapp.com/send?phone=91${companyDetails?.mobile}`}
                  target="_blank"
                >
                  <Button
                    type="primary"
                    icon={<BiLogoWhatsapp size={22} />}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Start WhatsApp Chat
                  </Button>
                </a>
                <a
                  href={`https://www.instagram.com/firefightsafetysolutions/?igshid=MWZjMTM2ODFkZ`}
                  target="_blank"
                >
                  <Button
                    type="primary"
                    icon={<BiLogoInstagram size={22} />}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Follow On Instagram
                  </Button>
                </a>
                <a
                  href={`https://www.facebook.com/people/Fire-Fight-Safety-Solutions/100064183660263`}
                  target="_blank"
                >
                  <Button
                    type="primary"
                    icon={<BiLogoFacebookCircle size={22} />}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Follow On Facebook
                  </Button>
                </a>
                <a href={`https://x.com/FightSafety?s=20`} target="_blank">
                  <Button
                    type="primary"
                    icon={<BiLogoTwitter size={22} />}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    Follow On Twitter
                  </Button>
                </a>
              </Space>
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Col>
    </Row>
  );
};

export default Contact;
