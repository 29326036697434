import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Modal,
  Spin,
  Form,
  Row,
  Col,
  DatePicker,
  Space,
  message,
  Breadcrumb,
} from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import APICall from "../../../services/apiservices";
import { PDFViewer, Document } from "@react-pdf/renderer";
import moment from "moment";
import { PDFDocument, handleDownloadPDF } from "../../../pdfs/pdfService";
import Quotation from "../../../pdfs/quotation";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
const { confirm } = Modal;

const QuoatationDetails = () => {
  const { quotationId } = useParams();
  const [quoatationDetails, setQuoatationDetails] = useState();
  const [form] = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    getQuotationDetails();
  }, []);

  const handleDelete = async (record) => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete quotation?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        // Delete logic goes here
        const res = await APICall(`/quotation/${quotationId}`, {}, 4);
        // getQuotations(0, 10);
        message.success("Quotation deleted successfully!");
        navigate("/quotations");
      },
      onCancel: () => {
        message.info("Delete canceled");
      },
    });
  };

  const getQuotationDetails = async () => {
    const response = await APICall(`/quotation/${quotationId}`, {}, 1);
    const quotation = response.data.quotation;
    console.log(quotation, "quotation");
    form.setFieldValue("date", dayjs(quotation.date));

    const quotationData = {
      ...quotation,
      letterheader: "/quotation_header.png",
      allData: quotation,
      customer: {
        name: quotation.customer.user.name,
        address: quotation.customer.user.address,
        discount: quotation.customer.discount,
        discountType: quotation.customer.discountType,
      },
      totalAmountWords: withDecimal(quotation.totalAmount),
      date: moment(quotation.date).format("DD/MM/YYYY"),
      quoatationNo: `QUOTE/${
        new Date().getFullYear().toString().slice(-2) +
        "-" +
        (new Date().getFullYear() + 1).toString().slice(-2)
      }/${(quotation?.quotationNo).toString().padStart(4, "0")}`,
    };

    const data = response.data.items.map((i) => {
      return {
        image:
          i.item.photoURL ||
          "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/1024px-No_image_available.svg.png",
        name: i.item.name,
        hsnCode: i.item.hsn,
        description: i.item.description,
        amount: i.price,
        quantity: i.quantity,
        totalAmount: i.price * i.quantity,
      };
    });

    quotationData.data = data;
    console.log(quotationData, "quotationData");
    setQuoatationDetails(quotationData);
  };

  function convertNumberToWords(amount) {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      let value = "";
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return words_string;
  }

  function withDecimal(n) {
    var nums = n.toString().split(".");
    var whole = convertNumberToWords(nums[0]) + "Rupees ";
    if (nums.length == 2) {
      var fraction = convertNumberToWords(nums[1]);
      return whole + "and " + fraction + "Paise";
    } else {
      return whole;
    }
  }

  const breadcrumbItems = [
    <Breadcrumb.Item key="quotations">
      <Link to="/quotations">Invoice</Link>
    </Breadcrumb.Item>,

    <Breadcrumb.Item key="download">
      <Link to="#">Download</Link>
    </Breadcrumb.Item>,
  ];

  return (
    <>
      <div
        type="inner"
        style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}
      >
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Card title="Invoices">
        <Form
          form={form}
          // layout="vertical"
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item name="date" label="Date">
                <DatePicker
                  placeholder={moment().format("YYYY-MM-DD")}
                  style={{ width: "100%" }}
                  tabIndex={1}
                />
              </Form.Item>
            </Col>

            {/* <Col span={6}>
                        <Form.Item
                            name="place"
                            label="Place"
                        >
                            <Input placeholder="e.g. Pune" tabIndex={2}/>
                        </Form.Item>
                    </Col> */}

            {/* <Col span={6}>
            <Form.Item name="includeLetterheadHeader" valuePropName="checked">
              <Checkbox>Print With Letterhead header</Checkbox>
            </Form.Item>
          </Col> */}

            <Col span={6}>
              <Form.Item>
                <Space>
                  {/* <Button
                  type="primary"
                  onClick={() => {
                    const formData = form.getFieldsValue();
                    const tempQuotationDetails = { ...quoatationDetails };
                    formData.date &&
                      (tempQuotationDetails.date = moment(
                        new Date(formData.date)
                      ).format("DD/MM/YYYY"));
                    formData.includeLetterheadHeader
                      ? (tempQuotationDetails.letterheader =
                          tempQuotationDetails.allData.letterhead.header)
                      : (tempQuotationDetails.letterheader = undefined);
                    // formData.includeLetterheadFooter ? tempQuotationDetails.letterfooter = tempQuotationDetails.allData.letterhead.footer : tempQuotationDetails.letterfooter = undefined
                    setQuoatationDetails({ ...tempQuotationDetails });
                  }}
                  tabIndex={4}
                >
                  
                </Button> */}
                  {quoatationDetails && (
                    <Button
                      type="primary"
                      onClick={() =>
                        handleDownloadPDF({
                          pages: [<Quotation {...quoatationDetails} />],
                          fileName: `${quoatationDetails.customer.name}-Quotation-${quoatationDetails.quoatationNo}`,
                        })
                      }
                    >
                      Download
                    </Button>
                  )}
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {quoatationDetails ? (
          <PDFViewer width={"100%"} height={1200}>
            <PDFDocument pages={[<Quotation {...quoatationDetails} />]} />
          </PDFViewer>
        ) : (
          <Spin />
        )}
      </Card>
    </>
  );
};

export default QuoatationDetails;
