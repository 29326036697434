import {
  DatePicker,
  Form,
  Input,
  Button,
  Row,
  Col,
  Card,
  message,
  Dropdown,
  Table,
  Modal,
  Space,
  Popconfirm,
  Upload,
  Breadcrumb,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import DebounceSelect from "../../../components/debounceSelect/DebounceSelect";
import TextArea from "antd/es/input/TextArea";
import APICall from "../../../services/apiservices";
import { useForm } from "antd/es/form/Form";
import { Select } from "antd";
import { LuPlus } from "react-icons/lu";
import axios from "axios";
import dayjs from "dayjs";
import { DownOutlined } from "@ant-design/icons";
import create from "@ant-design/icons/lib/components/IconFont";

const { confirm } = Modal;
const { Item } = Form;
const { Option } = Select;

const ManageQuoatation = ({}) => {
  const [quotationData, setQuotationData] = useState();
  const [form] = Form.useForm();
  const [itemForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const [discountType, setDiscountType] = useState("%");
  const [discountValue, setDiscountValue] = useState(0);
  const [price, setPrice] = useState("");
  const [gstPrice, setGstPrice] = useState("");
  const [itemData, setItemData] = useState(null);
  const [cgst, setCgst] = useState(0);
  const [sgst, setSgst] = useState(0);
  const [isEditSeatModalVisible, setIsEditSeatModalVisible] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [discountedAmount, setDiscountedAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [originalItems, setOriginalItems] = useState([]);
  const [editingRecordIndex, setEditingRecordIndex] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [value, setValue] = useState(undefined);
  const [editingRecordSeat, setEditingRecordSeat] = useState(null);
  const [itemValue, setItemValue] = useState(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [defaultDate, setDefaultDate] = useState(moment());
  const [editSeatForm] = Form.useForm();
  const [seatFileId, setSeatFileId] = useState(null);
  const [fileName, setFileName] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [tableParams, setTableParams] = useState({
    filters: {
      searchTerm: "",
    },
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
    },
  });

  const [selectedDate, setSelectedDate] = useState(moment());
  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date); // Update the selected date
    }
  };

  const validatePositiveNumber = (rule, value) => {
    if (value < 0) {
      return Promise.reject("Value must be a non-negative number");
    }
    return Promise.resolve();
  };

  const disabledDate = (current) => {
    const today = moment().startOf("day");
    return current && current < today;
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);

    const formData = itemForm.getFieldsValue();

    itemForm.resetFields();
    const addSeatData = {
      ...formData.item,
      price: formData.price,
      quantity: formData.quantity,
      totalPrice: formData.price * formData.quantity,
      itemId: formData._id,
    };
    console.log(addSeatData, "addSeatData");
    setItemValue(undefined);
    // if (selectedItems?.find((i) => i._id === formData._id)) {
    //   message.warning("Item already added to the invoice table");
    //   setIsLoading(false);
    //   return;
    // }
    const itemsSelected = [...selectedItems];
    setSelectedItems([...itemsSelected, addSeatData]);
    changeNumerics({ itemsSelected: [...itemsSelected, addSeatData] });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getQuotationDetails = async () => {
    try {
      const response = await APICall(`/quotation/${params.id}`, {}, 1);
      console.log(response, "555");
      setQuotationData(response.data);
      setIsLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  const dueDate = moment().add(6, "days");

  useEffect(() => {
    form.setFieldsValue({
      date: selectedDate,
      dueDate: dueDate,
    });
  }, [form, selectedDate, dueDate]);

  useEffect(() => {
    if (params.id) {
      setIsEdit(true);
      setIsLoading(true);
      getQuotationDetails();
    }
  }, [params.id]);

  useEffect(() => {
    if (quotationData) {
      form.setFieldsValue({
        customerId: {
          value: quotationData?.quotation?.customer?.user?._id,
          label: quotationData?.quotation?.customer?.user?.name,
        },
        date: dayjs(quotationData?.quotation?.date),
        subject: quotationData?.quotation?.subject,
        discount: quotationData?.quotation?.discount,
        discountType: quotationData?.quotation?.discountType,
        sgst: quotationData?.quotation?.sgst,
        cgst: quotationData?.quotation?.cgst,
        dueDate: dayjs(quotationData?.quotation?.dueDate),
      });
      setDiscountValue(quotationData?.quotation?.discount);
      setDiscountType(quotationData?.quotation?.discountType || "%");

      setSelectedItems(
        quotationData?.items.map((item) => ({
          ...item,
          clientName: quotationData?.quotation?.customer?.user?.name,
          dueDate: dayjs(quotationData?.quotation?.dueDate),
        }))
      );
      changeNumerics({ itemsSelected: quotationData?.items });
    }
  }, [quotationData]);

  const onFinish = async (values) => {
    if (selectedItems.length === 0) {
      message.error("Please add seat");
      return;
    }
    setIsLoading(true);
    const data = { ...values };
    // data.discount = data.discount || 0;
    // data.discountType = discountType || "%";
    data.cgst = parseInt(data.cgst) || 0;
    data.sgst = parseInt(data.sgst) || 0;
    // data.discountAmount = discount;
    data.cgstAmount = cgst;
    data.sgstAmount = sgst;
    data.amount = amount;
    data.totalAmount = Math.round(totalAmount);
    data.items = [...selectedItems];
    // data.discount = discountValue;

    try {
      if (isEdit) {
        console.log(data, "upd");
        const response = await APICall(`/quotation/${params.id}`, data, 3);
        if (response.data) {
          message.success("Invoice has been updated successfully.");
          navigate("/quotations");
        }
      } else {
        const response = await APICall(`/quotation`, data, 2);
        if (response.data) {
          message.success("Invoice has been created successfully.");
          navigate(`/quotations/${response.data.data._id}`);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      message.error(error.response.data.message);
    }
  };

  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);

  const fetchCustomerList = async (searchValue) => {
    const res = await APICall(
      `/client?page=1&limit=30&query=${searchValue}&isActive=true`,
      {},
      1
    );

    const filteredCustomers = res.data.documents.filter((customer) => {
      return customer.clientBookingDetails?.invoiceType === "withGST";
    });

    return filteredCustomers.map((customer) => ({
      label: customer.user.name,
      data: customer,
      value: customer._id,
    }));
  };

  const fetchItemList = async (searchValue) => {
    const res = await APICall(
      `/seat?page=1&limit=30&name=${searchValue}`,
      {},
      1
    );
    console.log(res, "66");
    return res.data.documents.map((item) => ({
      label: `${item.name}`,
      value: item._id,
      data: item,
    }));
  };

  const handleSeatUpload = async (file) => {
    const fileData = {
      requirement: [
        {
          order: 0,
          extension: file.name.substring(file.name.lastIndexOf(".") + 1),
        },
      ],
      module: "Seat",
    };
    try {
      const fileResponse = await APICall("/file", fileData, 2);
      await axios.put(fileResponse.data.data[0].url, file);
      setSeatFileId(fileResponse.data.data[0].id);
      setFileName(file.name);
      setImageUrl(fileResponse.data.data[0].url);
      message.success("Image uploaded successfully!");
    } catch (error) {
      message.error("Failed to upload seat image");
    }

    return false;
  };

  const handleDelete = async (_id, index) => {
    confirm({
      title: "Confirm Delete",
      content: `Are you sure you want to delete this seat?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      // onOk: async () => {
      //   try {
      //     await APICall(`/seat/${_id}`, {}, 4);
      //     message.success("Item deleted successfully!");
      //     // Update the table after deleting an item
      //     getItems();
      //   } catch (error) {
      //     message.error(error.response.data.message);
      //   }
      // },
      // onCancel: () => {
      //   message.info("Delete canceled");
      // },
      onOk: () => {
        setSelectedItems((prevItems) => {
          const newItems = [...prevItems];
          newItems.splice(index, 1); // Remove the item at the specific index
          return newItems;
        });
        message.success("Removed seat!");
      },
      onCancel: () => {
        // message.info("Delete canceled");
      },
    });
  };

  useEffect(() => {
    if (editingRecordSeat) {
      setFileName(editingRecordSeat.photoFileId || "");
      setSeatFileId(editingRecordSeat.photoFileId || "");
    }
  }, [editingRecordSeat]);

  const handleEditSeat = (record, index) => {
    console.log(record, "555");
    setEditingRecordSeat(record);
    setEditingRecordIndex(index);
    setIsEditSeatModalVisible(true);
    editSeatForm.setFieldsValue(record);
    setFileName(record.photoFileId || "");
  };

  const onEditOkSeat = (values) => {
    handleEditOkSeat(values);
  };

  const handleEditOkSeat = (values) => {
    console.log(values, "56");
    const price = values.price;
    const totalPrice = values.price * values.quantity;
    setSelectedItems((prevSeats) =>
      prevSeats.map((seat, index) =>
        index === editingRecordIndex
          ? { ...seat, ...values, price, totalPrice, photoFileId: seatFileId }
          : seat
      )
    );
    setIsEditSeatModalVisible(false);
    message.success("Seat updated successfully!");
    changeNumerics({ itemsSelected: selectedItems });
  };

  const getItems = async (searchTerm, page, limit) => {
    try {
      const response = await APICall("/seat", {}, 1);
      setItems(response.data.documents);
      setOriginalItems(response.data.documents);
      setIsLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    changeNumerics({
      itemsSelected: selectedItems,
      cgst: 9, // 9% CGST
      sgst: 9,
    });
  }, [selectedItems]);

  useEffect(() => {
    console.log(selectedItems);
    getItems();
  }, [selectedItems]);

  const getItemDetails = async () => {
    try {
      const response = await APICall(`/seat/${params.id}`, {}, 1);
      setItemData(response.data); // Set the current edited item's details
      // setImg(response.data.photoURL);
      setIsEdit(true);
      setIsLoading(false);
    } catch (error) {
      message.error(error.response.data.message);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "Sr. No.",
      key: "srNo",
      render: (_, record, index) => {
        return (
          tableParams.pagination.pageSize *
            (tableParams.pagination.current - 1) +
          index +
          1
        );
      },
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return record?.item?.name || record?.name;
      },
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (_, record) => {
        return record?.item?.description || record?.description;
      },
      width: "25%",
    },
    {
      title: "Price (₹)",
      dataIndex: "price",
      render: (_, record) => {
        return <>₹{record.price}</>;
      },
      width: "10%",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      render: (_, record) => {
        return <>{record.quantity}</>;
      },
      width: "10%",
    },
    {
      title: "Total Price (₹)",
      dataIndex: "totalPrice",
      render: (_, record) => {
        return <>₹{record.price * record.quantity}</>;
      },
      width: "10%",
    },
    {
      title: "Actions",
      key: "actions",
      width: "10%",
      align: "center",
      fixed: "right",
      render: (_, record, index) => (
        <Space>
          <Link>
            <Button
              key="edit"
              onClick={() => handleEditSeat(record, index)}
              type="link"
              icon={<EditOutlined />}
            />
          </Link>
          <Button
            type="link"
            danger
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record._id, index)}
          />
        </Space>
      ),
    },
  ];

  // const changeNumerics = ({ itemsSelected }) => {
  //   const items = itemsSelected || selectedItems || [];
  //   let amt = items
  //     ? items.reduce(
  //         (total, item) =>
  //           total + parseInt(item.price) * parseInt(item.quantity) || 0,
  //         0
  //       )
  //     : selectedItems.reduce(
  //         (total, item) =>
  //           total + parseInt(item.price) * parseInt(item.quantity) || 0,
  //         0
  //       );
  //   console.log(amt, "amt");
  //   const formData = form.getFieldsValue();

  //   let dis = parseInt(formData.discount) || 0;
  //   let disPrice = dis * amt * 0.01;

  //   let cgs = parseInt(formData.cgst) || 0;
  //   let cgsPrice = 0;

  //   let sgs = parseInt(formData.sgst) || 0;
  //   let sgsPrice = 0;

  //   let discountedPrice = amt - disPrice;
  //   sgsPrice = sgs * discountedPrice * 0.01;
  //   cgsPrice = cgs * discountedPrice * 0.01;
  //   let totalAmt = discountedPrice + cgsPrice + sgsPrice;

  //   setDiscount(parseFloat(disPrice).toFixed(2));
  //   setDiscountedAmount(parseFloat(discountedPrice).toFixed(2));
  //   setCgst(parseFloat(cgsPrice).toFixed(2));
  //   setSgst(parseFloat(sgsPrice).toFixed(2));
  //   setAmount(parseFloat(amt).toFixed(2));
  //   setTotalAmount(Math.round(parseFloat(totalAmt).toFixed(2)));
  // };

  // this is my code
  // const changeNumerics = ({ itemsSelected = [] }) => {
  //   // Numeric calculations for discount, cgst, sgst, total amount
  //   let discount = 0;
  //   let cgst = 0;
  //   let sgst = 0;
  //   let amount = 0;
  //   itemsSelected.forEach((item) => {
  //     amount += item.quantity * item.price;
  //   });
  //   if (form.getFieldValue("discount")) {
  //     discount = (amount * form.getFieldValue("discount")) / 100;
  //   }
  //   if (form.getFieldValue("cgst")) {
  //     cgst = ((amount - discount) * form.getFieldValue("cgst")) / 100;
  //   }
  //   if (form.getFieldValue("sgst")) {
  //     sgst = ((amount - discount) * form.getFieldValue("sgst")) / 100;
  //   }
  //   const totalAmount = amount - discount + cgst + sgst;
  //   setDiscount(discount);
  //   setCgst(cgst);
  //   setSgst(sgst);
  //   setAmount(amount);
  //   setTotalAmount(totalAmount);
  // };

  const changeNumerics = ({
    itemsSelected = selectedItems,
    cgst = 9,
    sgst = 9,
    updatedDiscount = null,
    // discountType = "%", // default to percentage
  }) => {
    // Calculate total amount of items selected
    let amt = itemsSelected.reduce(
      (total, item) =>
        total + parseFloat(item.price || 0) * parseInt(item.quantity || 1),
      0
    );

    const formData = form.getFieldsValue();
    const discount =
      updatedDiscount !== null
        ? parseFloat(updatedDiscount)
        : parseFloat(formData.discount || 0);

    // Calculate discount based on selected discount type
    let disPrice;
    if (discountType === "%") {
      disPrice = (amt * discount) / 100; // discount as percentage
    } else if (discountType === "₹") {
      disPrice = discount; // discount as fixed rupee amount
    }
    console.log("Discount Type:", discountType);
    console.log("Discount Value:", discount); // This should log 2 if that's the correct value.
    console.log("Discounted Price:", disPrice);
    // Calculate discounted amount after applying discount
    const discountedAmount = amt - disPrice;

    // Ensure CGST and SGST are percentages
    const validCgst = cgst / 100;
    const validSgst = sgst / 100;

    // Calculate CGST and SGST based on the discounted amount
    const cgsPrice = discountedAmount * validCgst;
    const sgsPrice = discountedAmount * validSgst;

    // Calculate total amount including taxes
    const totalAmt = discountedAmount + cgsPrice + sgsPrice;

    // Update state values
    setAmount(amt.toFixed(2));
    setDiscount(disPrice.toFixed(2));
    setDiscountedAmount(discountedAmount.toFixed(2));
    setCgst(cgsPrice.toFixed(2));
    setSgst(sgsPrice.toFixed(2));
    setTotalAmount(totalAmt.toFixed(2));
  };

  const validatePercentage = (_, value) => {
    if (!value) return Promise.resolve();

    const percentageRegex = /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/;

    if (!percentageRegex.test(value)) {
      return Promise.reject("Please enter a valid percentage");
    }
    return Promise.resolve();
  };

  const breadcrumbItems = isEdit ? (
    <>
      <Breadcrumb.Item key="quotations">
        <Link to="/quotations">Invoice</Link>
      </Breadcrumb.Item>

      <Breadcrumb.Item key="Form">
        <Link to="#">Edit</Link>
      </Breadcrumb.Item>
    </>
  ) : (
    <>
      <Breadcrumb.Item key="quotations">
        <Link to="/quotations">Invoice</Link>
      </Breadcrumb.Item>
      <Breadcrumb.Item key="Form">
        <Link to="#">Create</Link>
      </Breadcrumb.Item>
    </>
  );

  return (
    <>
      <div
        type="inner"
        style={{ marginTop: "-10px", padding: "0px 0px 15px 5px" }}
      >
        <Breadcrumb separator=">">{breadcrumbItems}</Breadcrumb>
      </div>
      <Row gutter={10}>
        <Col span={24}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={() =>
              changeNumerics({ itemsSelected: selectedItems })
            }
            initialValues={{
              termsAndConditions: `Billing For Accessories will be after actual usage
          Delivery and Installation charges will be borne by us
          Free Demo And Training
          Payment Terms: 100% Advance`,
              discount: 0,
            }}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Card
                  title={isEdit ? "Edit Invoice" : "Create Invoice"}
                  bordered={false}
                >
                  <Row gutter={10}>
                    <Col span={8}>
                      <Form.Item label="Date" name="date">
                        <DatePicker
                          value={selectedDate}
                          onChange={handleDateChange}
                          // placeholder={selectedDate.format("DD-MM-YYYY")}
                          format="DD-MM-YYYY"
                          style={{ width: "100%" }}
                          tabIndex={3}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        name="customerId"
                        label="Select Client"
                        rules={[
                          {
                            required: true,
                            message: "Please select the Client",
                          },
                        ]}
                      >
                        <DebounceSelect
                          value={value}
                          placeholder="John Doe"
                          fetchOptions={fetchCustomerList}
                          onChange={(newValue, data) => {
                            setValue(newValue);
                            console.log(data, "666");
                            const seatsWithQuantity =
                              data?.data?.clientBookingDetails?.seats.map(
                                (seat) => ({
                                  ...seat,
                                  // quantity: 1,
                                })
                              );
                            setSelectedItems(seatsWithQuantity);
                            changeNumerics({
                              itemsSelected: seatsWithQuantity,
                            });
                          }}
                          style={{ width: "100%" }}
                          tabIndex={2}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Item
                        label="Due Date"
                        name="dueDate"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Due date",
                          },
                        ]}
                      >
                        <DatePicker
                          // disabled
                          // disabledDate={disabledDate}
                          placeholder="16/07/2023"
                          format="DD-MM-YYYY"
                          style={{ width: "100%" }}
                          tabIndex={3}
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    {/* <Col span={8}>
                      {" "}
                      <Item
                        label="Discount (%)"
                        name="discount"
                        rules={[
                          {
                            validator: validatePercentage,
                          },
                        ]}
                      >
                        <Input
                          placeholder="10"
                          min={0}
                          // type="number"
                          onChange={(e) => {
                            const discountValue =
                              parseFloat(e.target.value) || 0;
                            changeNumerics({
                              itemsSelected: selectedItems,
                              updatedDiscount: discountValue,
                              cgst: 9, // Ensure CGST is set to 9%
                              sgst: 9, // Ensure SGST is set to 9%
                            });
                          }}
                          tabIndex={4}
                        />
                      </Item>
                    </Col> */}
                    {/* <Col span={8}>
                      <Form.Item
                        label="Discount"
                        name="discount"
                        rules={[
                          {
                            validator: validatePercentage,
                          },
                        ]}
                      >
                        <Input.Group compact>
                          <Input
                            style={{ width: "85%" }}
                            value={discountValue}
                            onChange={(e) => {
                              const discountValue =
                                parseFloat(e.target.value) || 0;
                              setDiscountValue(discountValue); // Update state
                              form.setFieldValue("discount", discountValue); // Update form value
                              changeNumerics({
                                itemsSelected: selectedItems,
                                updatedDiscount: discountValue,
                                discountType: discountType,
                                cgst: 9,
                                sgst: 9,
                              });
                            }}
                            tabIndex={4}
                          />
                          <Select
                            value={discountType}
                            style={{ width: "15%" }}
                            onChange={(value) => {
                              setDiscountType(value); // Store the selected discount type
                              changeNumerics({
                                itemsSelected: selectedItems,
                                updatedDiscount: discountValue, // Use the stored discount value
                                discountType: value, // Use the selected discount type
                                cgst: 9, // Ensure CGST is set to 9%
                                sgst: 9, // Ensure SGST is set to 9%
                              });
                            }}
                          >
                            <Select.Option value="%">%</Select.Option>
                            <Select.Option value="₹">₹</Select.Option>
                          </Select>
                        </Input.Group>
                      </Form.Item>
                    </Col> */}
                    <Col span={12}>
                      <Item
                        label="Terms And Conditions"
                        name="termsAndConditions"
                        rules={[
                          {
                            // required: true,
                            message: "Please enter terms and conditions",
                          },
                        ]}
                      >
                        <Input.TextArea rows={4} />
                      </Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <br />

            <Card
              title="Seats"
              extra={
                <>
                  <Button
                    type="primary"
                    icon={<LuPlus style={{ paddingTop: "2px" }} />}
                    onClick={showModal}
                    tabIndex={7}
                  >
                    Add Seat
                  </Button>
                </>
              }
            >
              <Table
                loading={isLoading}
                columns={columns}
                dataSource={selectedItems}
                size="large"
                footer={() => (
                  <>
                    <Row
                      style={{
                        paddingBottom: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <Col span={20}>Amount</Col>
                      <Col>₹{amount}</Col>
                    </Row>

                    <Row
                      style={{
                        paddingBottom: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <Col span={20}>CGST</Col>
                      <Col>₹{cgst}</Col>
                    </Row>

                    <Row
                      style={{
                        paddingBottom: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        borderBottom: "2px solid gray",
                      }}
                    >
                      <Col span={20}>SGST</Col>
                      <Col>₹{sgst}</Col>
                    </Row>

                    {/* <Row
                      style={{
                        paddingBottom: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <Col span={20}>Discount</Col>
                      <Col>₹{discount}</Col>
                    </Row> */}

                    {/* <Row
                      style={{
                        borderBottom: "2px solid gray",
                        paddingBottom: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <Col span={20}>
                        <b>Discounted Amount</b>
                      </Col>
                      <Col>
                        <b>₹{discountedAmount}</b>
                      </Col>
                    </Row> */}

                    <Row
                      style={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <Col span={20}>Total Price</Col>
                      <Col style={{ fontWeight: "600", fontSize: "20px    " }}>
                        ₹{totalAmount}
                      </Col>
                    </Row>
                  </>
                )}
              />
            </Card>
            <Item style={{ marginTop: "20px", marginLeft: "20px" }}>
              <Button
                type="primary"
                htmlType="submit"
                tabIndex={8}
                style={{ backgroundColor: "#26b689" }}
              >
                {isEdit ? "Save" : "Create"}
              </Button>
            </Item>
          </Form>
        </Col>
      </Row>
      <Modal
        title="Add Seat"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        okText="Add"
        okButtonProps={{ style: { backgroundColor: "#26b689" } }}
      >
        <Form
          form={itemForm}
          layout="vertical"
          onFinish={(value) => {
            console.log(value, "modal value");
            setSelectedItems((prevItems) => [...prevItems, value]);
          }}
        >
          <Form.Item
            name="_id"
            label="Select Seat"
            rules={[
              {
                required: true,
                message: "Please select the seat",
              },
            ]}
          >
            <DebounceSelect
              value={itemValue}
              placeholder="e.g. Cabin"
              fetchOptions={fetchItemList}
              onChange={(newValue, selectedItem) => {
                setItemValue(newValue);
                console.log(selectedItem, "555");
                itemForm.setFieldsValue({
                  name: selectedItem?.data?.name,
                  description: selectedItem?.data?.description,
                  item: selectedItem.data,
                  price: selectedItem.data.price,
                  quantity: 1,
                });
              }}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Row gutter={8}>
            <Col span={12}>
              <Item label="Data" name="item" hidden></Item>
              <Item
                label="Price (₹)"
                name="price"
                rules={[
                  {
                    required: true,
                    message: "Please enter the price",
                  },
                  {
                    validator: validatePositiveNumber,
                  },
                ]}
              >
                <Input placeholder="e.g. 1000" type="number" />
              </Item>
            </Col>

            <Col span={12}>
              <Item
                label="Quantity"
                name="quantity"
                rules={[
                  {
                    required: true,
                    message: "Please enter quantity",
                  },
                  {
                    validator: validatePositiveNumber,
                  },
                ]}
              >
                <Input placeholder="e.g. 3" type="number" min={1} />
              </Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        open={isEditSeatModalVisible}
        title="Edit Seat"
        onCancel={() => setIsEditSeatModalVisible(false)}
        onOk={() => {
          editSeatForm.validateFields().then((values) => {
            onEditOkSeat(values);
          });
        }}
      >
        <Form form={editSeatForm} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input the name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please input the description" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="price"
            label="Price"
            rules={[
              {
                required: true,
                message: "Please input the price",
              },
              {
                validator: validatePositiveNumber,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="quantity"
            label="Quantity"
            rules={[
              { required: true, message: "Please input the quantity" },
              {
                validator: validatePositiveNumber,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Image" name="file">
            <Upload
              Upload
              accept=".jpg, .png"
              beforeUpload={handleSeatUpload}
              multiple={false}
            >
              <Button>Click to Upload</Button>
            </Upload>
            {fileName && (
              <div style={{ marginTop: 8, color: "red" }}>{fileName}</div>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ManageQuoatation;
