import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../features/Dashboard";
import MainLayout from "../../components/Layout/MainLayout";
import Amc from "../features/Amc";
import SurveyDetails from "../features/Surveys";
import AmcDetails from "../features/amc/AmcDetails";
import Contact from "../features/Contact";
import ActiveAMC from "../features/ActiveAMC";
import Reports from "../features/Reports";
import Quotations from "../features/Quotations";
import GuestEntry from "../features/GuestEntry";
import ManageQuoatation from "../features/quotations/ManageQuoatation";
import QuoatationDetails from "../features/quotations/QuoatationDetails";
import MonthQuoatation from "../features/quotations/MonthQuoatation";
import Enquiry from "../features/Enquiry";
import ManageEnquiries from "../features/enquiry/manageEnquiry";
import ManageEntries from "../features/GuestEntry/ManageEntries";
import Employees from "../features/Employees";
import ManageEmployee from "../features/employee/ManageEmployees";
import EmployeeDetails from "../features/employee/EmployeeDetails";

const CutomerRoutes = () => {
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <MainLayout>
            <Dashboard />
          </MainLayout>
        }
      />
      <Route
        path="/employees"
        element={
          <MainLayout active="employees">
            <Employees />
          </MainLayout>
        }
      />

      <Route
        path="/quotations"
        element={
          <MainLayout active="quotations">
            <Quotations />
          </MainLayout>
        }
      />
      <Route
        path="/quotations/:quotationId"
        element={
          <MainLayout active="quotations">
            <QuoatationDetails />
          </MainLayout>
        }
      />
      <Route
        path="/quotations/manage"
        element={
          <MainLayout active="items">
            <ManageQuoatation />
          </MainLayout>
        }
      />
      <Route
        path="/quotations/month"
        element={
          <MainLayout active="quotations">
            <MonthQuoatation />
          </MainLayout>
        }
      />
      <Route
        path="/quotations/manage/:id"
        element={
          <MainLayout active="items">
            <ManageQuoatation isEdit />
          </MainLayout>
        }
      />
      <Route
        path="/enquiry"
        element={
          <MainLayout active="enquiry">
            <Enquiry />
          </MainLayout>
        }
      />

      <Route
        path="/enquiry/manage"
        element={
          <MainLayout active="enquiry">
            <ManageEnquiries />
          </MainLayout>
        }
      />

      <Route
        path="/enquiry/manage/:id"
        element={
          <MainLayout active="enquiry">
            <ManageEnquiries />
          </MainLayout>
        }
      />

      <Route
        path="/guestEntry"
        element={
          <MainLayout active="guestEntry">
            <GuestEntry />
          </MainLayout>
        }
      />

      <Route
        path="/guestEntry/manage"
        element={
          <MainLayout active="guestEntry">
            <ManageEntries />
          </MainLayout>
        }
      />

      <Route
        path="/guestEntry/manage/:id"
        element={
          <MainLayout active="guestEntry">
            <ManageEntries isEdit />
          </MainLayout>
        }
      />

      <Route
        path="/surveys"
        element={
          <MainLayout>
            <SurveyDetails />
          </MainLayout>
        }
      />
      <Route
        path="/amcs"
        element={
          <MainLayout>
            <Amc />
          </MainLayout>
        }
      />
      <Route
        path="/amcs/active"
        element={
          <MainLayout>
            <ActiveAMC />
          </MainLayout>
        }
      />

      <Route
        path="/amcs/:amcId"
        element={
          <MainLayout>
            <AmcDetails />
          </MainLayout>
        }
      />

      <Route
        path="/reports"
        element={
          <MainLayout>
            <Reports />
          </MainLayout>
        }
      />

      <Route
        path="/contact"
        element={
          <MainLayout>
            <Contact />
          </MainLayout>
        }
      />
    </Routes>
  );
};

export default CutomerRoutes;
