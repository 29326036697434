import {
  Dropdown,
  Button,
  Row,
  Col,
  Card,
  Table,
  Space,
  Form,
  Modal,
  Typography,
  Input,
  Radio,
  message,
  Breadcrumb,
  InputNumber,
} from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
import FormItem from "antd/es/form/FormItem";
import queryString from "query-string";
import APICall from "../../../services/apiservices";
import { useForm } from "antd/es/form/Form";

const { Text } = Typography;
const { Search } = Input;
const { confirm } = Modal;

const Areas = () => {
  const { TextArea } = Input;
  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const location = useLocation();
  const params = useParams();
  const { customerId, customerName, buildingName, pagename, surveyType } =
    queryString.parse(location.search);

  const breadCrumbItems = [
    {
      label: pagename,
      to: `/${pagename.toLowerCase()}`,
    },
    {
      label: customerName,
      // to: `/surveys`,
    },
    {
      label: "Buildings",
      to: -1,
    },
    {
      label: buildingName,
    },
    {
      label: "Areas",
    },
  ];
  const [form] = Form.useForm();
  const [modelOpen, setModelOpen] = useState(false);
  const [addArea] = useForm();
  const [areaName, setAreaName] = useState("");
  // const [radioAreaType, setRadioAreaType] = useState("floor");
  const [floorNumber, setFloorNumber] = useState(0);
  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [floors, setFloors] = useState([]);
  const [filteredFloors, setFilteredFloors] = useState([
    {
      name: "Loading...",
    }
  ]);
  const [conductedFloors, setConductedFloors] = useState([]);

  const handleSave = () => {
    setSaveLoading(true);
    form.validateFields().then(async (formData) => {
      try {
        const res = await APICall(
          `/${pagename === "Visits" ? "visits" : "survey"}/${pagename === "Visits" ? params.visitId : params.surveyId
          }/conducted/building/${params.buildingId}`,
          formData,
          3
        );
        setSaveLoading(false);
        message.success("Incharge and notes saved.");
      } catch (error) {
        message.error(error);
        setSaveLoading(false);
      }
    });
  };

  useEffect(() => {
    getFloors();
    getConductedFoors();
  }, []);

  const getFloors = async () => {
    try {
      const response = await APICall(
        `/customer/${customerId}/building/${params.buildingId}/floor`,
        {},
        1
      );
      setFloors(response.data.data);
      setFilteredFloors(response.data.data);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch floors");
    }
  };

  const getConductedFoors = async () => {
    try {
      const response = await APICall(
        `/${pagename === "Visits" ? "visits" : "survey"}/${pagename === "Visits" ? params.visitId : params.surveyId
        }/conducted/building/${params.buildingId}`,
        {},
        1
      );
      form.setFieldsValue({
        inCharge: response?.data.data?.information?.inCharge ?? "",
        notes: response?.data.data?.information?.notes ?? "",
      });
      setConductedFloors(response?.data?.data?.floors);
      console.log(response?.data?.data?.floors);
    } catch (error) {
      message.error("Failed to fetch conducted floors");
    }
  };

  const [modelRemoveOpen, setModelRemoveOpen] = useState(false);

  const [areaId, setAreaId] = useState(0);

  const onRemove = async (data) => {
    confirm({
      title: "Are you sure, you want to remove area?",
      onOk: async () => {
        try {
          const response = await APICall(
            `/customer/${customerId}/building/${params.buildingId}/floor/${data._id}`,
            {},
            4
          );
          if (response?.status) {
            Modal.success({
              title: "Area Removed",
              content: "",
            });
            getFloors();
            addArea.resetFields();
          }
        } catch (error) {
          console.log(error);
          Modal.error({
            title: "Cannot Remove Area",
          });
        }
      },
      okButtonProps: {
        style: {
          backgroundColor: "red",
        },
      },
    });
  };

  const handlePermission = (id) => {
    setAreaId(id);
    setModelRemoveOpen(true);
  };

  const coloums = [
    {
      title: "Sr.No.",
      key: "srNo",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Name",
      key: "name",
      render: (_, record) => <Text>{`${record.name}`}</Text>,
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        // <Dropdown
        //   menu={{
        //     items: [
        //       {
        //         key: 1,
        //         label: (
        //           <Link
        //             to={`/${pagename === "Visits" ? "visit" : "survey"}/${
        //               pagename === "Visits" ? params.visitId : params.surveyId
        //             }/building/${params.buildingId}/area/${
        //               record._id
        //             }/equipments?customerName=${customerName}&buildingName=${buildingName}&floor=${
        //               record.name
        //             }&customerId=${customerId}&pagename=${pagename}`}
        //           >
        //             {pagename === "Visits" ? "Conduct Visit" : "Conduct Survey"}
        //           </Link>
        //         ),
        //       },
        //       {
        //         key: 2,
        //         label: "Remove",
        //         onClick: () => {
        //           onRemove(record);
        //         },
        //         danger: true,
        //       },
        //     ],
        //   }}
        // >
        //   <Button type="link">Actions</Button>
        // </Dropdown>
        <Space>
          <Link
            to={`/${pagename === "Visits" ? "visit" : "survey"}/${pagename === "Visits" ? params.visitId : params.surveyId
              }/building/${params.buildingId}/area/${record._id}/${surveyType !== "Normal" ? "fireExtinguisher" : "equipments"
              }?customerName=${customerName}&buildingName=${buildingName}&floor=${record.name
              }&customerId=${customerId}&pagename=${pagename}&surveyType=${surveyType}`}
          >
            <Button type="primary" ghost size="small">
              {conductedFloors?.find((item) => item?.floorId === record._id)
                ? pagename === "Visits"
                  ? "Edit Visit"
                  : "Edit Survey"
                : pagename === "Visits"
                  ? "Conduct Visit"
                  : "Conduct Survey"}
            </Button>
          </Link>
          <Button
            type="primary"
            ghost
            size="small"
            onClick={() => onRemove(record)}
          >
            Remove
          </Button>
        </Space>
      ),
      align: "center",
      fixed: "right",
    },
  ];

  const onAddfloorFinish = async (formData) => {
    console.log(formData);
    if (
      formData.areaType === "custom"
        ? floors?.find(
          (item) => item?.name.toLowerCase() === formData?.name.toLowerCase()
        )
        : floors?.find(
          (item) =>
            item.name.toLowerCase() ===
            ("Floor " + formData.name.toString()).toLowerCase()
        )
    ) {
      message.error("Already exists.");
    } else {
      try {
        console.log(formData);
        const response = await APICall(
          `/customer/${customerId}/building/${params.buildingId}/floor`,
          formData.areaType !== "custom"
            ? { name: "Floor " + formData.name.toString() }
            : { name: formData.name.toString() },
          2
        );
        if (response?.status) {
          message.success("New area added.");
          getFloors();
          addArea.resetFields();
          setModelOpen(false);
        }
      } catch (error) {
        console.log(error);
        // message.error();
      }
    }
  };

  const onSearch = (value) => {
    //filter floors
    const filteredFloors = floors.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    console.log(filteredFloors);
    setFilteredFloors(filteredFloors);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Card
            title={
              <Row>
                <Col 
                  lg={24}
                  md={24}
                  sm={20} 
                  xs={0}
                >
                  <Breadcrumb separator=">" style={{ margin: "16px 0" }}>
                    {breadCrumbItems.map((item, index) => (
                      <Breadcrumb.Item key={index}>
                        {item.to ? (
                          <Link to={item.to}>{item.label}</Link>
                        ) : (
                          item.label
                        )}
                      </Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                </Col>
              </Row>
            }
            extra={
              // !loading ? (
              <Row
                justify="end"
                align={"top"}
                style={{ paddingBottom: "10px" }}
              >
                <Col
                  lg={24}
                  xs={24}
                >
                  <Space>
                    <Search
                      style={{
                        width: 220,
                      }}
                      placeholder="Search by name"
                      allowClear
                      size="middle"
                      onSearch={onSearch}
                      enterButton
                    />

                    <Button
                      type="primary"
                      onClick={() => setModelOpen(true)}
                      ghost
                    >
                      Add
                    </Button>
                  </Space>
                </Col>
              </Row>
              // ) : null
            }
          >
            <Space direction="vertical" style={{ width: "100%" }}>
              <Card type="inner">
                <Space>
                  <Form
                    form={form}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "16px",
                    }}
                    onFinish={handleSave}
                  >
                    <Form.Item label="Site Incharge Name" name="inCharge">
                      <Input
                        style={{
                          width: "200px",
                        }}
                      />
                    </Form.Item>

                    <Form.Item label="Notes" name="notes">
                      <TextArea
                        rows={2}
                        style={{
                          width: "200px",
                        }}
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        htmlType="submit"
                        type="primary"
                        // onClick={handleSave}
                        loading={saveLoading}
                      >
                        Save
                      </Button>
                    </Form.Item>
                  </Form>
                </Space>
              </Card>
              <Card type="inner">
                <Table
                  columns={coloums}
                  dataSource={filteredFloors}
                  // loading={loading}
                  size="small"
                  scroll={{ x: "1000px" }}
                />
              </Card>
            </Space>
          </Card>
          <Modal
            title="Add Area"
            okText={"Add"}
            centered
            open={modelOpen}
            onCancel={() => {
              setModelOpen(false);
              addArea.resetFields();
            }}
            onOk={addArea.submit}
          >
            <Form form={addArea} onFinish={onAddfloorFinish}>
              <Form.Item
                name="areaType"
                label="Area Type"
                initialValue={"floor"}
              >
                <Radio.Group
                  // onChange={handleRadioChange}
                  // value={radioAreaType}
                  defaultValue={"floor"}
                >
                  <Radio value={"floor"}>Floor</Radio>
                  <Radio value={"custom"}>Custom</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.areaType !== currentValues.areaType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("areaType") === "floor" ? (
                    <Form.Item
                      name="name"
                      label="Floor Number"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="name"
                      label="Area Name"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )
                }
              </Form.Item>

              {/* <Form.Item
                    label="Floor Number"
                    onChange={(e) => setFloorNumber(e.target.value)}
                    name="name"
                  >
                    <Input
                      type="number"
                      placeholder="Floor Number"
                      value={floorNumber}
                    />
                  </Form.Item>
                

                {(
                  <Form.Item
                    label="Area Name"
                    onChange={(e) => setAreaName(e.target.value)}
                    name="name"
                  >
                    <Input
                      placeholder="Area Name"
                      value={areaName}
                    />
                  </Form.Item>
                )} */}
            </Form>
          </Modal>

          <Modal
            title="Remove Area"
            okText={"Remove"}
            centered
            open={modelRemoveOpen}
            onCancel={() => {
              setModelRemoveOpen(false);
            }}
            onOk={onRemove}
          >
            <p>Are you sure you want to remove this area?</p>
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default Areas;
